;(function() {
"use strict";

const householdListComponent = {
  templateUrl:  'components/manager/assign-workload/rhs-fieldworker-panel/household-list/household-list.component.html',
  controller:   'HouseholdListController',
  controllerAs: 'vm',
  require: {
    parent: '^rhsFieldworkerPanel'
  },
  bindings: {
    households: '<',
    selectedFieldworker: '<',
    selectedWorkType: '<'
  }
};

function householdListController($log, Alerts, NgTableParams, HouseholdModel, FieldworkerModel) {
  let vm = this;
  vm.assigning = true;

  vm.$onChanges = function(changedObj) {
    if (angular.isDefined(changedObj.selectedWorkType) && angular.isDefined(vm.selectedFieldworker)) {
      vm.parseSelected();
    } else if (angular.isDefined(changedObj.selectedFieldworker)) {
      vm.parseSelected();
    }
    if (angular.isDefined(changedObj.households) && angular.isDefined(changedObj.households.currentValue)) {
      vm.households = angular.copy(changedObj.households.currentValue);
      vm.tableParams = new NgTableParams(
        {
          count: vm.households.length // hides pager
        },
        {
          dataset: vm.households,
          counts: [] // hides page sizes
        });
      vm.parseSelected();
    }
  };

  vm.parseSelected = function(households = vm.households, worker = vm.selectedFieldworker, type = vm.selectedWorkType) {
    if (!worker || !type) {
      // $log.warn('no selected work type or worker');
      return;
    }
    if (households) {
      households.forEach(household => {
        if (household[type.selector] === vm.selectedFieldworker.admin_user_id) {
          household[type.field] = true;
        } else {
          household[type.field] = false;
        }
      });
    }

    if (type.label === "Validation") {
      vm.assigning = !_.some(households, {"selectedForValidation": true});
    } else if (type.label === "Recruitment") {
      vm.assigning = !_.some(households, {"selectedForRecruitment": true});
    }
  };

  vm.assignAll = function () {
    let body = [];
    vm.households.forEach((household, index) => {
      // Its a bit odd having the assign check inside the loop, :(
      if (vm.assigning === false) {
        // need to unassign all the fieldworkers
        if (household[vm.selectedWorkType.selector]) {
          household[vm.selectedWorkType.selector] = null;
          body.push({household_id: household.household_id});
        }
      } else if (vm.assigning === true) {
        // Need to assign all these households to the selected fieldworker
        household[vm.selectedWorkType.selector] = vm.selectedFieldworker.admin_user_id;
        body.push({household_id: household.household_id});
      }
      if (index === (vm.households.length - 1)) {
        FieldworkerModel.batchUpdateHouseholds(vm.selectedFieldworker.admin_user_id, vm.selectedWorkType.role, vm.assigning, body).$promise.then(resp => {
          if (vm.households.length === resp.length) {
            vm.parent.households = resp;
          } else if (resp.length > 0) {
            // If some houselds were assigned but not all we need to push to them individually.
            _.map(vm.households, ((house, index) => {
              if (_.find(resp, {household_id: house.household_id})) {
                vm.households.splice(index, 1, _.find(resp, {household_id: house.household_id}));
              }
              vm.parseSelected();
              vm.tableParams.reload();
            }));
          }
          vm.parent.getFieldworkersWithWorkloads();
          vm.parent.updateWorkload();
        });
      }
    });
  };

  vm.updateHousehold = function(household, type = vm.selectedWorkType) {
    let body = [];
    body.push(household);
    FieldworkerModel.batchUpdateHouseholds(vm.selectedFieldworker.admin_user_id, vm.selectedWorkType.role, household[type.field], body).$promise.then(resp => {
      let index = _.indexOf(vm.households, _.find(vm.households, (h) => { return h.household_id === resp[0].household_id }));
      vm.households.splice(index, 1, resp[0]);
      vm.tableParams.reload();
      vm.parent.getFieldworkersWithWorkloads();
      vm.parent.updateWorkload();
      vm.parseSelected();
    });
  };
}

angular
    .module('tmr-admin')
    .component('householdList', householdListComponent)
    .controller('HouseholdListController', householdListController)
;
}());
