;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('MetaModel', MetaModel);

/* @ngInject */
function MetaModel($log, $q, MetaResource) {
  let resource = MetaResource;
  let service  = {
    scheduledEventTypes:      scheduledEventTypes,
    contactTypes:             contactTypes,
    contactOutcomes:          contactOutcomes,
    contactTypesOutcomes:     contactTypesOutcomes,
    contactOutcomeReasons:    contactOutcomeReasons,
    contactOutcomeReasonMaps: contactOutcomeReasonMaps,
    dwellingTypes:            dwellingTypes,
    timeWindows:              timeWindows,
    workTypes:                workTypes,
  };
  let store = {};

  return service;

  function requestMeta(key, field) {
    const defer = $q.defer();
    if (angular.isUndefined(store[key])) {
      // We haven't got this.
      resource.get({field}).$promise.then(
        (res) => {
          store[key] = res;
          defer.resolve(store[key]);
        }
      );
    } else {
      defer.resolve(store[key]);
    }
    return defer.promise;
  }

  function scheduledEventTypes() {
    return requestMeta('scheduledEventTypes', 'scheduled-event-types');
  }

  function contactTypes() {
    return requestMeta('contactTypes', 'contact-types');
  }

  function contactOutcomes() {
    return requestMeta('contactOutcomes', 'contact-outcomes');
  }

  function contactTypesOutcomes() {
    return requestMeta('contactTypesOutcomes', 'contact-types-outcomes');
  }

  function contactOutcomeReasons() {
    return requestMeta('contactOutcomeReasons', 'contact-outcome-reasons');
  }

  function contactOutcomeReasonMaps() {
    return requestMeta('contactOutcomeReasonMaps', 'contact-outcome-reason-maps');
  }

  function dwellingTypes() {
    return requestMeta('dwellingTypes', 'dwelling-types');
  }

  function timeWindows() {
    return requestMeta('timeWindows', 'time-windows');
  }

  function workTypes() {
    let defer = $q.defer();
    let types = [
      {
        id: 1,
        label: "Validation",
        role: "validator",
        selector: "validator_user_id",
        field: "selectedForValidation"
      },
      {
        id: 2,
        label: "Recruitment",
        role: "recruiter",
        selector: "recruiter_user_id",
        field: "selectedForRecruitment"
      }
    ];
    defer.resolve(types);
    return defer.promise;
  }
}
}());
