;(function() {
"use strict";

const tabSetComponent = {
  templateUrl:  'components/tab-set/tab-set.component.html',
  transclude: true
};

angular
    .module('tmr-admin')
    .component('tabSet', tabSetComponent)
;
}());
