;(function() {
"use strict";

angular.module('tmr-admin').factory('ReminderLogResource', ($resource, Project) => {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/reminder/logs', paramDefaults, {
      'search': { method: 'GET', url:'/api/project/:project_id/reminder/logs', isArray: true }
  });
});
}());
