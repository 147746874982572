;(function() {
"use strict";

angular.module('tmr-admin').factory('FieldworkerWeekResource', ($resource, Project) => {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/fieldworkers/:fieldworker_id/work-weeks/', paramDefaults, {
    'getAllWorkWeeksForWorker': {method: 'GET', isArray: true},
    'getAllWorkWeeksForWorkerAndWorkType': { method:'GET', url:'/api/project/:project_id/fieldworkers/:fieldworker_id/work-weeks?work_type=:work_type', isArray:true },
  });
});
}());
