;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('FieldworkerModel', FieldworkerModel);

/* @ngInject */
function FieldworkerModel($log, FieldworkerResource, FieldworkerBatchResource, FieldworkerDetailResource) {
  let resource = FieldworkerResource;
  let batchResource = FieldworkerBatchResource;
  let detailResource = FieldworkerDetailResource;

  let service  = {
    get:                   get,
    getHouseholds:         getHouseholds,
    getAll:                getAll,
    workloads:             workloads,
    scheduledEvents:       scheduledEvents,
    batchUpdateHouseholds: batchUpdateHouseholds,
    getAllByWeek:          getAllByWeek,
    getAllByWeekAndWorker: getAllByWeekAndWorker,
    getAllFiltered:        getAllFiltered,
    updateStatus:          updateStatus,
    activateProjects:      activateProjects,
  };

  return service;

  function get(fieldworkerId) {
    return resource.get({ fieldworker_id: fieldworkerId });
  }

  function getHouseholds(fieldworkerId, role, week_number) {
    return resource.getHouseholds({ 'fieldworker_id': fieldworkerId, 'week_number': week_number, 'role': role});
  }

  function getAll(params) {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource.getAll(params);
  }

  function workloads(fieldworkerId) {
    return resource.workloads({fieldworker_id: fieldworkerId});
  }

  function scheduledEvents(fieldworkerId, weekId) {
    return resource.scheduledEvents({fieldworker_id: fieldworkerId, 'scheduled-week-id': weekId});
  }

  function getAllByWeek(week_number) {
    return resource.getAllByWeek({ week_number: week_number });
  }

  function getAllByWeekAndWorker(fieldworkerId, week_number) {
    return resource.getAllByWeekAndWorker({fieldworker_id: fieldworkerId, week_number: week_number});
  }

  function batchUpdateHouseholds(fieldworkerId, role, assign, body) {
    if (assign === true) {
      return batchResource.assignToHouseholds({fieldworker_id: fieldworkerId, role: role}, body);
    } else if (assign === false) {
      return batchResource.removeFromHouseholds({fieldworker_id: fieldworkerId, role: role}, body);
    }
  }

  function getAllFiltered(range, sort, filters) {
    let params = {};
    let filtersCopy = angular.copy(filters);
    if (filtersCopy.created_utc) {
      var dateValidator = /\d{4}-\d{2}-\d{2}/;
      if (dateValidator.test(filtersCopy.created_utc) === false) {
        // If the entered value passes the regex, set the filter
        filtersCopy.created_utc = "";
      }
    }
    params["sort"] = sort;
    _.merge(params, filtersCopy);
    return detailResource(range).withFilters(params);
  }

  function updateStatus(fieldworker, isActive) {
    const pathParams = { admin_user_id: fieldworker.admin_user_id };
    const bodyParams = { active: isActive };

    return detailResource().update(pathParams, bodyParams);
  }

  function activateProjects(userId, role, projectIds) {
    return detailResource().activateProjects({ user_id: userId, }, { role: role, project_ids: projectIds });
  }

}
}());
