;(function() {
"use strict";

const showDiagnosticsComponent = {
    templateUrl: 'components/show-diagnostics/show-diagnostics.component.html',
    controller: 'ShowDiagnosticsController',
    controllerAs: 'vm',
};

function showDiagnosticsController($location, $log, $state, $window, $scope) {
    let vm = this;
    vm.version = window.tmr.code_version;

    vm.today = new Date();

    vm.timeZoneFormatted = jstz.determine().name();
    vm.userAgent = navigator.userAgent;

    vm.host = $location.host();

    vm.$onInit = function () {
    };

    vm.$onChanges = function (changedObj) {
    };

    vm.back = function(){
        $window.history.back();
    };
    
    vm.reload = function(){
        $window.location.reload();
    };

    vm.hostGroups = [
        {
            name: 'Production',
            hosts: [
                {domain: 'tmr-admin.data-online.net.au', params: {mode: 'no-cors'}, expected_status: 200},
                {domain: 'travelsurvey.tmr.qld.gov.au', params: {mode: 'no-cors'}},
            ],
        },
        {
            name: 'Staging',
            hosts: [
                {domain: 'tmr-admin-staging.data-online.net.au', params: {mode: 'no-cors'}, expected_status: 200},
                {domain: 'tmr-tst-staging.sd-demo.com', params: {mode: 'no-cors'}},
                {domain: 'login-staging.logicly.com.au', params: {mode: 'no-cors'}},
            ],
        },
        {
            name: 'All',
            hosts: [
                {domain: 'www.logicly.com.au', expected_status: 200},
                {domain: 'docs.logicly.com.au', path: '/en/latest/', expected_status: 200},
                {domain: 'auth.logicly.com.au', params: {mode: 'no-cors'}},
                {domain: 'login.logicly.com.au', params: {mode: 'no-cors'}},
                {domain: 'cdn.auth0.com', params: {mode: 'no-cors'}},
                {domain: 'cdn.rollbar.com', expected_status: 200},
                {domain: 'api.rollbar.com', expected_status: 200},
                {domain: 'cdnjs.cloudflare.com', params: {mode: 'no-cors'}},
                {domain: 'fonts.googleapis.com', path: '/css?family=Lato', expected_status: 200},
                {domain: 'fonts.gstatic.com', path: '/s/nunito/v26/XRXV3I6Li01BKofINeaBTMnFcQ.woff2', params: {mode: 'no-cors'}},
                {domain: 'maps.googleapis.com', params: {mode: 'no-cors'}},
                {domain: 'maps.gstatic.com', path: '/mapfiles/transparent.png', expected_status: 200},
            ],
        },
        {
            name: 'Expected blocks',
            diag: true, 
            hosts: [
                {domain: 'does-not-exist.logicly.com.au', expected_status: 'Load failed'},
                {domain: 'api.github.com', expected_status: 200},
            ],
        },
    ];

    function getUrl(host) {

        const scheme = host.scheme || 'https';
        const port = host.port ? ':' + host.port : '';
        const path = host.path || '/';

        return `${scheme}://${host.domain}${port}${path}`;
    };

    function testUrl(host) {
        let url = getUrl(host);

        let default_params = {
            method: 'HEAD',
        };

        // v1.1.2 of the electron app has AbortSignal, but not
        // AbortSignal.timeout
        if (typeof(AbortSignal == 'function') && AbortSignal.timeout) {
            const timeoutSeconds = 6;
            default_params.signal = AbortSignal.timeout(timeoutSeconds * 1000);
        }

        const params = Object.assign({}, default_params, host.params);

        return fetch(url, params);
    };

    function checkHost(host, diag) {
        host.status      = 'checking';
        host.status_icon = `⏳`;
        host.url         = getUrl(host);

        testUrl(host).then(response => {

            host.status = response.status;

            if (_.has(host, 'params.mode') 
                && host.params.mode == 'no-cors'
                && host.domain != vm.host) {

                host.expected_status = 0;
            }

            if (host.expected_status == response.status) {
                host.status_text = `ok`;
                host.status_icon = diag ? '🔎' : `✅`;
            }
            else {
                host.status      = host.expected_status + ` <> ` + response.status;
                host.status_text = `error`;
                host.status_icon = diag ? '🔎' : `❌`;
            }
            $scope.$apply();
        }).catch(error => {
            host.status      = error.message;
            host.status_text = `error`;
            host.status_icon =  diag ? '🔎' : `❌`;
            $scope.$apply();
        });

    };

    for (const group of vm.hostGroups) {
        for (const host of group.hosts) {
            checkHost(host, group.diag);
        }
    };
}

angular
    .module('tmr-admin')
    .component('showDiagnostics', showDiagnosticsComponent)
    .controller('ShowDiagnosticsController', showDiagnosticsController)
    ;
}());
