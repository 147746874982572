;(function() {
"use strict";

const householdEventComponent = {
  templateUrl:  'components/household/event/household-event.component.html',
  controller:   'HouseholdEventController',
  controllerAs: 'vm',
  require: {
    parent: '^household',
  },
  bindings: {
    contacts: '<',
    household: '<',
    meta: '<',
  }
};

function householdEventController($state, $stateParams, $timeout, $anchorScroll, $log, Alerts, ContactModel, HouseholdModel, ScheduledEventModel) {
  let vm = this;
  vm.canNavigate = true;

  vm.$onChanges = function(changedObj) {
    if (changedObj.household && changedObj.household.currentValue) {
      vm.household = changedObj.household.currentValue;
      vm.getAllEvents(vm.household.household_id);
    }
  };

  vm.$onInit = function() {
    // when navigating to household 'event' tab, auto-focus the chosen row if it's defined
    if ($stateParams.rowToFocus) {
      $timeout(function(){
        $anchorScroll($stateParams.rowToFocus);
      }, 100);
    }
  };

  vm.saveContact = function (contact) {
    return ContactModel.save(vm.household.household_id, contact).$promise.then((resp) => {
      vm.contact_id = resp.contact_id;
      if (vm.showResolveForm) {
        return vm.resolveScheduledEvent().$promise.then((r) => vm.getAllEvents(vm.household.household_id));
      }
      else {
        return vm.getAllEvents(vm.household.household_id);
      }
    })
    .then(() => vm.enableNavigation({$toggle: true}));
  };

  vm.updateContact = function (contact) {
    return ContactModel.update(vm.household.household_id, contact.contact_id, contact).$promise.then((resp) => {
      vm.contact_id = resp.contact_id;
      return vm.getAllEvents(vm.household.household_id);
    })
    .then(() => vm.enableNavigation({$toggle: true}));
  };

  vm.deleteContact = function (contact) {
    return ContactModel.remove(vm.household.household_id, contact.contact_id).$promise.then(() => {
      return vm.getAllEvents(vm.household.household_id);
    })
    .then(() => vm.enableNavigation({$toggle: true}));
  };

  vm.deleteScheduledEvent = function(entry) {
    return ScheduledEventModel.remove(vm.household.household_id, entry.scheduled_event_id).$promise.then(() => {
      return vm.getAllEvents(vm.household.household_id);
    })
    .then(() => vm.enableNavigation({$toggle: true}));
  };

  vm.resolveScheduledEvent = function() {
    const entry = vm.scheduledEventToResolve;
    let body  = _.clone(entry);
    body.resolved_by_contact_id = vm.contact_id;
    return ScheduledEventModel.update(vm.household.household_id, entry.scheduled_event_id, body);
  };

  vm.getAllEvents = function(householdId) {
    return HouseholdModel.getAllHouseholdEvent(householdId).$promise.then(resp => {
      vm.contacts = resp;
      //console.log(vm.contacts);
    });
  };

  vm.onOpenForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = true;
      vm.showEditForm = false;
      vm.enableNavigation({$toggle: true});
    });
  };

  vm.onEditForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = false;
      vm.showEditForm = true;
      vm.enableNavigation({$toggle: true});
    });
  };

  vm.onResolveForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showResolveForm = true;
      vm.enableNavigation({$toggle: true});
    });
  };

  vm.enableNavigation = function(toggle) {
    // Toggles wether you can leave the page by conventional means
    if (angular.isUndefined(toggle)) {
      vm.canNavigate = !vm.canNavigate;
    } else {
      vm.canNavigate = toggle;
    }
    if (vm.canNavigate === true) {
      return vm.getHousehold();
    }
  };

  vm.getHousehold = function() {
    return HouseholdModel.get(vm.household.household_id).$promise.then(resp => {
      vm.household = resp;
    });
  };
}

angular
    .module('tmr-admin')
    .component('householdEvent', householdEventComponent)
    .controller('HouseholdEventController', householdEventController)
;
}());
