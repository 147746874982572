;(function() {
"use strict";

const tmrSelectComponent = {
  templateUrl:  'components/inputs/tmr-select/tmr-select.component.html',
  controller:   'TmrSelectController',
  controllerAs: 'vm',
  bindings: {
    model: '<',
    options: '<',
    defaultString: '@',
    change: '&'
  }
};

// ngInject
function tmrSelectController($log, Alerts, MetaModel) {
  let vm = this;

  vm.$onChanges = function (changedObj) {
    if (!changedObj.model.isFirstChange()) {
      return;
    }
 
    if (changedObj.model.currentValue) {
      vm.model = changedObj.model.currentValue;
      return;
    }

    vm.options = angular.isDefined(changedObj.options.currentValue) ? angular.copy(changedObj.options.currentValue) : $log.warn("options must be supplied to tmr-select");
    vm.defaultString = changedObj.defaultString.currentValue;

    // See if any of the original options is marked as defaultOption
    let originalDefault = _.find(vm.options, { isDefault: true });

    if (originalDefault && angular.isUndefined(changedObj.model.currentValue)) {
      vm.model = originalDefault;
      return;
    } 

    // Make default option if nothing coming in looked like a default
    let newDefault = {
      id: "",
      label: vm.defaultString,
      isDefault: true
    };
    vm.options.push(newDefault);
    // Set default if no value is selected.
    if (angular.isUndefined(changedObj.model.currentValue)) {
      vm.model = newDefault;
    }

  };
}

angular
    .module('tmr-admin')
    .component('tmrSelect', tmrSelectComponent)
    .controller('TmrSelectController', tmrSelectController)
;
}());
