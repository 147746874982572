;(function() {
"use strict";

const profileComponent = {
    template:  `
    <div class="profile">
        <a ui-sref="showDiagnostics" class="tmr-icon">
            <tmr-icon style="font-size: 20px;" icon="info-sign" meaning="Project: {{ vm.projectName }}.&#013;(click to show diagnostics)"></tmr-icon>
        </a>
        <div class="dropdown" uib-dropdown is-open="status.open">
        <button class="btn-primary" uib-dropdown-toggle id="logoutButton">
            {{ vm.auth.current.user.full_name }}
            <span class="caret"></span>
        </button>
            <ul class="dropdown-menu" role="menu">
            <li role="menuitem" ng-if="vm.showSwitchProject">
                <a ui-sref="projectSelector">Switch Project</a>
            </li>
            <li role="menuitem">
                <a ng-click="vm.auth.logout()">Logout</a>
            </li>
            </ul>
        </div>
    </div>`,
    controller:   'ProfileController',
    controllerAs: 'vm'
  };

  function profileController(Auth, Project) {
    let vm = this;
    vm.auth = Auth;
    vm.projectName = Project.getProjectName();
    if (vm.auth.current.user.is_owner) {
      vm.showSwitchProject = true;
    }
    else {
      vm.showSwitchProject = Project.getProjectList().length > 1;
    }

  }

  angular
      .module('tmr-admin')
      .component('profile', profileComponent)
      .controller('ProfileController', profileController);
}());
