;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('Persistence', function($location, $log, $window) {

  function create() {

    let values = { };

    function setAll(newValues) {
      values = _.chain(newValues)
                .pickBy((v, k) => !_.isUndefined(v))
                .mapValues((v, k) => v + '')    // stringify the values
                .pickBy((v, k) => v.length > 0) // strip out any empty strings
                .value();

      // .replace() here prevents extra history entry
      // Source: https://stackoverflow.com/questions/25685132/change-location-search-preventing-push-to-history
      $location.search(values).replace();
    }

    function getAll() {
      return $location.search();
    }

    function getValue(key, defaultValue) {
      let data = getAll();
      if (_.has(data, key)) {
        return data[key];
      }
      return defaultValue;
    }

    function setValue(key, value) {
      let data = getAll();
      data[key] = value;
      setAll(data);
    }

    // use this for sessionStorage
    function setItem(key, value) {
      if(key) {
        try {
          $window.sessionStorage.setItem(key, angular.toJson(value))
        } catch(err) {
          return undefined;
        }
      } else {
        console.log('Not a valid Key');
      }
    }
    function getItem(key) {
      try {
        return angular.fromJson($window.sessionStorage.getItem(key))
      } catch(err) {
        return undefined;
      }
    }

    function removeItem(key) {
      try {
        $window.sessionStorage.removeItem(key);
      } catch(err) {
        return undefined;
      }
    }

    return {
      getAll:   getAll,
      setAll:   setAll,
      getValue: getValue,
      setValue: setValue,
      storage: {
        setValue: setItem,
        getValue: getItem,
        removeValue: removeItem,
      }
    };
  }

  // This factory just returns a constructor function.
  return create;
});
}());
