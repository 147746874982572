;(function() {
"use strict";

const instructionModalComponent = {
  templateUrl:  'components/login/instruction-modal/instruction-modal.component.html',
  controller:   'InstructionModalController',
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&'
  },
};

function instructionModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };
}

angular
    .module('tmr-admin')
    .component('instructionModal', instructionModalComponent)
    .controller('InstructionModalController', instructionModalController)
;
}());
