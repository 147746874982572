;(function() {
"use strict";

const reminderLogsComponent = {
  templateUrl:  'components/manager/reminder-logs/reminder-logs.component.html',
  controller:   'reminderLogsController',
  controllerAs: 'vm',
};

// ngInject
function reminderLogsController(NgTableParams, ReminderLogModel, $filter) {

  let vm = this;

  vm.noLogs  = false;  // we don't know yet
  vm.date    = null;
  vm.maxDate = new Date();

  const logs = [ ]; // the reference is constant, the array itself is mutable

  function updateTable(date) {
    const filter = { };
    if (date) {
      filter.reminder_date = $filter('date')(date, 'yyyy-MM-dd');
    }
    return ReminderLogModel.getAll(filter).$promise.then((resp) => {
      // Replace the contents of the logs array with the contents of
      // result.
      logs.length = 0;
      logs.push(...resp);
      vm.tableParams.reload();
      vm.noLogs = logs.length == 0;

      // Initialise the date selector if need be.
      if (vm.date == null) {
        if (logs.length > 0) {
          vm.date = logs[0].reminder_time;
        }
        else {
          // No logs at all. Just select today.
          vm.date = new Date();
        }
      }
    });
  }

  vm.$onInit = function() {

    vm.tableParams = new NgTableParams(
      {
        noPager: true,
        isFiltersVisible: true,
        count: 1000,    // hide the pager - we're only showing one day
        sorting: {
          reminder_time: "desc",
        },
      },
      {
        counts: [ ],
        dataset: logs,
      }
    );

    updateTable();

  }

  vm.setDate = function(newDate) {
    updateTable(newDate);
  }

  vm.dateDisabled = function(...args) {
    return false;
  }

  vm.datepickerOptions = {
    datepickerMode: 'month',
  };

}

angular
    .module('tmr-admin')
    .component('reminderLogs', reminderLogsComponent)
    .controller('reminderLogsController', reminderLogsController);
}());
