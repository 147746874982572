;(function() {
"use strict";

const workloadListComponent = {
  templateUrl:  'components/manager/assign-workload/workload-list/workload-list.component.html',
  controller:   'WorkloadListController',
  controllerAs: 'vm',
  bindings: {
    workloads: '<',
    selectedWorkload: '<',
    setSelectedWorkload: '&',
    selectedWorkType: '<',
  }
};

// ngInject
function workloadListController($log, Alerts, Project) {
  let vm = this;
  
  vm.project_id  = Project.getProjectID();
  vm.getSum = function(key) {
    return _.sumBy(vm.workloads, `summary.${key}`);
  }
}

angular
    .module('tmr-admin')
    .component('workloadList', workloadListComponent)
    .controller('WorkloadListController', workloadListController)
;
}());
