;(function() {
"use strict";

const contactLogComponent = {
  templateUrl:  'components/household/main/contact-log/contact-log.component.html',
  controller:   'ContactLogController',
  controllerAs: 'vm',
  require: {
    parent: '^household',
  },
  bindings: {
    contacts: '<',
    household: '<',
    enableNavigation: '&', // This is accessed by the child form through a require
  }
};

function contactLogController($state, $log, Alerts, ContactModel, HouseholdModel) {
  let vm = this;

  vm.$onChanges = function(changedObj) {
    if (changedObj.household && changedObj.household.currentValue) {
      vm.household = changedObj.household.currentValue;
      vm.getAllContacts(vm.household.household_id);
    }
  };

  vm.saveContact = function (contact, refreshAfter = true) {
    return ContactModel.save(vm.household.household_id, contact).$promise.then(() => {
      if (refreshAfter) {
        vm.getAllContacts(vm.household.household_id);
        vm.enableNavigation({$toggle: true});
      }
    });
  };

  vm.updateContact = function (contact, refreshAfter = true) {
    return ContactModel.update(vm.household.household_id, contact.contact_id, contact).$promise.then(() => {
      if (refreshAfter) {
        vm.getAllContacts(vm.household.household_id);
        vm.enableNavigation({$toggle: true});
      }
    });
  };

  vm.deleteContact = function (contact) {
    return ContactModel.remove(vm.household.household_id, contact.contact_id).$promise.then(() => {
      vm.getAllContacts(vm.household.household_id);
      vm.enableNavigation({$toggle: true});
    });
  };

  vm.getAllContacts = function(householdId) {
    ContactModel.getAll(householdId).$promise.then(resp => {
      vm.contacts = resp;
    });
  };

  vm.onOpenForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = true;
      vm.showEditForm = false;
      vm.enableNavigation({$toggle: false});
    });
  };

  vm.onEditForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = false;
      vm.showEditForm = true;
      vm.enableNavigation({$toggle: false});
    });
  };
}

angular
    .module('tmr-admin')
    .component('contactLog', contactLogComponent)
    .controller('ContactLogController', contactLogController)
;
}());
