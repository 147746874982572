;(function() {
"use strict";

const scheduledEventComponent = {
  templateUrl:  'components/household/main/scheduled-event/scheduled-event.component.html',
  controller:   'ScheduledEventController',
  controllerAs: 'vm',
  bindings: {
    // for named resolves the inbound binding also needs to be named, not clear in the ui router docs
    scheduledEvents: "<",
    household: '<',
    enableNavigation: '&', // This is accessed by the child form through a require
  }
};

// ngInject
function scheduledEventController($log, Alerts, ScheduledEventModel, HouseholdModel) {
  let vm = this;

  vm.$onChanges = function(changedObj) {
  };

  vm.saveEvent = function (scheduledEvent) {
    ScheduledEventModel.save(vm.household.household_id, scheduledEvent).$promise.then(() => {
      ScheduledEventModel.getAllForHousehold(vm.household.household_id).$promise.then(resp => {
        vm.scheduledEvents = resp;
        vm.enableNavigation({$toggle: true});
      });
    });
  };

  vm.updateEvent = function (scheduledEvent) {
    ScheduledEventModel.update(vm.household.household_id, scheduledEvent.scheduled_event_id, scheduledEvent).$promise.then(() => {
      ScheduledEventModel.getAllForHousehold(vm.household.household_id).$promise.then(resp => {
        vm.scheduledEvents = resp;
        vm.enableNavigation({$toggle: true});
      });
    });
  };

  vm.deleteContact = function (scheduledEvent) {
    return ScheduledEventModel.remove(vm.household.household_id, scheduledEvent.scheduled_event_id).$promise.then(() => {
      ScheduledEventModel.getAllForHousehold(vm.household.household_id).$promise.then(resp => {
        vm.scheduledEvents = resp;
        vm.enableNavigation({$toggle: true});
      });
    });
  };

  vm.onOpenForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = true;
      vm.showEditForm = false;
      vm.enableNavigation({$toggle: false});
    });
  };

  vm.onEditForm = function() {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then((resp) => {
      Alerts.removeAll();
      vm.showAddForm = false;
      vm.showEditForm = true;
      vm.enableNavigation({$toggle: false});
    });
  };
}

angular
    .module('tmr-admin')
    .component('scheduledEvent', scheduledEventComponent)
    .controller('ScheduledEventController', scheduledEventController)
;
}());
