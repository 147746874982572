;(function() {
"use strict";

const invalidationModalComponent = {
  templateUrl:  'components/household/main/contact-log/contact-form/invalidation-modal/invalidation-modal.component.html',
  controller:   'InvalidationModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&'
  },
};

function invalidationModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('invalidationModal', invalidationModalComponent)
    .controller('InvalidationModalController', invalidationModalController)
;
}());
