;(function() {
"use strict";

const managerComponent = {
  templateUrl:  'components/manager/manager.component.html',
  controller:   'ManagerController',
  controllerAs: 'vm'
};

function managerController(Auth, FieldworkerModel, $log, $state, Alerts, Project) {
  let vm = this;
  vm.auth = Auth;

  FieldworkerModel.getAll().$promise.then((fieldworkers) => {
    vm.fieldworkers = fieldworkers;
  });

  vm.isActive = function(name) {
    return $state.includes(name)
  }

  vm.goToFieldworker = function() {
    if (angular.isUndefined(vm.selectedFieldworker)) {
      Alerts.add('danger', 'Please select a fieldworker');
    } else {
      $state.go('workSummary',
        { fieldworker_id: vm.selectedFieldworker.admin_user_id }
      );
    }
  };

  vm.setSelectedFieldworker = function(fieldworker) {
    vm.selectedFieldworker = fieldworker;
  };
}

angular
    .module('tmr-admin')
    .component('manager', managerComponent)
    .controller('ManagerController', managerController)
;
}());
