;(function() {
"use strict";

angular.module('tmr-admin').factory('SurveyWeekModel', (SurveyWeekResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = SurveyWeekResource;

  function get(surveyWeekId) {
    return resource.get({ survey_week_id: surveyWeekId });
  }

  function getAll() {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource.getAll();
  }

  function getAllClustersForWeek(surveyWeekId) {
    return resource.getAllClustersForWeek({ survey_week_id: surveyWeekId });
  }

  return {
    get:                    get,
    getAll:                 getAll,
    getAllClustersForWeek:  getAllClustersForWeek
  };
});
}());
