;(function() {
"use strict";

const householdComponent = {
  templateUrl:  'components/household/household.component.html',
  controller:   'HouseholdController',
  controllerAs: 'vm',
  bindings: {
    household: '<household',
  }
};

function householdController($log, $state, $window, Auth, Persistence, PreviousUrl, Project) {
  let vm = this;
  vm.auth = Auth;
  vm.projectFeatures = Project.getProjectFeatures();
  vm.$onInit = activate;
  vm.$onChanges = change;

  let persistence = Persistence();
  let previousUrl = PreviousUrl('householdUrl');

  function activate() {
    angular.extend(vm, {
      activeTab:              'household',
      back:                   back,
      home:                   home,
      loginUrl:               loginUrl,
    });
  }

  function loginUrl() {
    // Don't be tempted to make this a static variable - we need the
    // cachebuster parameter to get refreshed each time. There's a time-limited
    // JWT involved that will fail if the result gets cached.
    return '/api/survey'
         + '?household_id=' + vm.household.household_id
         + '&admin_user_id=' + vm.auth.current.user.admin_user_id
         + '&cachebuster=' + Math.floor(Date.now() / 1000)
         + '&tab_name=' + ($state.current.url).toString()
         ;
  };

  function back() {
    previousUrl.go();
  }

  function home() {
    const { params, state } = vm.auth.current.user.homeState;
    $state.go(state, params);
  }

  function change(changedObj) {
  }
}

angular
    .module('tmr-admin')
    .component('household', householdComponent)
    .controller('HouseholdController', householdController)
;
}());
