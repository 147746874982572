;(function() {
"use strict";

const eventFormComponent = {
  templateUrl:  'components/household/main/scheduled-event/event-form/event-form.component.html',
  controller:   'EventFormController',
  controllerAs: 'vm',
  require: {
    // This lets us access function on the parent controller, only available in $onInit (and after)
    parent: '^scheduledEvent'
  },
  bindings: {
    model: "<",
    mode: "@"
  }
};

// ngInject
function eventFormController($log, $uibModal, Auth, Alerts, uibDateParser, MetaModel, ScheduledEventModel, FieldworkerModel) {
  let vm = this;
  vm.auth = Auth;
  vm.$onInit = function() {
    MetaModel.scheduledEventTypes().then(resp => {
      vm.scheduledEventTypes = resp;
    });
    if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
      getFieldworkers();
    }
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.model && changedObj.model.currentValue) {
      vm.model = changedObj.model.currentValue;
      if (vm.model.assigned_to_id === vm.auth.current.user.admin_user_id) {
        vm.model.assignedToCurrent = true;
      }
    }
    if (changedObj.mode && changedObj.mode.currentValue) {
      vm.mode = changedObj.mode.currentValue;
      if (vm.mode === "new") {
        vm.model = {
          assigned_to_id: null,
          scheduled_for: new Date(),
          time_window_id: null,
        };
      }
    }
  };
  // The model date needs to be a js date, use uibDateParser to coerce this.
  // vm.model.date = new Date();

  vm.setDate = function(date) {
    if (date) {
      vm.model.scheduled_for = date;
    }
  };

  vm.save = function() {
    // TODO this selecet needs a better default where empty string isnt the value but im in a major hurry here. RA
    if (vm.model.assigned_to_id === "") {
      vm.model.assigned_to_id = null;
    }
    if (vm.mode === "new") {
      vm.parent.saveEvent(vm.model);
    } else {
      vm.parent.updateEvent(vm.model);
    }
    // TODO simplify this wackyness
    vm.parent.showAddForm = false;
    vm.parent.showEditForm = false;
  };

  vm.cancel = function() {
    vm.model = {};
    // TODO simplify this wackyness
    vm.parent.showAddForm = false;
    vm.parent.showEditForm = false;
    vm.parent.enableNavigation({$toggle: true});
  };

  const getFieldworkers = function() {
    FieldworkerModel.getAll().$promise.then((fieldworkers) => {

      vm.fieldworkers = fieldworkers;
      const fwHash = _.keyBy(vm.fieldworkers, 'admin_user_id');

      // If assigned_to is set, there's a chance it may be an inactive fieldworker,
      // in which case, they won't appear in the list retrieved from the backend
      // (which is active only). If that's the case, then re-insert the assigned_to
      // fw into the list so that it displays properly when editing an existing
      // scheduled event.
      if ( !_.isNil(vm.model.assigned_to) && !fwHash[vm.model.assigned_to_id]) {
        vm.fieldworkers.unshift(vm.model.assigned_to);
      }
    });
  };

  vm.assignToCurrent =  function() {
    if (vm.model.assignedToCurrent === true) {
      vm.model.assigned_to_id = vm.auth.current.user.admin_user_id;
    } else {
      vm.model.assigned_to_id = null;
      vm.model.assigned_to = null;
    }
  };

  vm.typeSelected = function() {
    if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
      return;
    } else if (vm.model.scheduled_event_type_id === 2) {
      vm.model.assignedToCurrent = false;
      return;
    }
    vm.model.assignedToCurrent = true;
    vm.assignToCurrent();
  };

  vm.delete = function() {
    let modalInst = $uibModal.open({
      component: 'deleteModal',
      resolve: {
        title: () => {
          return "scheduled";
        },
        type: () => {
          return vm.model.scheduled_event_type;
        },
        time: () => {
          return vm.model.scheduled_for;
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      vm.parent.deleteContact(vm.model).then(resp => {
        let alertStr = 'Deleted scheduled event ' + vm.model.scheduled_event_id;
        Alerts.add('warning', alertStr, ['household']);
        vm.parent.showEditForm = false;
      });
    }, function () {
      // If cancel is hit
    });
  };
}

angular
    .module('tmr-admin')
    .component('eventForm', eventFormComponent)
    .controller('EventFormController', eventFormController)
;
}());
