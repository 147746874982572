;(function() {
"use strict";

angular.module('tmr-admin').factory('ReminderLogModel', ['ReminderLogResource', (ReminderLogResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = ReminderLogResource;

  function getAll(filter) {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource.query(filter);
  }

  return {
    getAll: getAll,
  };
}]);
}());
