;(function() {
"use strict";

const contactListComponent = {
  templateUrl:  'components/household/main/contact-log/contact-list/contact-list.component.html',
  controller:   'ContactListController',
  controllerAs: 'vm',
  require: {
    parent: '^contactLog'
  },
  bindings: {
    contacts: '<',
    household: '<'
  }
};

function contactListController($log, $uibModal, NgTableParams) {
  let vm = this;

  vm.$onChanges = function(changedObj) {
    if (changedObj.contacts && changedObj.contacts.currentValue) {
      vm.contacts = angular.copy(changedObj.contacts.currentValue);
      vm.tableParams = new NgTableParams({
        count: vm.contacts.length,
        sorting: { contact_time: "desc" }
      }, {
        dataset: vm.contacts,
        counts: [], // hides page sizes
      });
    }
  };

  vm.editContact = function(contact) {
    // TODO this is not a good way of doing this, altering parent values to show/hide bits
    vm.parent.contactToEdit = contact;
    vm.parent.onEditForm();
  };

  vm.openModal = function(household_note, event_note) {
    let modalInst = $uibModal.open({
      component: 'noteModal',
      resolve: {
        notes: () => {
          return { household: household_note, event: event_note };
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      return;
    }, function () {
      // If cancel is hit
      return;
    });
  };
}

angular
    .module('tmr-admin')
    .component('contactList', contactListComponent)
    .controller('ContactListController', contactListController)
;
}());
