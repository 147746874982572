;(function() {
"use strict";

angular
  .module('tmr-admin')
  .run(run);

/* @ngInject */
function run($transitions, $state, $log, $urlService, Auth, Alerts) {

  function onError(trans) {
    if (trans.error() === 'Unauthorized') {
      $state.go('login');
    }
    $log.error(trans.error());
  }

  function checkAccess(trans) {
    const forbiddenState = '403';
    Alerts.removeAll(trans.to().name);
    // Shitty docs for the new angular transitions is at https://ui-router.github.io/ng2/docs/latest/classes/transition.transition-1.html
    // Here we need to check if the user is actually able to land on this state
    if (!Auth.canAccessState(trans.to().name)) {
      // lets just make sure that the
      $state.go(forbiddenState);
    }
  }

  $transitions.onError({ }, onError);
  $transitions.onStart({ }, checkAccess);

  // This is the fallback handler when no routes match. It's defined here and
  // not in app.js because we need runtime services.
  // Note that the docs say (url, router) as the params, but the debugger says
  // otherwise.
  // https://ui-router.github.io/ng1/docs/latest/interfaces/url.urlrulesapi.html#otherwise
  $urlService.rules.otherwise((dunno, url, router) => {

    // Find out if we're logged in. If we have no user object, this will fetch
    // one and return the promise. If we do, it'll return the already-resolved
    // promise, which will immediately resolve.
    Auth.login().then(user => {
      if (!Auth.isLoggedIn()) {
        // There wasn't a user. Off to the login page.
        $state.go('login');
        return;
      }

      if ($state.current.name != '') {
        // If we have a previous state, abort the transition and send them back
        // to where they came from.
        Alerts.add('warning', url.path + ': not found');
        $state.go($state.current);
      }
      else if (user.projects.length != 1) {
        // User is assigned to multiple projects - force them to pick the
        // project they're logging into. This also handles the no-projects
        // case.
        $state.go('projectSelector');
      }
      else {
        // If there's no previous state, send them to their home state.
        $state.go(user.homeState.state, user.homeState.params);
      }
    });

    return; // Waiting on the user promise - nothing to return yet.
  });
}
}());
