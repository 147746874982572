;(function() {
"use strict";

angular.module('tmr-admin').factory('Alerts', function($log) {
  let currentAlerts = [];

  function add(type, message, whitelist = []) {
    currentAlerts.push({type, message, whitelist});
  }

  function remove(index) {
    currentAlerts.splice(index, 1);
  }

  function removeAll(state) {
    let newAlerts = [];
    for (let alert of currentAlerts) {
      if (alert.whitelist.indexOf(state) > -1) {
        newAlerts.push(alert);
        continue;
      }
    }
    currentAlerts = newAlerts;
  }

  function getAll() {
    return currentAlerts;
  }

  return {
    add:        add,
    getAll:     getAll,
    remove:     remove,
    removeAll:  removeAll
  };
});
}());
