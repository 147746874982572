;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('TablePersistence', function($log, Persistence) {

  // Converts: { foo: 'asc', bar: 'desc' } to "foo,-bar"
  function encodeSort(sortParams) {
    return _.map(sortParams, (v, k) => ((v == 'asc') ? '' : '-') + k).join(',');
  }

  // Converts: "foo,-bar" to { foo: 'asc', bar: 'desc' }
  function decodeSort(str) {
    let sortParams = { };
    _.each(str.split(','), v => {
      let sign = v.substr(0, 1);
      if (sign == '+') {
        sortParams[v.substr(1)] = 'asc';
      }
      else if (sign == '-') {
        sortParams[v.substr(1)] = 'desc';
      }
      else {
        sortParams[v] = 'asc';
      }
    });
    return sortParams;
  }

  function field(encode, decode) {
    return { encode, decode }
  }

  let fields = {
    sorting:  field(encodeSort, decodeSort),
    page:     field(_.identity, _.parseInt),
    count:    field(_.identity, _.parseInt),
  };

  function create() {

    let persistence = Persistence();

    function set(tableParams) {
      // if we have filters that are not valid, localFilters only have valid filters
      let filter = tableParams.localFilters || tableParams.filter();
      let values = _.clone(filter);

      _.each(fields, (codec, key) => {
          let value = tableParams[key]();
          values[key] = codec.encode(value);
      });

      persistence.setAll(values);
    }

    function get(opts=[]) {
      let values = persistence.getAll();
      let tableParams = { };

      _.each(fields, (codec, key) => {
        if (_.has(values, key)) {
          let fromValue = values[key];
          delete values[key];

          let toValue = codec.decode(fromValue);
          if (!_.isUndefined(toValue)) {
            tableParams[key] = toValue;
          }
        }
      });

      tableParams.filter = values;

      // for external values that don't exist in the 'fields' hash above
      // but we want to grab it from the url
      _.forEach(opts, (item) => {
        if (_.has(values, item)) {
          tableParams[item] = values[item];
        }
      });
      return tableParams;
    }

    return {
      get,
      set,
      encodeSort,
      decodeSort,
    };
  }

  // This factory just returns a constructor function.
  return create;
});
}());
