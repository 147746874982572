;(function() {
"use strict";

/*

 This module is a little tricky, but not too bad.

 Accordions work by giving each panel an `is-open="isOpen"` attribute, and these
 get updated to reflect which panels are open. Each panel object in your array
 will get a boolean isOpen field.

 For our one-panel-at-a-time case, we really want to know which panel is open.

 One solution would be to add watches to all the isOpen fields, but this isn't
 such a great idea.

 The trick is to create an accessor property in each panel object, and set that
 as the is-open="isOpen" attribute. That way we effectively get a callback
 function when any of the is-open attributes change.

 Use it like this:

    <uib-accordion close-others="true">
        <uib-accordion-group
            class="panel-default"
            ng-repeat="panel in vm.panels"
            heading="{{panel.heading}}"
            is-open="panel.isOpen" >
          <!-- .... -->
        </uib-accordion-group>
    </uib-accordion>

 Then create the isOpen accessors like this:

    let persistence = AccordionPersistence();

    persistence.attach(vm.panels, 'panel', 'name', 'isOpen');


 The params are:

    array:
      The panel objects that will get the accessor added

    persistenceKey:
      The key used in the &key=value url query string

    objectKey:
      The value used in the &key=value query string. This can be a field name,
      or a function that takes an object. These must generate a unique value
      per panel object.

    isOpen:
      The name of the accessor object. This needs to match the right-hand-side
      of the html attribute. ie. `is-open="panel.isOpen"`

*/
angular
  .module('tmr-admin')
  .factory('AccordionPersistence', function($log, Persistence) {

  function noop() { }

  function alwaysTrue() {
    return true;
  }

  function create() {

    let persistence = Persistence();

    function attach(array, persistenceKey, objectKey, canOpen = alwaysTrue, callback = noop, isOpen = 'isOpen') {
      if (array.length == 0) {
        return;
      }

      let getObjectKey = _.isFunction(objectKey)
          ? objectKey : (object) => object[objectKey];

      // Note that we don't actually store anything per-object, just a reference
      // to the active one.
      let activeItem;
      setActiveItem(getInitialItem());

      //$log.log("ACTIVE SLOT=", activeItem);

      function getInitialItem() {
        let savedKey = persistence.getValue(persistenceKey);
        if (!_.isUndefined(savedKey)) {
          // $log.log(`Found saved ${persistenceKey}=${savedKey}`);
          let found = _.find(array, (item) => getObjectKey(item) == savedKey);
          if (found) {
            // $log.log(`Using saved ${persistenceKey}=${savedKey}`);
            if (canOpen(found)) {
              return found;
            }
          }
          // $log.log(`Saved ${persistenceKey}=${savedKey} is not valid`);
        }
        
        let defaultItem = _.find(array, item => canOpen(item));
        
        // $log.log(`Using default ${persistenceKey}=`, defaultItem);
        return defaultItem;
      }

      function setActiveItem(item) {
        activeItem = item;
        let key = _.isUndefined(item) ? undefined : getObjectKey(item);
        persistence.setValue(persistenceKey, key);
        callback(persistenceKey, item);
        //$log.log(`${persistenceKey} active is ${key}`, activeItem);
      }

      //$log.log(`Attaching ${isOpen} to ${persistenceKey}`, array);
      _.each(array, item => {
        let key = getObjectKey(item);
        //$log.log(`... ${key}`, item);
        function get() {
          return activeItem == item;
        }
        function set(isActive) {
          if (isActive) {
            setActiveItem(item);
          }
          else if (activeItem == item) {
            setActiveItem(undefined);
          }
        }
        Object.defineProperty(item, isOpen, { get, set });
      });

      function getActiveItem() {
        return activeItem;
      }

      return getActiveItem;
    }

    return {
      attach: attach,
    };
  }

  // This factory just returns a constructor function.
  return create;
});
}());
