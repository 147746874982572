;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('Storage', Storage);

function Storage(Project) {
  function create() {

    function setItem(key, val) {
      checkProjectChange();
      try {
        const value = JSON.stringify(val);
        sessionStorage.setItem(key, value);
      }
      catch(e) {
        console.log('Something went wrong', e);
      }
    }

    function getItem(key) {
      checkProjectChange();
      try {
        const value = JSON.parse(sessionStorage.getItem(key));
        return value;
      }
      catch(e) {
        //console.log('Something went wrong', e);
        return undefined;
      }
    }

    function checkProjectChange() {
      let key = 'projectId';
      let currentProjectId = Project.getProjectID();
      let storedProjectId  = sessionStorage.getItem(key);

      if (storedProjectId && currentProjectId != storedProjectId) {
        sessionStorage.clear();
      }
      sessionStorage.setItem(key, currentProjectId);
    }

    function removeItem(key) {
      sessionStorage.removeItem(key);
    }

    function clear() {
      sessionStorage.clear();
    }

    return {
      setItem,
      getItem,
      removeItem,
      clear,
    }
  }

  return create;
}
}());
