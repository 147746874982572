;(function() {
"use strict";

const resolveEventFormComponent = {
  templateUrl:  'components/household/event/resolve-event-form/resolve-event-form.component.html',
  controller:   'ResolveEventFormController',
  controllerAs: 'vm',
  require: {
    parent: '^householdEvent'
  },
  bindings: {
    entry: '<', // the scheduled event we want to resolve
    parentContact: '<', // the contact event that created the target scheduled event
    enableNavigation: '&',
    contact: '<', // use when we want to edit a contact event that resolved a scheduled event
  }
};

function resolveEventFormController($q, $state, $filter, $log, $uibModal, Alerts, Auth, HouseholdModel, ScheduledEventModel) {
  let vm = this;
  vm.$onInit = function() {
    vm.entry.contact = angular.copy(vm.parentContact);
  };
}

angular
    .module('tmr-admin')
    .component('resolveEventForm', resolveEventFormComponent)
    .controller('ResolveEventFormController', resolveEventFormController)
;
}());
