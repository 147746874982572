;(function() {
"use strict";

const householdMainComponent = {
  templateUrl:  'components/household/main/household-main.component.html',
  controller:   'HouseholdMainController',
  controllerAs: 'vm',
  bindings: {
    household: '<household',
    activeTab: '<',
    previousUrl: '<',
  },
  require: {
    parent: '^household'
  },
};

// ngInject
function householdMainController($log, Auth, Alerts, ContactModel, ScheduledEventModel, HouseholdModel) {
  let vm = this;
  vm.auth = Auth;
  vm.canNavigate = true;

  vm.$onChanges = function(changedObj) {
    // Set the active tab to be other than the default (0 = contact_log)
    if (changedObj.activeTab && changedObj.activeTab.currentValue) {
      vm.active = changedObj.activeTab.currentValue * 1;
    }
    if (changedObj.household.isFirstChange()) {
      if (vm.parent.projectFeatures.linked_events) {
        // as linkend events feature is active,
        // household-main only needs to keep updating household details
        vm.getHousehold().then(resp => {
          vm.parent.household = resp;
        });
      }
      else {
        // This is clobbering changes. See #288.
        // This isn't the right fix, but it's good enough for now.
        //vm.household = angular.copy(changedObj.household.currentValue);
        ScheduledEventModel.getAllForHousehold(vm.household.household_id).$promise.then(resp => {
          vm.scheduledEvents = resp;
        });
        ContactModel.getAll(vm.household.household_id).$promise.then(resp => {
          vm.contacts = resp;
        });
      }
    }
  };

  vm.enableNavigation = function(toggle) {
    // Toggles wether you can leave the page by conventional means
    if (angular.isUndefined(toggle)) {
      vm.canNavigate = !vm.canNavigate;
    } else {
      vm.canNavigate = toggle;
    }
    if (vm.canNavigate === true) {
      vm.getHousehold();
    }
  };

  vm.getHousehold = function() {
    return HouseholdModel.get(vm.household.household_id).$promise.then(resp => {
      vm.household = resp;
      return resp;
    });
  };

  vm.save = function(withAlert) {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then(() => {
      if (withAlert) {
        Alerts.add('success', `Saved household ${$filter('tmrToken')(vm.household.token)}`);
      }
    });
  };
}

angular
    .module('tmr-admin')
    .component('householdMain', householdMainComponent)
    .controller('HouseholdMainController', householdMainController)
;
}());
