;(function() {
"use strict";

const userCodeComponent = {
  templateUrl:  'components/user/code/user-code.component.html',
  controller: 'UserCodeController',
  controllerAs: 'vm',
  bindings: {
    user: '<',
    noTooltip:   '@',
  }
};

function userCodeController() {
  let vm = this;
}

angular
  .module('tmr-admin')
  .component('userCode', userCodeComponent)
  .controller('UserCodeController', userCodeController)
;
}());
