;(function() {
"use strict";

const assignEventsComponent = {
  templateUrl:  'components/manager/assign-events/assign-events.component.html',
  controller:   'AssignEventsController',
  controllerAs: 'vm',
  bindings: {
    weeks: '<weeks',
  }
};

// ngInject
function assignEventsController($log, $q, $state, Alerts, SurveyWeekModel, ClusterModel, FieldworkerModel, MetaModel) {
  let vm = this;
  vm.$onInit = function() {
  };

  vm.$onChanges = function(changedObj) {
  };

  // -------------------Getters---------------------------

  // -------------------Setters---------------------------
}

angular
    .module('tmr-admin')
    .component('assignEvents', assignEventsComponent)
    .controller('AssignEventsController', assignEventsController)
;
}());
