;(function() {
"use strict";

const managerMainComponent = {
  templateUrl:  'components/manager/manage-users/index.html',
  controller:   'ManagerMainController',
  controllerAs: 'vm',
  bindings: {
    previousUrl: '<',
  },
  require: {
  },
};

// ngInject
function managerMainController($log, $state, Auth, Alerts, Storage, $uibModal) {
  let vm = this;
  let storage   = Storage();
  vm.auth = Auth;
  vm.canNavigate = true;
  vm.createInvite = false;

  vm.isActive = function(name) {
    return $state.current.name == name;
    };
  
  vm.showInviteForm = () => {
    const modalInstance = $uibModal.open({
      component: 'newUserInvite',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        newInvite: () => {
          return vm.newInvite;
        }
      }
    });
    modalInstance.result.then((newInvite) => {
      this.newInvite = newInvite;
    }).catch(() => {
      modalInstance.close();
    })

  }

  // This is a bit of a pain - there's bound to be a better way to do it.
  // The problem is that the 'add user' and 'delete/resend invite' components
  // are separate pages, thety're not children of the pending user tab.
  // We determine which tab requires activation via storage retrieval.
  vm.$onInit = function() {
    vm.activeTab = $state.current.name === 'currentUsers' ? 0 : 1;
    // clear previously-selected tab value
  };

  vm.$onChanges = function(changedObj) {
  };

  vm.enableNavigation = function(toggle) {
    // Toggles wether you can leave the page by conventional means
    if (angular.isUndefined(toggle)) {
      vm.canNavigate = !vm.canNavigate;
    } else {
      vm.canNavigate = toggle;
    }
    if (vm.canNavigate === true) {
        ; // FIXME: can probably remove this...
    }
  };

  // flag to track whether the child 'pending invitations' tab component
  // needs to refresh the list of users after a new invitation is added:
  vm.refresh_pending = false;
}

angular
    .module('tmr-admin')
    .component('managerMain', managerMainComponent)
    .controller('ManagerMainController', managerMainController)
;
}());
