;(function() {
"use strict";

const dateTimeComponent = {
  templateUrl:  'components/inputs/date-time/date-time.component.html',
  controller:   'DateTimeController',
  controllerAs: 'vm',
  bindings: {
    model: '<',
    lowerBound: '<',
    readonly: '<',
    setDate: '&',
    prefix: '@',
  }
};

function dateTimeController($log, Auth, Project) {
  let vm = this;
  vm.auth = Auth;

  vm.dateOptions = { showWeeks: false };
  vm.projectFeatures = Project.getProjectFeatures();

  vm.$onChanges = function (changedObj) {
    //$log.info(changedObj.readonly.currentValue);
    if (changedObj.model && changedObj.model.currentValue) {
      vm.date = new Date(angular.copy(changedObj.model.currentValue));
    }

    if (changedObj.lowerBound && changedObj.lowerBound.currentValue) {
      // if we have the lower boundary, set minDate with it
      vm.minDate = new Date(angular.copy(changedObj.lowerBound.currentValue));
      vm.dateOptions.minDate = vm.minDate;
      if (vm.date < vm.minDate) {
        $log.info("Move the date up to the lower bound");
        vm.date = angular.copy(vm.minDate);
      }
    }

    if (!vm.minDate &&
          !vm.auth.current.user.is_owner && !vm.auth.current.user.is_manager
          && vm.prefix == "Scheduled") {
      // only managers are able to set schedule event for past dates
      vm.minDate = new Date();
      vm.dateOptions.minDate = vm.minDate;
    }
  };
}

angular
    .module('tmr-admin')
    .component('dateTime', dateTimeComponent)
    .controller('DateTimeController', dateTimeController)
;
}());
