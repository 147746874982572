;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('MetaResource', MetaResource);

/* @ngInject */
function MetaResource($resource) {
  return $resource('/api/meta/:field', null, {
    'get': {isArray: true, cache: true}
  });
}
}());
