;(function() {
"use strict";

angular
  .module('tmr-admin')
  .filter('tmrToken', ($log) => {
    return function(str) {
      let reg = new RegExp('([A-Z0-9]{4})([A-Z0-9]{1,})');
      let arr = reg.exec(str);
      if (arr === null) {
        $log.log("Access Code filter regex failed");
        return str;
      }
      return `${arr[1]}\u2011${arr[2]}`;
    };
  })
  ;
}());
