;(function() {
"use strict";

angular.module('tmr-admin').factory('HouseholdModel', (HouseholdResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = HouseholdResource;

  function get(householdId) {
    return resource("").get({ household_id: householdId });
  }

  function update(householdId, household) {
    return resource("").update({ household_id: householdId }, household);
  }

  function notes(householdId) {
    return resource("").notes({household_id: householdId});
  }

  function getAllHouseholdEvent(householdId) {
    return resource("").allHouseholdEvent({household_id: householdId});
  }

  function getAll() {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource("").query();
  }

  function getAllFiltered(range, sort, filters) {
    let params = {};
    let filtersCopy = angular.copy(filters);
    params["sort"] = sort;
    _.merge(params, filtersCopy);
    return resource(range).withFilters(params);
  }

  function resetToken(householdId) {
    return resource("").resetToken({ household_id: householdId }, {});
  }

  function invalidate(householdId) {
    return resource("").invalidate({ household_id: householdId }, {});
  }

  function byToken(token) {
    return resource("").byToken({token: token});
  }

  return {
    get:        get,
    getAll:     getAll,
    getAllFiltered: getAllFiltered,
    getAllHouseholdEvent: getAllHouseholdEvent,
    update:     update,
    notes:      notes,
    resetToken: resetToken,
    invalidate: invalidate,
    byToken: byToken
  };
});
}());
