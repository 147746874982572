;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('TimeWindows', TimeWindows);

function TimeWindows(MetaModel) {
  const windowsPromise = MetaModel.timeWindows();

  function getDateTime(tw, date) {
    const ret = new Date(date);
    ret.setHours(0);
    ret.setMinutes(tw.minutes_past_midnight);
    return ret;
  }

  // Walk through the time windows and find the first one that starts after
  // the start time. It may be that the start time is beyond the final time
  // window, so we'd need the first one on the next day.
  // We handle this with dayOffset. In reality, we'll only ever hit the first
  // one on the next day, but the code is simpler if we just loop until we hit
  // one.
  function nextWindow(windows, startTime) {
    for (let dayOffset = 0; ; dayOffset++) {
      for (const tw of windows) {
        const dateTime = new Date(startTime);
        dateTime.setDate(dateTime.getDay() + dayOffset);
        dateTime.setHours(0);
        dateTime.setMinutes(tw.minutes_past_midnight);
        if (dateTime > startTime) {
          return {
            dateTime:  dateTime,
            id:        tw.id,
          };
        }
      }
    }
  }

  function create() {
    return {
      getDateTime,
      nextWindow,
      windowsPromise,
    };
  }

  return create;
}
}());
