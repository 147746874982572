;(function() {
"use strict";

angular.module('tmr-admin').factory('ContactResource', ($resource, Project) => {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/households/:household_id/contacts/:contact_id', paramDefaults, {
    // $resources does not have an update method registered by default
    'update': { method: 'PUT' },
    'search': { method: 'GET', url:'/api/project/:project_id/households/:household_id/contacts', isArray: true}
  });
});
}());
