;(function() {
"use strict";

const fieldworkerNameComponent = {
  templateUrl:  'components/fieldworker/name/fieldworker-name.component.html',
  controller: 'FieldworkerNameController',
  controllerAs: 'vm',
  bindings: {
    fieldworker: '<',
    noTooltip:   '@',
  }
};

function fieldworkerNameController(BooleanAttribute) {
  let vm = this;
  vm.$onInit = init;

  function init() {
    angular.extend(vm, {
      hasNoTooltip: hasNoTooltip,
    });
  }

  function hasNoTooltip() {
    return BooleanAttribute(vm.noTooltip);
  }
}

angular
  .module('tmr-admin')
  .component('fieldworkerName', fieldworkerNameComponent)
  .controller('FieldworkerNameController', fieldworkerNameController)
;
}());
