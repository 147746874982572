;(function() {
"use strict";

// show loading if network request takes more than 750ms, can be changed in _loader.scss in line 29
angular.module('tmr-admin').directive('globalLoading', ['$http', ($http) => {
    return {
        restrict: 'E',
        template: `        
        <div class="loader__container content_loading_container">
        <div class="content_loading">
          <img src="img/spinner.gif"></img>
        </div>
        </div>`,
        link: (scope, element) => {
            scope.$watch(
                () => $http.pendingRequests.length, 
                (pendingRequestsLength) => {
                    if(pendingRequestsLength > 0) {
                        element.removeClass('ng-hide');
                    } else {
                        element.addClass('ng-hide');
                    }
                });
        }
    }
}]);
}());
