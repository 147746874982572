;(function() {
"use strict";

const householdMapComponent = {
  templateUrl:  'components/household/map/household-map.component.html',
  controller:   'HouseholdMapController',
  controllerAs: 'vm',
  bindings: {
    household: '<',
  },
  require: {
    parent: '^household'
  },
};

// ngInject
function householdMapController($log, Auth) {
  let vm = this;
  vm.auth = Auth;

  vm.$onChanges = function(changedObj) {
    if (angular.isDefined(changedObj.household.currentValue)) {
      vm.mapData = [changedObj.household.currentValue];
      $log.log(vm.mapData);
    }
  };
}

angular
    .module('tmr-admin')
    .component('householdMap', householdMapComponent)
    .controller('HouseholdMapController', householdMapController)
;
}());
