;(function() {
"use strict";

const datepickerComponent = {
  templateUrl:  'components/inputs/datepicker/datepicker.component.html',
  controller:   'DatepickerController',
  controllerAs: 'vm',
  bindings: {
    model: '<',
    label: '@',
    setDate: '&',
    maxDate: '<',
    minDate: '<'
  }
};

function datepickerController($log) {
  let vm = this;
  vm.formats = ['dd-MMM-yyyy', 'dd/MM/yyyy', 'd!/M!/yyyy', 'dd-MM-yyyy'];

  vm.$onChanges = function (changedObj) {
    if (changedObj.model) {
      vm.date = changedObj.model.currentValue ? new Date(angular.copy(changedObj.model.currentValue)) : null;
    }

    vm.dpOptions = {
      datepickerMode: 'day',
      minDate: vm.minDate,
      maxDate: vm.maxDate,
      showWeeks: false,
    };
  };

  vm.showDatepicker = function() {
    //$log.log(vm.dpOptions);
    vm.popup1.opened = true;
  };

  vm.popup1 = {
    opened: false
  };
}

angular
    .module('tmr-admin')
    .component('datepicker', datepickerComponent)
    .controller('DatepickerController', datepickerController)
;
}());
