;(function() {
"use strict";

const assignWorkloadComponent = {
  templateUrl:  'components/manager/assign-workload/assign-workload.component.html',
  controller:   'AssignWorkloadController',
  controllerAs: 'vm',
  bindings: {
    weeks: '<weeks'
  }
};

// ngInject
function assignWorkloadController($log, $q, $state, Alerts, SurveyWeekModel, ClusterModel, FieldworkerModel, MetaModel, Persistence, $interval, $scope) {
  let vm = this;

  let refreshTimer;

  function refreshWorkloadList () {
    SurveyWeekModel.getAllClustersForWeek(vm.selectedWeek.survey_week_id)
                   .$promise
                   .then((workloads) => {
      vm.workloads = _.sortBy(workloads, ['name']);
    });
  }

  function stopWorkloadListRefresh () {
    if ( angular.isDefined(refreshTimer) ) {
      $interval.cancel(refreshTimer);
      refreshTimer = undefined;
    }
  }

  vm.$onInit = function() {
    vm.selectedWorkType = null;

    // poll for updates to the workload list every minute:
    refreshTimer = $interval(refreshWorkloadList, 60000);
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.weeks) {
      vm.weeks = changedObj.weeks.currentValue;
      if (!vm.selectedWeek) {
        vm.setSelectedWeek(getDefaultWeek());
      } else {
        vm.getWorkloads(vm.selectedWeek.survey_week_id);
      }
    }
  };

  // make sure that the workload list refresh $interval is destroyed
  // when we go out of scope...
  vm.$onDestroy = stopWorkloadListRefresh;

  let persistence = Persistence();

  // -------------------Getters---------------------------

  vm.getWorkloads = function(surveyWeekId) {
    let clusterId = parseInt(persistence.getValue('cluster'));
    SurveyWeekModel.getAllClustersForWeek(surveyWeekId).$promise.then((workloads) => {
      vm.workloads = _.sortBy(workloads, ['name']);
      
      let savedWorkload = _.find(vm.workloads, { cluster_id: clusterId });

      if (savedWorkload) {
        vm.setSelectedWorkload(savedWorkload)
      }
      else {
        // Select the first valid week
        vm.setSelectedWorkload(_.find(vm.workloads, 'valid_cluster'));
      }
      
    });
  };

  vm.updateWorkload = function(clusterId) {
    ClusterModel.getSummary(clusterId).$promise.then(resp => {
      let target = _.find(vm.workloads, { 'cluster_id': resp.cluster_id });
      target.summary = resp.summary;
    });
  };

  // -------------------Setters---------------------------

  vm.setSelectedWeek = function(week) {
    vm.selectedWeek = week;
    persistence.setValue('week', week.survey_week_id);
    vm.getWorkloads(week.survey_week_id);
  };

  vm.setSelectedWorkload = function(workload) {
    vm.selectedWorkload = workload;

    persistence.setValue('cluster', vm.selectedWorkload.cluster_id);

    // refresh the workload list - some cluster details may have changed since
    // it was last fetched:
    // (NB: don't call vm.getWorkloads() here...)
    refreshWorkloadList();
  };

  vm.setMapData = function(data) {
    vm.mapData = data;
  };

  vm.toggleWorkType = function(type) {
    vm.selectedWorkType =  type.label;
  };

  function getDefaultWeek() {
    // Use parseInt here because _.find seems to do a strict match.
    // Just searching for saved.week doesn't work.
    let weekId = parseInt(persistence.getValue('week'));
    let savedWeek = _.find(vm.weeks, { survey_week_id: weekId });
    if (savedWeek) {
      return savedWeek;
    }

    // Otherwise, the current week.
    let now = new Date();
    let currentWeek = _.find(vm.weeks, (week, index) => {
      let weekEnd = new Date(week.work_week_end_date + "T" + "18:00");
      return (now.getTime() <= weekEnd.getTime());
    });
    if (currentWeek) {
      return currentWeek;
    }

    // If we've run off the end of the weeks, just use the last week.
    return vm.weeks[vm.weeks.length - 1];
  }
}

angular
    .module('tmr-admin')
    .component('assignWorkload', assignWorkloadComponent)
    .controller('AssignWorkloadController', assignWorkloadController)
;
}());
