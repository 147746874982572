;(function() {
"use strict";

const resetTokenModalComponent = {
  templateUrl:  'components/household/main/household-detail/reset-token-modal/reset-token-modal.component.html',
  controller:   'ResetTokenModalController',
  controllerAs: 'vm',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
};

function resetTokenModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('resetTokenModal', resetTokenModalComponent)
    .controller('ResetTokenModalController', resetTokenModalController)
;
}());
