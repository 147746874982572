;(function() {
"use strict";

const scheduledEventWorkComponent = {
  templateUrl:  'components/fieldworker/scheduled-event-work/scheduled-event-work.component.html',
  controller:   'ScheduledEventWorkController',
  controllerAs: 'vm',
  require: {
    parent: '^fieldworker'
  },
  bindings: {
    selectedWeek: '<'
  }
};

/* @ngInject */
function scheduledEventWorkController($log, NgTableParams, $state, $filter, MetaModel, FieldworkerModel, Persistence) {
  let vm = this;
  vm.$onChanges = activate;
  vm.$onInit = init;
  let storage = Persistence().storage;

  function init() {
    MetaModel.scheduledEventTypes().then(resp => {
      vm.scheduledEventTypes = resp;
    });
  }

  function activate(changedObj) {
    if (changedObj.selectedWeek && changedObj.selectedWeek.currentValue) {
      FieldworkerModel.scheduledEvents($state.params.fieldworker_id, changedObj.selectedWeek.currentValue.scheduled_week_id).$promise.then(resp => {
        vm.scheduledEvents = [];
        // Only show CAPI events.
        _.map(resp, (item) => {
          if (item.scheduled_event_type_id === 1) {
            vm.scheduledEvents.push(item);
          }
        });
        let CAPI = storage.getValue('CAPI');
        if(CAPI && CAPI.child) {
          let found = false;
          vm.scheduledEvents.forEach(item => {
            if(item.scheduled_event_id === CAPI.child.scheduled_event_id ) {
              found = true;
              item.innerOpen = CAPI.child.innerOpen
            }
          })
          if(!found) {
            storage.removeValue('CAPI', undefined);
          }
        }

        // --------------------------- Friggin Around ---------------------------------
        // In order to have a default element opened in the accordion, have to know the order of things, hence arraifying this, I may not have done this in an even vaguely simple way.
        let todo = [];
        let data = _.groupBy(vm.scheduledEvents, ev => formatDate(ev.scheduled_for));
        _.map(data, (item, key) => {
          // Pretty rough way to get the date of the group

          // sorted events by scheduled_for time in ascending order
          // but push resolved events to the bottom
          const items = _.orderBy(item, ['scheduled_for'], ['asc']);
          let sorted_content = [];
          let sorted_resolved_content = [];
          _.forEach(items, (entry) => {
            if (entry.resolved_by_contact_id) {
              sorted_resolved_content.push(entry);
            }
            else {
              sorted_content.push(entry);
            }
          });
          sorted_content = [...sorted_content, ...sorted_resolved_content];
          todo.push({heading: key, content: sorted_content, timestamp: new Date(item[0].scheduled_for).getTime()});
        });
        // Sort the todo array by date
        todo = _.sortBy(todo, "timestamp"); // .reverse() to invert;

        // --------------------------- End Friggin -------------------------------------
        vm.capiAccordionData = {
          'todo': todo,
        };

        let content = [];
        // $log.log(_.groupBy(vm.scheduledEvents, ev => formatDate(ev.scheduled_for)));
        if (vm.capiAccordionData.todo.length) {
         
          if(CAPI) {
            vm.capiAccordionData.todo.forEach(item => {
              if(item.heading === CAPI.heading) {
                item.open = CAPI.open;
                content = item.content;
              }
            })
          } else {
            vm.capiAccordionData.todo[0].open = true;
            content = todo[0].content;
          }
          // Default select to the first item
          // vm.makeMapData(null, todo[0], false);
          let households = [];
          content.forEach(contact => households.push(contact.household));
          vm.parent.setMapData(households);
        } else {
          // if no data, call setData with empty array to update the tmr-map
          vm.parent.setMapData([]);
        }
      });
    }
  }

  vm.handleMapData = function(event, contact) {
    const { scheduled_event_type, household } = contact;
    // only parent element has those two information
    if(event.target.innerHTML.includes(`${scheduled_event_type}, ${household.address}`)) {
      contact.innerOpen = !contact.innerOpen;
      let CAPI = storage.getValue('CAPI')
      if(CAPI) {
        CAPI = Object.assign(CAPI, { child: { scheduled_event_id: contact.scheduled_event_id, innerOpen: contact.innerOpen }});
        storage.setValue('CAPI', CAPI)
      }
    }
    vm.parent.setMapData(contact.household);
  }

  vm.makeMapData = function(event, item) {

    const { heading, content } = item;
    // only react when the parent div is clicked
    if(event.target.innerText === heading) {
      item.open = !item.open;
      let CAPI = storage.getValue('CAPI');
      if(CAPI){
         CAPI = Object.assign(CAPI, { heading: item.heading, open: item.open });
      } else {
         CAPI = { heading: item.heading, open: item.open };
      }
      storage.setValue('CAPI', CAPI)
    }
    let households = [];
    content.forEach(contact => {
      households.push(contact.household);
    });
    vm.parent.setMapData(households);
  };
  // XXX: Do I really want to do this?
  function formatDate(date, format = 'dd/MM/yyyy') {
    return $filter('date')(date, format);
  }
}

angular
  .module('tmr-admin')
  .component('scheduledEventWork', scheduledEventWorkComponent)
  .controller('ScheduledEventWorkController', scheduledEventWorkController)
;
}());
