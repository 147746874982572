;(function() {
"use strict";

angular.module('tmr-admin')
.config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
  $stateProvider
  .state('assignWorkload', {
    parent: 'manager',
    url: '/assign-workload',
    views: {
      "content@manager" : {
        component: 'assignWorkload'
      }
    },
  })
  .state('assignEvents', {
    parent: 'manager',
    url: '/assign-events',
    views: {
      "content@manager" : {
        component: 'assignEvents'
      }
    }
  })
  .state('householdsListAll', {
    parent: 'manager',
    url: '/households-status',
    views: {
      "content@manager" : {
        component: 'householdListAll'
      }
    }
  })
  .state('reminderLogs', {
    parent: 'manager',
    url: '/logs',
    views: {
      "content@manager" : {
        component: 'reminderLogs'
      }
    }
  })
  .state('manageUsers', {
    parent: 'manager',
    url: '/users',
    abstract: true,
    defaultState: 'currentUsers',
    views: {
      "content@manager": {
        component: 'managerMain'
      }
    }
  })
  .state('currentUsers', {
    parent: 'manageUsers',
    url: '/current',
    views: {
      "manageUsers@manageUsers": {
        component: 'managerUsersCurrent'
      }
    }
  })
  .state('pendingUsers', {
    parent: 'manageUsers',
    url: '/pending',
    views: {
      "manageUsers@manageUsers": {
        component: 'managerUsersPending'
      }
    }
  })
  .state('listHouseholds', {
    parent: 'assignWorkload',
    url: '/list',
    views: {
      "panel@assignWorkload" : {
        component: 'rhsFieldworkerPanel'
      }
    }
  })
  .state('fieldworkerDetail', {
    parent: 'assignWorkload',
    url: '/fieldworker/:fieldworker_id/detail',
    // params: {
    //   fieldworker: 'default'
    // },
    views: {
      "panel@assignWorkload" : {
        component: 'fieldworkerDetail'
      }
    },
    resolve: {
      fieldworker: function($stateParams, FieldworkerModel) {
        return FieldworkerModel.get($stateParams.fieldworker_id).$promise;
      }
    }
  });
}]);
}());
