;(function() {
"use strict";

// This file contains multiple date filters. Use these instead of the date
// filter that ships with angular. This way we can keep the format and timezone
// handling consistent.
//
// This function creates filters for different formats.
// nb: don't use nginject - it doesn't do what we want here.
function makeDateFilter(format) {
  return ['$filter', function($filter) {
    let dateFilter = $filter('date');
    return (dateTime) => dateFilter(dateTime, format);
  }];
}

angular
  .module('tmr-admin')
  .filter('tmrDateTime',    makeDateFilter('dd-MMM-yyyy h:mm a'))
  .filter('tmrDateTimeTZ',  makeDateFilter('dd-MMM-yyyy h:mm a Z'))
  .filter('tmrDate',        makeDateFilter('dd-MMM-yyyy'))
  .filter('tmrDateNumeric', makeDateFilter('dd/MM/yyyy'))
  .filter('tmrDateLong',    makeDateFilter('fullDate'))
  .filter('tmrDayOfWeek',   makeDateFilter('EEEE'))
  .filter('tmrTime',        makeDateFilter('h:mm a'))
  ;

// Special filters for scheduled events and their optional time windows.
// These ones can use ngInject
function scheduledEventDateTime($filter) {
  const dateFilter = $filter('tmrDate');
  const timeFilter = $filter('scheduledEventTime');
  return (scheduledEvent) => {
    if (!scheduledEvent || !scheduledEvent.scheduled_for) {
      return '';
    }
    const date = dateFilter(scheduledEvent.scheduled_for);
    const time = timeFilter(scheduledEvent);
    return `${ date } ${ time }`;
  };
}

function scheduledEventTime($filter) {
  let timeFilter = $filter('tmrTime');
  return (scheduledEvent) => {
    if (!scheduledEvent) {
      return '';
    }
    if (scheduledEvent.time_window) {
      return scheduledEvent.time_window.label;
    }
    return timeFilter(scheduledEvent.scheduled_for);
  };
}

angular
  .module('tmr-admin')
  .filter('scheduledEventDateTime', scheduledEventDateTime)
  .filter('scheduledEventTime', scheduledEventTime);
}());
