;(function() {
"use strict";

const fieldworkerCodeComponent = {
  templateUrl:  'components/fieldworker/code/fieldworker-code.component.html',
  controller: 'FieldworkerCodeController',
  controllerAs: 'vm',
  bindings: {
    fieldworker:  '<',
    noTooltip:    '@',
  }
};

function fieldworkerCodeController(BooleanAttribute) {
  let vm = this;
  vm.$onInit = init;

  function init() {
    angular.extend(vm, {
      hasNoTooltip: hasNoTooltip,
    });
  }

  function hasNoTooltip() {
    return BooleanAttribute(vm.noTooltip);
  }
}

angular
  .module('tmr-admin')
  .component('fieldworkerCode', fieldworkerCodeComponent)
  .controller('FieldworkerCodeController', fieldworkerCodeController)
;
}());
