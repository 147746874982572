;(function() {
"use strict";

const fieldworkersListComponent = {
  templateUrl:  'components/manager/assign-workload/rhs-fieldworker-panel/fieldworkers-list/fieldworkers-list.component.html',
  controller:   'FieldworkersListController',
  controllerAs: 'vm',
  require: {
    parent: '^rhsFieldworkerPanel',
  },
  bindings: {
    fieldworkers: '<',
    selectedWorkType: '<'
  }
};

function fieldworkersListController($log, Alerts, NgTableParams) {
  let vm = this;
  vm.$onChanges = function(changedObj) {
    if (changedObj.fieldworkers) {
      if (changedObj.fieldworkers.currentValue) {
        vm.tableParams = new NgTableParams(
          {
            count: vm.fieldworkers.length,
            sorting: false,
          },
          {
            dataset: vm.fieldworkers,
            counts: [] // hides pager
          }
        );
      }
    }
  };
}

angular
    .module('tmr-admin')
    .component('fieldworkersList', fieldworkersListComponent)
    .controller('FieldworkersListController', fieldworkersListController)
;
}());
