;(function() {
"use strict";

const surveyWeekSelectComponent = {
  templateUrl:  'components/manager/assign-workload/survey-week-select/survey-week-select.component.html',
  controller:   'SurveyWeekSelectController',
  controllerAs: 'vm',
  bindings: {
    weeks: '<',
    selectedWeek: '<',
    setWeek: '&'
  }
};

// ngInject
function surveyWeekSelectController($log, Alerts) {
  let vm = this;
}

angular
    .module('tmr-admin')
    .component('surveyWeekSelect', surveyWeekSelectComponent)
    .controller('SurveyWeekSelectController', surveyWeekSelectController)
;
}());
