;(function() {
"use strict";

angular.module('tmr-admin').factory('HouseholdResource', ($log, $resource, Project) => {
  return (range) => {
    const paramDefaults = {
      project_id: function() { return Project.getProjectID(); }
    };
    return $resource('/api/project/:project_id/households/:household_id', paramDefaults, {
    // $resources does not have an update method registered by default
      'update': { method: 'PUT' },
      'search': { method: 'GET', url:'/api/project/:project_id/households', isArray: true},
      'notes':  { method: 'GET', url:'/api/project/:project_id/households/:household_id/notes', isArray: true},
      'byToken': { method: 'GET', url: '/api/project/:project_id/households/by-token/:token'},
      'allHouseholdEvent': { method: 'GET', url: '/api/project/:project_id/households/:household_id/events', isArray: true},
      'resetToken': {method: 'POST', url: '/api/project/:project_id/households/:household_id/token'},
      'invalidate': {method: 'DELETE', url: '/api/project/:project_id/households/:household_id'},
      'withFilters': { method: 'GET', url:'/api/project/:project_id/households', isArray: false, headers: {"Range": range},
        transformResponse: function(data, headers, status) {
          let response = {};
          response.data = angular.fromJson(data);
          response.headers = headers();
          return response;
        },
      },
    });
  };
});
}());
