;(function() {
"use strict";

const userNameComponent = {
  templateUrl:  'components/user/name/user-name.component.html',
  controller: 'UserNameController',
  controllerAs: 'vm',
  bindings: {
    user: '<',
    noTooltip:   '@',
  }
};

function userNameController() {
  let vm = this;
}

angular
  .module('tmr-admin')
  .component('userName', userNameComponent)
  .controller('UserNameController', userNameController)
;
}());
