;(function() {
"use strict";

angular.module('tmr-admin').factory('ContactModel', ['ContactResource', (ContactResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = ContactResource;

  function remove(householdId, contactId) {
    // Delete is a reserved word
    return resource.delete({ household_id: householdId, contact_id: contactId });
  }

  function get(householdId, contactId) {
    return resource.get({ household_id: householdId, contact_id: contactId });
  }

  function update(householdId, contactId, contact) {
    return resource.update({ household_id: householdId, contact_id: contactId }, contact);
  }

  function getAll(householdId) {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource.query({household_id: householdId});
  }

  function save(householdId, contact) {
    return resource.save({household_id: householdId}, contact);
  }

  return {
    remove:     remove,
    get:        get,
    getAll:     getAll,
    update:     update,
    save:       save
  };
}]);
}());
