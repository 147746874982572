;(function() {
"use strict";

angular.module('tmr-admin').directive('tmrAlerts', (Alerts) => {
  return {
    restrict: 'E',
    templateUrl: 'directives/alerts/display-alerts.directive.html',
    scope: {
      region: '@'
    },
    link: (scope, element, attr) => {
      scope.$watch(() => {
        return Alerts.getAll();
      }, (newVal, oldVal) => {
        scope.alerts = newVal;
      });
      scope.close = function(index) {
        Alerts.remove(index, scope.region);
      };
    }
  };
});
}());
