;(function() {
"use strict";

const contactFormComponent = {
  templateUrl:  'components/household/main/contact-log/contact-form/contact-form.component.html',
  controller:   'ContactFormController',
  controllerAs: 'vm',
  require: {
    parent: '^contactLog'
  },
  bindings: {
    contact: '<',
    mode: '@'
  }
};

function contactFormController($state, $filter, $log, $uibModal, Alerts, Auth, HouseholdModel, MetaModel) {
  let vm = this;
  vm.$onInit = function() {
    vm.auth = Auth;
    MetaModel.contactTypes().then(resp => {
      vm.contactTypes = resp;
    });
    MetaModel.contactOutcomes().then(resp => {
      vm.contactOutcomes = resp;
    });
    MetaModel.contactOutcomeReasons().then(resp => {
      vm.contactOutcomeReasons = resp;
    });
    MetaModel.contactOutcomeReasonMaps().then(resp => {
      vm.contactOutcomeReasonMaps = resp;
    });
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.mode && changedObj.mode.currentValue) {
      vm.mode = changedObj.mode.currentValue;
      if (vm.mode === "new") {
        vm.contact = {
          contact_time: new Date(),
          contact_type_id: 1
        };
        MetaModel.contactTypesOutcomes().then(resp => {
          vm.contactTypesOutcomes = resp;
          vm.filterOutcomes();
        });
      } else {
        MetaModel.contactTypesOutcomes().then(resp => {
          vm.contactTypesOutcomes = resp;
          vm.filterOutcomes();
          MetaModel.contactOutcomeReasonMaps().then(resp => {
            vm.contactOutcomeReasonMaps = resp;
            vm.filterReasons();
          });
        });
      }
    }
  };

  vm.filterOutcomes = function() {
    vm.filteredOutcomes = [];
    vm.contactTypesOutcomes.forEach(item => {
      if (item.id === vm.contact.contact_type_id) {
        vm.filteredOutcomes = item.contact_outcomes;
      }
    });
  };

  vm.filterReasons = function() {
    vm.filteredReasons = [];
    vm.contactOutcomeReasonMaps.forEach(item => {
      if (item.id === vm.contact.contact_outcome_id) {
        vm.filteredReasons = item.contact_outcome_reasons;
      }
    });
    // Catch only one value returned, se it as default selection (mostly for N/A)
    if (vm.filteredReasons.length === 1) {
      $log.log("length 1");
      vm.contact.contact_outcome_reason_id = vm.filteredReasons[0].id;
    }
  };

  vm.setDate = function(date) {
    if (date) {
      vm.contact.contact_time = date;
    }
  };

  vm.save = function() {
    // If the contact outcome is validation we want to show a confirmation
    // dialog before saving.
    if (vm.contact.contact_outcome_id === 1) {
      return invalidateHousehold();
    }
    else {
      return saveOrUpdateContact().then(closeForms);
    }
  }

  vm.cancel = function() {
    $log.info("Cancelling");
    vm.contact = {};
    closeForms();
    vm.parent.enableNavigation({$toggle: true});
  };

  vm.delete = function() {
    let modalInst = $uibModal.open({
      component: 'deleteModal',
      resolve: {
        title: () => {
          return "contact";
        },
        type: () => {
          return vm.contact.contact_type;
        },
        time: () => {
          return vm.contact.contact_time;
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      vm.parent.deleteContact(vm.contact).then(resp => {
        let alertStr = 'Deleted Contact ' + vm.contact.contact_id;
        Alerts.add('warning', alertStr, ['household']);
        vm.parent.showEditForm = false;
      });
    }, function () {
      vm.parent.showEditForm = true;
      // If cancel is hit
    });
  };
  function closeForms() {
    vm.parent.showAddForm = false;
    vm.parent.showEditForm = false;
  }

  function saveOrUpdateContact(refreshAfter = true) {
    // This just does the save and returns the promise.
    if (vm.mode === "new") {
      return vm.parent.saveContact(vm.contact, refreshAfter);
    } else {
      return vm.parent.updateContact(vm.contact, refreshAfter);
    }
  }

  function invalidateHousehold() {
    let household = vm.parent.household;

    let modalInst = $uibModal.open({
      component: 'invalidationModal',
      resolve: {
        address: () => {
          return household.address;
        },
      }
    });

    // There's a bunch of promises chained together here:
    // 1. show the confirmation dialog
    // 2. invalidate the household
    // 3. save the invalidation contact
    // 4. show an alert and go to a new state, because this household is no
    //    longer valid
    return modalInst.result       // [1]
      .then(ok => {               // [2]
          let result = HouseholdModel.invalidate(household.household_id);
          return result.$promise;
      })
      .then(resp => {             // [3]
          let message;
          if (resp.token) {
            let tokenFormatter = $filter('tmrToken');
            let oldToken = tokenFormatter(household.token);
            let newToken = tokenFormatter(resp.token);
            message = `Invalidated Household ${oldToken} replaced with ${newToken}`;
          }
          else {
            message = `No replacement households available in ${household.cluster}`;
          }

          Alerts.add('warning', message, ['listHouseholds', 'validationWork', 'assignEvents']);
          return saveOrUpdateContact(false); // false means no reload afterwards
        },
        failure => {
          if (failure.status == 409) {
            throw 'cancel'; // this is expected, and okay
          }
          else {
            throw failure; // not okay, re-throw
          }
        }
      )
      .then(() => {               // [4]
          if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
            $state.go('listHouseholds');
          }
          else if (vm.auth.current.user.is_fieldworker) {
            $state.go('validationWork', {fieldworker_id: vm.auth.current.user.admin_user_id});
          }
          else {
            $state.go('assignEvents');
          }
      })
      .catch(failure => {
        // This is just here to shut up the warning you get when cancelling the
        // dialog.
        if (! (failure == 'cancel' || failure == 'escape key press')) {
          throw failure;  // some failure other than cancelling the dialog
        }
      });
  }
}

angular
    .module('tmr-admin')
    .component('contactForm', contactFormComponent)
    .controller('ContactFormController', contactFormController)
;
}());
