;(function() {
"use strict";

const tmrIconComponent = {
    templateUrl: 'components/tmr-icon/tmr-icon.component.html',
    controller: 'TmrIconController',
    controllerAs: 'vm',
    bindings: {
        icon: '@',
        meaning: '@',
    },
};

function tmrIconController() {
}

angular
    .module('tmr-admin')
    .component('tmrIcon', tmrIconComponent)
    .controller('TmrIconController', tmrIconController)
    ;
}());
