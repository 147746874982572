;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('FieldworkerResource', FieldworkerResource);

/* @ngInject */
function FieldworkerResource($resource, Project) {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/fieldworkers/:fieldworker_id', paramDefaults, {
    'get': { method: 'GET'},
    'getHouseholds': {method: 'GET', url: '/api/project/:project_id/fieldworkers/:fieldworker_id/households', isArray: true},
    'search': { method: 'GET', url:'/api/project/:project_id/fieldworkers/:fieldworker_id/households', isArray: true},
    'workloads': { method: 'GET', url:'/api/project/:project_id/fieldworkers/:fieldworker_id/clusters', isArray: true},
    'scheduledEvents': { method: 'GET', url:'/api/project/:project_id/fieldworkers/:fieldworker_id/scheduled-events', isArray: true},
    'getAll': { method: 'GET', url:'/api/project/:project_id/fieldworkers', isArray: true},
    'getAllByWeek': { method:'GET', url:'/api/project/:project_id/fieldworkers/work-week/:week_number', isArray:true },
    'getAllByWeekAndWorker': { method:'GET', url:'/api/project/:project_id/fieldworkers/:fieldworker_id/work-week/:week_number', isArray:true },
  });
}
}());
