;(function() {
"use strict";

const noteModalComponent = {
  templateUrl:  'components/fieldworker/work-summary/note-modal/note-modal.component.html',
  controller:   'NoteModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&',
  },
};

function noteModalController($log) {
  var vm = this;

  vm.$onChanges = function(changedObj) {
    $log.log(changedObj);
  };

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('noteModal', noteModalComponent)
    .controller('NoteModalController', noteModalController)
;
}());
