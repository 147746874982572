;(function() {
"use strict";

const scheduledEventFormComponent = {
  templateUrl:  'components/household/event/contact-event-form/scheduled-event-form/scheduled-event-form.component.html',
  controller:   'ScheduledEventFormController',
  controllerAs: 'vm',
  require: {
    // This lets us access function on the parent controller, only available in $onInit (and after)
    parent: '^contactEventForm',
  },
  bindings: {
  }
};

// ngInject
function scheduledEventFormController($scope, $log, $uibModal, $timeout, $anchorScroll, Auth, uibDateParser, ScheduledEventModel, FieldworkerModel, ContactModel, TimeWindows) {
  let vm = this;
  vm.auth = Auth;

  const timeWindows = TimeWindows();

  vm.$onInit = function() {
    vm.useHighlight = true;
    vm.household = vm.parent.parent.household;
    vm.scheduledEventTypes = _.keyBy(vm.parent.parent.meta.scheduledEventTypes, 'id');
    vm.nextLocalId = 1;

    timeWindows.windowsPromise.then(resp => {
      vm.timeWindowList = angular.copy(resp);

      if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
        getFieldworkers(vm.parent.linkedScheduledEvents);
      }

      if (vm.parent.linkedScheduledEvents.length == 0) {
        vm.addNewRow();
      }
      else if (!(vm.auth.current.user.is_owner || vm.auth.current.user.is_manager)) {
        // populate the 'assign yourself' checkbox
        _.each(vm.parent.linkedScheduledEvents, (item) => {
          const flag = item.assigned_to_id == vm.auth.current.user.admin_user_id;
          item.assignedToCurrent = flag;
        });
      }

      if (vm.parent.contact.idToFocus) {
        $timeout(function() {
          $anchorScroll(vm.parent.contact.idToFocus);
        })
        .then(() => {
          return $timeout(function() {
            vm.useHighlight = false;
          }, 1000);
        });
      }
    });
  };

  vm.updateScheduledEvent = function(row, scheduledFor, timeWindowId) {
    row.scheduled_for = scheduledFor;
    row.time_window_id = timeWindowId;
  }

  vm.addNewRow = function() {
    const nextWindow = timeWindows.nextWindow(vm.timeWindowList, vm.parent.contact.contact_time);

    const row = {
      assigned_to_id: null,
      scheduled_for:  nextWindow.dateTime,
      time_window_id: nextWindow.id,
      timestamp:      "local:" + vm.nextLocalId++, // temporary id
    }
    vm.parent.storeTempScheduledEvents(row);
  };

  vm.deleteRow = function(row) {
    vm.parent.deleteTempScheduledEvents(row);
  };

  vm.readonlyTime = true;

  vm.disableDeletedBtn = function(row) {
    if (row.scheduled_event_id) {
      if (!_.isNull(row.resolved_by_contact_id)) {
        // if this scheduled event is resolved, cannot delete it
        return true;
      }

      if (vm.parent.disableAllScheduledItems) {
        return true;
      }
      // only allow to edit the targeted event
      return vm.parent.contact.idToFocus != row.scheduled_event_id;
    }

    return vm.parent.linkedScheduledEvents.length == 1;
  };

  vm.disableInputField = function(row) {
    if (row.scheduled_event_id) {
      if (vm.parent.disableAllScheduledItems) {
        return true;
      }

      // only allow to edit the targeted event
      return vm.parent.contact.idToFocus != row.scheduled_event_id;
    }

    return false;
  }

  // The model date needs to be a js date, use uibDateParser to coerce this.
  vm.setDate = function(row, date) {
    if (date) {
      row.scheduled_for = date;
    }
  };

  const getFieldworkers = function(events) {
    FieldworkerModel.getAll().$promise.then((fieldworkers) => {
      vm.fieldworkers = fieldworkers;

      const fwHash = _.keyBy(vm.fieldworkers, 'admin_user_id');
      _.each(events, (entry) => {
        // If assigned_to is set, there's a chance it may be an inactive fieldworker,
        // in which case, they won't appear in the list retrieved from the backend
        // (which is active only). If that's the case, then re-insert the assigned_to
        // fw into the list so that it displays properly when editing an existing
        // scheduled event.
        if (!_.isNil(entry.assigned_to) && !fwHash[entry.assigned_to.admin_user_id]) {
          vm.fieldworkers.unshift(entry.assigned_to);
        }
      });
    });
  };

  vm.setFieldworkerFromId = function(row) {
    row.assigned_to = _.find(vm.fieldworkers, (item) => {
      return item.admin_user_id == row.assigned_to_id;
    });
  };

  vm.assignToCurrent =  function(row) {
    if (row.assignedToCurrent === true) {
      row.assigned_to_id = vm.auth.current.user.admin_user_id;
      row.assigned_to = vm.auth.current.user;
    } else {
      row.assigned_to_id = null;
      row.assigned_to = null;
    }
  };

  vm.typeSelected = function(row) {
    row.scheduled_event_type = vm.scheduledEventTypes[row.scheduled_event_type_id]['label'];

    if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
      return;
    }

    // non-manager users set interview type = CATI
    // no need automatically tick to 'assign yourself' checkbox
    if (row.scheduled_event_type_id === 2) {
      row.assignedToCurrent = false;
    }
    else {
      row.assignedToCurrent = true;
    }
    vm.assignToCurrent(row);
  };
}

angular
    .module('tmr-admin')
    .component('scheduledEventForm', scheduledEventFormComponent)
    .controller('ScheduledEventFormController', scheduledEventFormController)
;
}());
