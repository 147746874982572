;(function() {
"use strict";

angular.module('tmr-admin').factory('FieldworkerWeekModel', (FieldworkerWeekResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = FieldworkerWeekResource;

  function getWeeksForWorker(fieldworkerId) {
    return resource.getAllWorkWeeksForWorker({ fieldworker_id: fieldworkerId });
  }

  function getWeeksForWorkerAndWorkType(fieldworkerId, work_type) {
    return resource.getAllWorkWeeksForWorkerAndWorkType({ fieldworker_id: fieldworkerId, work_type: work_type });
  }

  return {
    getWeeksForWorker:               getWeeksForWorker,
    getWeeksForWorkerAndType:        getWeeksForWorkerAndWorkType,
  };
});
}());
