;(function() {
"use strict";

const rhsFieldworkerPanelComponent = {
  templateUrl:  'components/manager/assign-workload/rhs-fieldworker-panel/rhs-fieldworker-panel.component.html',
  controller:   'RhsFieldworkerPanelController',
  controllerAs: 'vm',
  require: {
    parent: "^assignWorkload"
  },
  bindings: {
    selectedWorkload: "<",
    selectedWeek: '<'
  }
};

// ngInject
function rhsFieldworkerPanelController($log, Alerts, MetaModel, FieldworkerModel, ClusterModel, Persistence, Project, Auth) {
  let vm = this;

  vm.project_id  = Project.getProjectID();
  vm.auth = Auth; // used to control whether to show 'Manage Users' link

  vm.$onInit = function() {
    MetaModel.workTypes().then(resp => {
      vm.workTypes = resp;
      vm.getSelectedWorkType(vm.workTypes);
    });

    FieldworkerModel.getAll().$promise.then((fieldworkers) => {
      vm.fieldworkers = fieldworkers;
      vm.getSelectedFieldworker(vm.fieldworkers);
    });
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.selectedWorkload && changedObj.selectedWorkload.currentValue) {
      vm.selectedWorkload = angular.copy(changedObj.selectedWorkload.currentValue);
      vm.getHouseholds(vm.selectedWorkload.cluster_id);
    }
    if (changedObj.selectedWeek && changedObj.selectedWeek.currentValue) {
      vm.getFieldworkersWithWorkloads(changedObj.selectedWeek.currentValue.week_number);
    }
  };

  let persistence = Persistence();

  // ------------------- Getters  -----------------------

  vm.getFieldworkers = function() {
    FieldworkerModel.getAll().$promise.then((fieldworkers) => {
      vm.fieldworkers = fieldworkers;
    });
  };

  vm.getFieldworkersWithWorkloads = function(weekId) {
    if (!weekId) {
      weekId = vm.selectedWeek.week_number;
    }
    FieldworkerModel.getAllByWeek(weekId).$promise.then((resp) => {
      vm.fieldworkersWithWorkloads = resp;
    });
  };

  vm.getHouseholds = function(clusterId) {
    ClusterModel.get(clusterId).$promise.then(resp => {
      vm.households = resp;
      vm.parent.setMapData(vm.households);
    });
  };

  vm.updateWorkload = function() {
    vm.parent.updateWorkload(vm.selectedWorkload.cluster_id);
  };

  vm.getSelectedWorkType = function (wTypes) {
    let typeId = parseInt(persistence.getValue('worktype'));
    let type = _.find(wTypes, { id: typeId });

    if (type) {
      vm.setSelectedWorkType(type);
    }
  };

  vm.getSelectedFieldworker = function (fw) {
    let fw_code = persistence.getValue('fieldworker');

    if (fw_code) {
      // this is necessary because we know that code will always be uppercase
      // so even if the persistence returns lowercase code, it should still work
      fw_code = fw_code.toUpperCase();

      let fieldworker = _.find(fw, { code: fw_code });

      if (fieldworker) {
        vm.setSelectedFieldworker(fieldworker);
      }
    }
  };

  // -------------------- Setters ------------------------

  vm.setSelectedWorkType = function(value) {
    vm.selectedWorkType = value;
    persistence.setValue('worktype', value.id);
    vm.parent.toggleWorkType(value);
  };

  vm.setSelectedFieldworker = function(value) {
    vm.selectedFieldworker = value;
    persistence.setValue('fieldworker', value.code);
  };

}

angular
    .module('tmr-admin')
    .component('rhsFieldworkerPanel', rhsFieldworkerPanelComponent)
    .controller('RhsFieldworkerPanelController', rhsFieldworkerPanelController)
;
}());
