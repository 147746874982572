;(function() {
"use strict";

angular.module('tmr-admin', [
  'ngAria',
  'ngAnimate',
  'ngCookies',
  'ngMessages',
  'ngResource',
  'ngSanitize',
  'ngTouch',
  'ngTable',
  'ngMap',
  'ngStorage',
  'tandibar/ng-rollbar',

  'ui.bootstrap',
  'ui.router',
  'ui.bootstrap.datetimepicker',
  'ui.logicly.datetimepicker',
])
.config(['$locationProvider', '$stateProvider', '$urlRouterProvider', ($locationProvider, $stateProvider, $urlRouterProvider) => {
  $stateProvider
    .state('showDiagnostics', {
      url: '/diag',
      component: 'showDiagnostics'
    })
    .state('login', {
      url: '/login',
      component: 'login',
      title: 'Login',
      resolve: {
        auth: function(Auth) {
          // We don't need to be logged in to access the /login page, but when
          // we get there we want to know if there's a logged in user. This
          // does the check, but without the automatic fail that the auth
          // state resolver does.
          return Auth.login();
        },
      },
    })
    .state('auth', {
        // Abstract state representing authentication layer
      abstract: true,
      // If we don't specify a template, ui-router will insert one for us.
      // The default is '<ui-view />'
      // The ui-view directive seems to be how ui-router attaches the
      // view to the dom. If you specify an empty template here, everything
      // disappears. We had css that used the structure created by these
      // templates, but it was fragile and broke when we inserted /project.
      // Instead of relying on the structure, I've added these explicit
      // class names here, and matching rules in _manager_app.scss
      template: '<ui-view class="manager_app__auth" />',
      data: {
        requireAuth: true
      },
      resolve: {
        auth: function(Auth, $state, $timeout) {
          return Auth.login().then(user => {
            if (!Auth.isLoggedIn()) {
              // We get funny errors in the console we just do the $state.go
              // thing directly. Need to bung it in a timeout to get it into
              // the next digest.
              $timeout(function() { $state.go('login') });
            }
            return user;
          });
        },
      }
    })
    .state('projectSelector', {
      name: 'projectSelector',
      parent: 'auth',
      url: '/project',
      component: 'projectSelector',
    })
    // Abstract state used to inject currently-selected project into
    // front-end URLs
    .state('project', {
      name: 'project',
      parent: 'auth',
      abstract: true,
      template: '<ui-view class="manager_app__project" />',
      url: '/project/:project_id',
      onEnter: ($stateParams, $transitions, Project) => {
        // Here we push the selected project id into the Project service.
        //
        // If the project id is valid, the Project service will select the
        // appropriate project and return true. If not, the Project service
        // does nothing and returns false.
        //
        // We pass that value back as the return value to signal whether to
        // proceed with the state transition.
        return Project.setProjectID($stateParams.project_id);
      },
    })
    .state('manager', {
      name: 'manager',
      parent: 'project',
      url: '/manager',
      component: 'manager',
      resolve: {
        weeks: function($stateParams, SurveyWeekModel) {
          return SurveyWeekModel.getAll().$promise;
        }
      }
    })
    .state('fieldworker', {
      parent: 'project',
      url: '/fieldworker/:fieldworker_id?week_number&when&now&later&done',
      component: 'fieldworker',
      redirectTo: 'workSummary',
      reloadOnSearch: false,
      resolve: {
        weeks: function($stateParams, FieldworkerWeekModel) {
          return $stateParams.fieldworker_id ? FieldworkerWeekModel.getWeeksForWorker($stateParams.fieldworker_id).$promise : [];
        },
        fieldworkerId: function($stateParams) {
          return $stateParams.fieldworker_id;
        },
      }
    })
    .state('household', {
      parent: 'project',
      url: '/household/:household_id',
      component: 'household',
      redirectTo: 'householdMain',
      resolve: {
        // $transition$.params() seems to get a better handle on parameters that are not explicitly in the URL as /:value
        // should probably be rolled out everywhere
        household: function($transition$, HouseholdModel) {
          return HouseholdModel.get($transition$.params().household_id).$promise;
        },
      }
    });
    // Each major component has its own states config file;

    // Remove ! hash prefix added in angular 1.6
  $locationProvider.hashPrefix('');
}])
.provider('configService', function() {
  let service = this;
  let configService = {
    // This is the config from rw/config.js which gets created at container
    // start time.
    config: window.strategic_data_config,
  };
  service.$get = function() { return configService; };
})
.config(['RollbarProvider', RollbarProvider => {
  if( tmr.environment !== 'development' || tmr.rollbar_active_for_dev ){
    RollbarProvider.init({
      accessToken:                tmr.rollbar_access_token,
      captureUncaught:            true,
      captureUncaughtExceptions:  true,
      captureUnhandledRejections: true,
      payload: {
        environment:  tmr.environment,
        code_version: tmr.code_version,
        // This also gets decorated with 'person' info in the auth service
      },
    });
  }
  else {
    RollbarProvider.deinit();
  }
}]);

// Really not sure of the best place to put this code
// If require('electron') succeeds than means we're running inside the electron app,
// and we can do whatever renderer-side setup needs going.
try {
  const { webFrame } = require('electron');

  // Running under the electron app. In electron terms, this is the "renderer" process.

  // Enable zooming in the electron app (eg. pinch to zoom)
  webFrame.setVisualZoomLevelLimits(1, 3);
}
catch (err) {
  // Not running under the electron app. This is fine.
};
}());
