;(function() {
"use strict";

const deleteModalComponent = {
  templateUrl:  'components/household/main/contact-log/contact-form/delete-modal/delete-modal.component.html',
  controller:   'DeleteModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&'
  },
};

function deleteModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('deleteModal', deleteModalComponent)
    .controller('DeleteModalController', deleteModalController)
;
}());
