;(function() {
"use strict";

const deleteEntryModalComponent = {
  templateUrl:  'components/household/event/contact-event-form/delete-entry-modal/delete-entry-modal.component.html',
  controller:   'DeleteEntryModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&'
  },
};

function deleteEntryModalController($log) {
  var vm = this;

  vm.$onInit = function () {
    if (vm.resolve.isResolvedContact) {
      vm.optionalMsg = "Deleting this event will unresolve the associated scheduled event."
    }
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('deleteEntryModal', deleteEntryModalComponent)
    .controller('DeleteEntryModalController', deleteEntryModalController)
;
}());
