;(function() {
"use strict";

const managerUsersCurrentComponent = {
  templateUrl:  'components/manager/manage-users/current/list-user.html',
  controller:   'managerUsersCurrentController',
  controllerAs: 'vm',
  bindings: {
  }
};

function managerUsersCurrentController($log,
                                      $q,
                                      $state,
                                      $uibModal,
                                      $cookies,
                                      $window,
                                      NgTableParams,
                                      FieldworkerModel,
                                      TablePersistence,
                                      Alerts,
                                      Project,
                                      Storage,
                                      Auth
                                    ) {
  let vm = this;
  let project_id  = Project.getProjectID();
  let persistence = TablePersistence();
  let storage = Storage();

  vm.logged_in_user_id = Auth.current.user.admin_user_id;

  vm.$onInit = function() {
    let rowCount = $cookies.get("rowCount")
            ? $cookies.get("rowCount")
            : 25;

    const queryParams = persistence.get();
    const hasQueryParams = queryParams.filter && Object.keys(queryParams.filter).length;
    const persistedFilter = storage.getItem('currentFieldworkerList');
    const defaultFilter = {
      page: 1, // show first page
      count: 1 * rowCount, // count per page default
      isFiltersVisible: true,
      sorting: {
        full_name: "asc",
      },
      filter: { },
    };

    let baseParams = hasQueryParams  ? queryParams
                   : persistedFilter ? persistedFilter['baseParams']
                   :                   defaultFilter;
    let encodeSort = persistence.encodeSort;

    // When using a selector with ng-table, if there is no option with a blank
    // id, ng-table will insert a blank one for you. That's the default option.
    // You'll get a selectable blank line in your selector.
    // By specifying a row with an empty id, that one becomes your default.
    const defaultFilterId = '';

    // options for filtering users by manager/fieldworker/telephone
    vm.roleOpts = [
      { id: defaultFilterId, title: 'All' }, // default selection
      { id: 'manager', title: 'Manager' },
      { id: 'fieldworker', title: 'Fieldworker' },
      { id: 'telephone', title: 'Telephone' }
    ];

    // options for filtering users by active/inactive/all
    vm.activeOpts = [
      { id: defaultFilterId, title: 'Active' }, // default selection
      { id: 'inactive', title: 'Inactive' },
      { id: 'all', title: 'All' }
    ];

    // default filter to 'active' users:
    // (this is enforced by the backend anyway, but reflect that in the UI)
    if ( ! baseParams.filter.active ) {
      baseParams.filter.active = defaultFilterId;
    }
    // similarly for the role filter - default it to show 'All users' instead
    // of the blank option
    if ( ! baseParams.filter.role ) {
      baseParams.filter.role = defaultFilterId;
    }

    vm.tableParams = new NgTableParams(baseParams,
      {
        total: 0,
        paginationMaxBlocks: 8,
        paginationMinBlocks: 2,
        getData: function(params) {

          storage.setItem('currentFieldworkerList', { baseParams });

          // Update the url to reflect our updated params
          persistence.set(params);
          let page = params.page();
          let count = params.count();
          if (!$cookies.get("rowCount")) {
            $cookies.put("rowCount",  params.count());
          }

          // Because we're using id='' for the default options, we need to
          // restore this to the actual value for SD::OpenAPI to be happy.
          // Do that here, but take a copy of params.filter(), because that
          // function appears to return a reference to an internal data
          // structure. Modifying it directly screws up the selectors again.
          let filter = angular.copy(params.filter());
          if (filter.role == defaultFilterId) {
            filter.role = 'all';
          }
          if (filter.active == defaultFilterId) {
            filter.active = 'active';
          }

          let range = `${((page * count) - count)}-${(page * count) - 1}`;
          return FieldworkerModel.getAllFiltered(range, encodeSort(params.sorting()), filter).$promise.then((resp) => {
            vm.tableParams.total(setPages(resp.headers["content-range"]));
            return resp.data;
          });
        },
      });

      angular.element(document).ready(function () {
        let options = document.querySelectorAll('option[value=""]');
        if(options.length) {
          Array.from(options).forEach(option => {
            option.parentNode.removeChild(option);
           })
        }
    });
  }

  vm.toggleFieldworkerStatus = function(fieldworker) {
    const isActive = !fieldworker.active;
    FieldworkerModel.updateStatus(fieldworker, isActive).$promise.then(
      response => {
        fieldworker.active = response.active;
      },
      failure => {
        // Failed. Fieldworker doesn't get updated. Error message gets shown.
      },
    );
  }

  let setPages = function(header) {
    if (header === 'items */0') {
      return 0;
    }
    let [, , , max] = header.match(/(\d+)-(\d+)\/(\d+)/);
    return max;
  };
}

angular
    .module('tmr-admin')
    .component('managerUsersCurrent', managerUsersCurrentComponent)
    .controller('managerUsersCurrentController', managerUsersCurrentController);
}());
