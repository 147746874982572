;(function() {
"use strict";

angular.module('tmr-admin').controller('ReplacementList', function ($http, $timeout, $uibModal, ClusterModel, Alerts, Project) {
    let $ctrl = this;

    let project_id = Project.getProjectID();

    $ctrl.open = function( vm, current_cluster ) {
        $ctrl.current_cluster = current_cluster;

        $http.get('/api/project/'+project_id+'/clusters/' + current_cluster.cluster_id + '/nearby/unallocated').then(function(response) {
            $ctrl.replacements = response.data;
            $ctrl.replacements.push({ cluster_id: 0, name: 'Do not add a new cluster'});


            let modalInstance = $uibModal.open({
                animation:    true,
                templateUrl:  'components/manager/assign-workload/replacement-list/replacement-list.component.html',
                controller:   'ReplacementListCtrl',
                controllerAs: '$ctrl',
                resolve:      {
                    current_cluster: function () {
                        return $ctrl.current_cluster;
                    },
                    replacements:    function () {
                        return $ctrl.replacements;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                let [selectedReplacementClusterID] = result;

                // Refresh the cluster list
                ClusterModel.augment(current_cluster.cluster_id, selectedReplacementClusterID).$promise.then(resp => {

                    _.merge( current_cluster, resp[0] );

                    if( resp.length > 1 ){
                        vm.workloads.push( resp[1] );
                        vm.setSelectedWorkload({"$workload": resp[1] });
                    }
                    else {
                        let firstValidCluster =
                            _.find(vm.workloads, 'valid_cluster')
                            || vm.workloads[0];
                        vm.setSelectedWorkload({"$workload": firstValidCluster });
                    }
                    // this will scroll to the newly selected element
                    $timeout(0).then(() => {
                        let element = document.getElementsByClassName('workload-list__entry active');

                        if (element) {
                            element[0].scrollIntoView();
                        }
                    });

                }).catch( function(e){
                    Alerts.add('danger', "An error occurred during this operation. " + e);
                });


            }, angular.noop); // Nothing to do if they cancel the modal

        }).catch( angular.noop ); // System works fine if we don't get any replacement clusters back, so we can ignore the catch
    }
});

angular.module('tmr-admin').controller('ReplacementListCtrl', function ($uibModalInstance, current_cluster, replacements) {
    let $ctrl = this;
    $ctrl.stage = 1;
    $ctrl.current_cluster = current_cluster;
    $ctrl.replacements    = replacements;
    
    $ctrl.selected_replacement_cluster = $ctrl.replacements[0]['cluster_id'];

    $ctrl.ok = function() {
        if($ctrl.stage === 2) {
            $uibModalInstance.close([
                $ctrl.selected_replacement_cluster,
            ]);
        } else {
            $ctrl.stage = 2;
        }

    };

    $ctrl.cancel = function () {
        if($ctrl.stage === 1 ) {
            $uibModalInstance.dismiss('cancel');
        } else {
            $ctrl.stage = 1;
        }

    };

    $ctrl.getName = function () {
        const current_cluster = $ctrl.replacements.filter(replacement => replacement.cluster_id === $ctrl.selected_replacement_cluster);
        return current_cluster.length && current_cluster[0]['name'];
    }
});
}());
