;(function() {
"use strict";

angular.module('tmr-admin').factory('ScheduledEventResource', ($resource, Project) => {
  return (range) => {
    const paramDefaults = {
      project_id: function() { return Project.getProjectID(); }
    };
    // This is closured to inject the headers, which resources wont allow you to set on calling an action :(
    return $resource('/api/project/:project_id/scheduled-events/:scheduled_event_id', paramDefaults, {
    // $resources does not have an update method registered by default
      'update': { method: 'PUT' },
      'query': { method: 'GET', url:'/api/project/:project_id/scheduled-events', isArray: false, headers: {"Range": range},
        transformResponse: function(data, headers) {
          let response = {};
          response.data = angular.fromJson(data);
          response.headers = headers();
          return response;
        } }
    });
  };
});
}());
