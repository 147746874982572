;(function() {
"use strict";

const fieldworkerSelectComponent = {
  templateUrl:  'components/manager/assign-workload/rhs-fieldworker-panel/fieldworker-select/fieldworker-select.component.html',
  controller:   'FieldworkerSelectController',
  controllerAs: 'vm',
  bindings: {
    fieldworkers: '<',
    selectedFieldworker: '<',
    setSelectedFieldworker: '&'
  }
};

// ngInject
function fieldworkerSelectController($log, Alerts) {
  let vm = this;

  vm.$onChanges = function(changedObj) {

    if (angular.isDefined(changedObj.selectedFieldworker) && angular.isDefined(changedObj.selectedFieldworker.currentValue)) {
      vm.selectedFieldworker = changedObj.selectedFieldworker.currentValue;
      return;
    }

    if (angular.isDefined(changedObj.fieldworkers) && angular.isDefined(changedObj.fieldworkers.currentValue)) {
      vm.fieldworkers = angular.copy(changedObj.fieldworkers.currentValue);
      let newDefault = {
        id: "",
        full_name: "Select Fieldworker",
        isDefault: true
      };
      vm.fieldworkers.push(newDefault);
      if (angular.isUndefined(changedObj.selectedFieldworker)) {
        vm.selectedFieldworker = newDefault;
      }
    }

  };
}

angular
    .module('tmr-admin')
    .component('fieldworkerSelect', fieldworkerSelectComponent)
    .controller('FieldworkerSelectController', fieldworkerSelectController)
;
}());
