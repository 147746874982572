;(function() {
"use strict";

angular.module('tmr-admin').config(($provide, $httpProvider) => {
  $provide.factory('httpErrorInterceptor', ($q, $log, Alerts, $injector) => {
    function requestError(rejection) {
      return $q.reject(rejection);
    }

    function responseError(rejection) {
      let Auth = $injector.get('Auth');
      let errorMessage = 'unknown error';
      if (rejection.status === 403 && /token expired or invalid/g.test(rejection.data.error)) {
        Alerts.add('danger', 'Login expired, please login again');
        Auth.logout();
      } else if (rejection.status === 403 && /sufficient attributes/g.test(rejection.data.error)) {
        // The user is trying to access the app without having the right.
        Alerts.add('danger', 'You don\'t have access to this application');
        Auth.logout();
        Auth.isDenied = true;
      } else {
        if (rejection.data && rejection.data.displayMessage) {
          errorMessage = rejection.data.displayMessage;
          Alerts.add('danger', errorMessage);
        } else if (rejection.data && rejection.data.errors) {
          for (const item in rejection.data.errors) {
            errorMessage = showAlert(rejection.status, rejection.statusText, item, rejection.data.errors[item]);
          }
        } else if (rejection.data) {
          errorMessage = showAlert(rejection.status, rejection.statusText, rejection.data.exception, rejection.data.message );
        }
      }
      return $q.reject(Object.assign(rejection, { errorMessage } ));
    }

    function showAlert(status, statusText, message1 = null, message2 = null) {
      let text = status + ' ' + statusText;
      if (!isEmpty(message1)) {
        text = text + ': ' + message1;
      }
      if (!isEmpty(message2)) {
        text = text + ': ' + message2;
      }
      Alerts.add('danger', text);
      return message2 || text;
    }

    function isEmpty(input) {
      if (input === null || input === undefined) { 
        return true;
      }

      input = input + ''; //this will make sure that anything that user passes in is converted to string
      input = input.trim()

      if (input.length > 0) {
        return false;
      }

      return true;
    }

    return {
      requestError:   requestError,
      responseError:  responseError
    };
  });
  $httpProvider.interceptors.push('httpErrorInterceptor');
});
}());
