;(function() {
"use strict";

const contactEventFormComponent = {
  templateUrl:  'components/household/event/contact-event-form/contact-event-form.component.html',
  controller:   'ContactEventFormController',
  controllerAs: 'vm',
  require: {
    parent: '^householdEvent'
  },
  bindings: {
    household: '<',
    contact: '<',
    mode: '@',
    enableNavigation: '&',
    parentEvent: '<',// set when we want to resolve a scheduled event, use to ensure resolved events have valid date
  }
};

function contactEventFormController($q, $state, $filter, $log, $uibModal, Alerts, Auth, HouseholdModel, ScheduledEventModel) {
  let vm = this;

  vm.$onInit = function() {
    vm.auth = Auth;
    vm.showScheduledEventForm = false;
    vm.disableDropdown = false;
    vm.disableAllScheduledItems = false;
    vm.displayContactAsLabel = false;
    vm.deleteScheduledIds = [ ];
    vm.contactTypesOutcomes = _.keyBy(vm.parent.meta.contactTypesOutcomes, 'id');
    vm.contactOutcomeReasonMaps = _.keyBy(vm.parent.meta.contactOutcomeReasonMaps, 'id');
    vm.linkedScheduledEvents = [ ];

    // Find the "Recruitment" contact types (there's only one), and
    // then pull out the outcomes, and find those that are "finalised".
    // Then make an { id => true } hashmap of the contact outcome ids.
    // We use those to check if an outcome finalises the recruitment.
    vm.isFinalisedOutcome = _.chain(vm.contactTypesOutcomes)
                             .filter(cto => cto.label == 'Recruitment')
                             .flatMap('contact_outcomes')
                             .filter(co => co.type_final && !co.final)
                             .map(co => [ co.id, true ])
                             .fromPairs()
                             .value();

    vm.enableNavigation({$toggle: false});

    if (vm.mode === "new") {
      vm.contact = {
        contact_time: new Date(),
        contact_type_id: 1
      };
      vm.filterOutcomes();
    } else {
      // mode === 'edit'
      vm.filterOutcomes();
      vm.filterReasons();

      if (vm.contact.children) {
        // spot a contact with outcome = 'Scheduled'
        vm.showScheduledEventForm = true;
        // don't allow to edit the outcome if the current one = 'Scheduled'
        vm.disableDropdown = true;

        if (vm.contact.idToFocus) {
          // we are editing a scheduled event
          // disable the parent contact form
          vm.displayContactAsLabel = true;
        }
        else {
          // we are editing the contact
          // disable all scheduled event items
          vm.disableAllScheduledItems = true;
        }
        for (let item of vm.contact.children) {
          item.timestamp = item.scheduled_event_id;
          // events will be sorted by scheduled time in desceding order
          // based on the result retrieved from backend
          vm.linkedScheduledEvents.push(item);
        }
      }
    }
  };

  function parentRecruitmentContacts() {
    return _.chain(vm.parent.contacts)
            .filter(contact => contact.event_type == "contact")
            .map(contact => contact.contact)
            .filter(contact => contact.contact_type == "Recruitment")
            .value();
  }

  function isRecruitment(contact) {
    return vm.contactTypesOutcomes[contact.contact_type_id].label == "Recruitment";
  }

  function isFinalisedRecruitment(contact) {
    return contact.contact_outcome_id
        && vm.isFinalisedOutcome[contact.contact_outcome_id];
  }

  vm.recruitmentWarning = function() {
    if (!isRecruitment(vm.contact)) {
      return;
    }

    // First check if there's an existing finalised recruitment contact before
    // this one.
    const recruitments = parentRecruitmentContacts();
    const previousFinalisedRecruitments = _.filter(recruitments,
      contact => (new Date(contact.contact_time) < vm.contact.contact_time)
              && isFinalisedRecruitment(contact)
    );
    if (previousFinalisedRecruitments.length > 0) {
      // Oldest one is last
      return `Recruitment has already been finalised`;
    }

    // Now check to see if this is a finalised recruitment contact that invalidates
    // any later recruitment contacts.
    if (!isFinalisedRecruitment(vm.contact)) {
      return;
    }

    const subsequentRecruitments = _.filter(recruitments,
      contact => new Date(contact.contact_time) > vm.contact.contact_time
    );
    if (subsequentRecruitments.length > 0) {
      return `This recruitment contact conflicts with later recruitment contacts`;
    }

    return;
  };

  vm.loadTempScheduledEvents = function(item) {
    return vm.linkedScheduledEvents;
  }

  vm.storeTempScheduledEvents = function(item) {
    // new events created from UI but not yet stored in backend
    // always stay at the bottom (above the 'Add' button)
    vm.linkedScheduledEvents.push(item);
  };

  vm.deleteTempScheduledEvents = function(row) {
    vm.linkedScheduledEvents = _.reject(vm.linkedScheduledEvents, { timestamp: row.timestamp });

    if (row.scheduled_event_id) {
      vm.deleteScheduledIds.push(row.scheduled_event_id);
    }
  }

  vm.filterOutcomes = function() {
    let contactType = vm.contactTypesOutcomes[vm.contact.contact_type_id];
    vm.filteredOutcomes = contactType ? contactType.contact_outcomes : [ ];
  };

  vm.filterReasons = function() {
    // grab create_scheduled_events flag
    vm.filteredOutcomes.forEach(item => {
      if (vm.contact.contact_outcome_id === item.id) {
        vm.contact.creates_scheduled_events = item.creates_scheduled_events;
      }
    });

    let reasonMap = vm.contactOutcomeReasonMaps[vm.contact.contact_outcome_id];
    vm.filteredReasons = reasonMap ? reasonMap.contact_outcome_reasons : [ ];

    // Catch only one value returned, se it as default selection (mostly for N/A)
    if (vm.filteredReasons.length === 1) {
      vm.contact.contact_outcome_reason_id = vm.filteredReasons[0].id;
    }

    if (vm.contact.creates_scheduled_events) { // Outcome = 'Scheduled', show scheduled-event-form
      vm.showScheduledEventForm = true;
    }
    else {
      vm.showScheduledEventForm = false;
    }
  };

  vm.setDate = function(date) {
    if (date) {
      vm.contact.contact_time = date;
    }
  };

  vm.manageSave = function() {
    if (vm.parentEvent) {
      // save for resolving event
      if (new Date(vm.contact.contact_time) <= new Date(vm.parentEvent.scheduled_for)) {
        let formatter = $filter('tmrDateTime');
        Alerts.add('danger', `The resolved event time must be later than ${formatter(vm.parentEvent.scheduled_for)}`);
      }
      else {
        vm.save();
      }
    }
    else {
      // save in 'edit' or 'new' mode
      vm.save();
    }
  };

  vm.save = function() {
    Alerts.removeAll();
    // If the contact outcome is validation we want to show a confirmation
    // dialog before saving.
    // contact_outcome_id = 1 means Outcome = Invalidated
    if (vm.contact.contact_outcome_id === 1) {
      return invalidateHousehold();
    }
    else {
      //console.log(vm.contact);
      if (!vm.contact.creates_scheduled_events) {
        return saveOrUpdateContact().then(() => {
          closeForms();
          deleteAllTempEvents();
        });
      }
      else if (validateEvents()) {
        // contact.creates_scheduled_events is true
        // make sure all scheduled events row are valid
        return manageScheduledEvents()
          .then(values => saveOrUpdateContact())
          .then(ok => saveAllTempEvents())
          .then(values => vm.parent.getAllEvents(vm.parent.household.household_id))
          .then(closeForms)
          .catch(error => {
            //console.log(error);
            Alerts.removeAll();
            Alerts.add('danger', error.data.errors);
          });
      }
    }
  };

  vm.saveWithModal = function() {
    let modalInst = $uibModal.open({
      component: 'resolveEarlyEntryModal',
      resolve: {
        time: () => {
          return vm.parentEvent.scheduled_for;
        },
      }
    });

    return modalInst.result.then(function () {
      // If ok is hit
      return vm.save();
    }, function () {
      // If cancel is hit
    });
  };

  vm.cancel = function() {
    Alerts.removeAll();
    $log.info("Cancelling");
    vm.contact = {};
    closeForms();
    vm.enableNavigation({$toggle: true});
    deleteAllTempEvents();
  };

  function validateEvents() {
    for (const item of vm.linkedScheduledEvents) {
      if (new Date(vm.contact.contact_time) >= new Date(item.scheduled_for)) {
        Alerts.add('danger', 'All scheduled events must be after the parent contact event time');
        return false;
      }
      if (item.resolved_by_contact && new Date(item.scheduled_for) >= new Date(item.resolved_by_contact.contact_time)) {
        let formatter = $filter('tmrDateTime');
        Alerts.add('danger', `The scheduled time must be earlier than the resolved time ${formatter(item.resolved_by_contact.contact_time)}`);
        return false;
      }
    }
    return true;
  }

  function saveAllTempEvents() {
    const contact_id   = vm.parent.contact_id;
    const household_id = vm.parent.household.household_id;
    //TODO: should have an endpoint to create scheduled events in bulk
    // for now, we do one by one
    let promises = [];

    _.each(vm.linkedScheduledEvents, (scheduledEvent) => {
      scheduledEvent.created_by_contact_id = contact_id;
      if (scheduledEvent.scheduled_event_id) {
        // handle event with scheduled_event_id in a separated function
      }
      else {
        promises.push( ScheduledEventModel.save(household_id, scheduledEvent).$promise );
      }
    });
    return $q.all(promises);
  }

  function deleteAllTempEvents() { // delete everything users've added if outcome != 'scheduled'
    vm.linkedScheduledEvents.length = 0;
  }

  function manageScheduledEvents() {
    // bulk delete & update scheduled events that have scheduled_event_id
    const household_id = vm.parent.household.household_id;
    let promises = [];

    if (vm.disableAllScheduledItems) {
      // all scheduled items are unable to edit, no need to update them
      return $q.all(promises);
    }

    _.each(vm.deleteScheduledIds, (id) => {
      promises.push( ScheduledEventModel.remove(household_id, id).$promise );
    });

    _.each(vm.linkedScheduledEvents, (scheduledEvent) => {
      if (scheduledEvent.scheduled_event_id && scheduledEvent.scheduled_event_id == vm.contact.idToFocus) {
        // only take care of the scheduled event that we are targeting
        let body = scheduledEvent;
        promises.push( ScheduledEventModel.update(household_id, scheduledEvent.scheduled_event_id, body).$promise );
      }
    });
    return $q.all(promises);
  }

  function closeForms() {
    vm.parent.showAddForm = false;
    vm.parent.showEditForm = false;
    vm.parent.showResolveForm = false;
  }

  function saveOrUpdateContact() {
    // This just does the save and returns the promise.
    if (vm.mode === "new") {
      return vm.parent.saveContact(vm.contact);
    } else {
      return vm.parent.updateContact(vm.contact);
    }
  }

  function invalidateHousehold() {
    let household = vm.parent.household;
    let is_cancel = 0;

    let modalInst = $uibModal.open({
      component: 'invalidationEntryModal',
      resolve: {
        address: () => {
          return household.address;
        },
      }
    });

    // There's a bunch of promises chained together here:
    // 1. show the confirmation dialog
    // 2. save the invalidation contact
    // 3. invalidate the household
    // 4. show an alert and go to a new state, because this household is no
    //    longer valid
    return modalInst.result       // [1]
      .then(saveOrUpdateContact)  // [2]
      .then(ok => {               // [3]
          let result = HouseholdModel.invalidate(household.household_id);
          return result.$promise;
      })
      .then(resp => {             // [4]
          let message;
          if (resp.token) {
            let tokenFormatter = $filter('tmrToken');
            let oldToken = tokenFormatter(household.token);
            let newToken = tokenFormatter(resp.token);
            message = `Invalidated Household ${oldToken} replaced with ${newToken}`;
          }
          else {
            message = `No replacement households available in ${household.cluster}`;
          }

          Alerts.add('warning', message, ['listHouseholds', 'validationWork', 'assignEvents']);
          if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
            $state.go('listHouseholds');
          }
          else if (vm.auth.current.user.is_fieldworker) {
            $state.go('validationWork', {fieldworker_id: vm.auth.current.user.admin_user_id});
          }
          else {
            $state.go('assignEvents');
          }
      })
      .catch(failure => {
        // This is just here to shut up the warning you get when cancelling the
        // dialog.
        if (failure == 'cancel' || failure == 'escape key press') {
          is_cancel = 1; // if hitting cancel, we wouldn't need to delete all scheduled entries
        }
        else {
          throw failure;  // some failure other than cancelling the dialog
        }
      })
      .finally(() => {
        if (!is_cancel) {
          deleteAllTempEvents();
        }
      });
  };

}

angular
    .module('tmr-admin')
    .component('contactEventForm', contactEventFormComponent)
    .controller('ContactEventFormController', contactEventFormController)
;
}());
