;(function() {
"use strict";

angular.module('tmr-admin')
.config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
  $stateProvider
  .state('validationWork', {
    parent: 'fieldworker',
    url: '/validation',
    views: {
      "content@fieldworker" : {
        component: 'fieldworkerTodo'
      }
    },
    resolve: {
      workloads: function($stateParams, FieldworkerModel) {
        return FieldworkerModel.workloads($stateParams.fieldworker_id).$promise;
      },
      mode: () => { return 'validation'; },
    }
  })
  .state('recruitmentWork', {
    parent: 'fieldworker',
    url: '/recruitment',
    views: {
      "content@fieldworker" : {
        component: 'fieldworkerTodo'
      }
    },
    resolve: {
      workloads: function($stateParams, FieldworkerModel) {
        return FieldworkerModel.workloads($stateParams.fieldworker_id).$promise;
      },
      mode: () => { return 'recruitment'; },
    }
  })
  .state('scheduledEventWork', {
    parent: 'fieldworker',
    url: '/events',
    views: {
      "content@fieldworker" : {
        component: 'scheduledEventWork'
      }
    },
    resolve: {
    }
  })
  .state('workSummary', {
    parent: 'fieldworker',
    url: '/summary',
    views: {
      "content@fieldworker" : {
        component: 'workSummary'
      },
      "more-filters@fieldworker" : {
        component: 'workSummaryRelevance'
      }
    },
    resolve: {
    }
  });
}]);
}());
