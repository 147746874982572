;(function() {
"use strict";

angular.module('tmr-admin')
.config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
  $stateProvider
  .state('householdMain', {
    parent: 'household',
    url: '/main',
    views: {
      "content@household" : {
        component: 'householdMain'
      }
    },
    params: {
      activeTab: 0
    },
    resolve: {
      activeTab: function($transition$) {
        return $transition$.params().activeTab;
      },
      previousUrl: function($state) {
        return $state.href($state.current.name, $state.params);
      },
    }
  })
  .state('householdEvent', {
    parent: 'household',
    url: '/event',
    params: {
        rowToFocus: null
    },
    views: {
      "content@household" : {
        component: 'householdEvent'
      }
    },
    resolve: {
      scheduledEvents: function($log, $transition$, ScheduledEventModel) {
        return ScheduledEventModel.getAllForHousehold($transition$.params().household_id).$promise;
      },
      meta: function($q, MetaModel) {
        let promises = {
          contactTypes: MetaModel.contactTypes(),
          contactTypesOutcomes: MetaModel.contactTypesOutcomes(),
          contactOutcomeReasonMaps: MetaModel.contactOutcomeReasonMaps(),
          scheduledEventTypes: MetaModel.scheduledEventTypes(),
        };
        return $q.all(promises);
      },
    }
  })
  .state('householdNotes', {
    parent: 'household',
    url: '/notes',
    views: {
      "content@household" : {
        component: 'householdNotes'
      }
    },
    resolve: {
      notes: function($log, $transition$, HouseholdModel) {
        return HouseholdModel.notes($transition$.params().household_id).$promise;
      },
      currentNote: function($log, $transition$, HouseholdModel) {
        return HouseholdModel.get($transition$.params().household_id).$promise;
      }
    }
  })
  .state('householdMap', {
    parent: 'household',
    url: '/map',
    views: {
      "content@household" : {
        component: 'householdMap'
      }
    },
    resolve: {
    }
  });
}]);
}());
