;(function() {
"use strict";

const fieldworkerComponent = {
  templateUrl:  'components/fieldworker/fieldworker.component.html',
  controller:   'FieldworkerController',
  controllerAs: 'vm',
  bindings: {
    weeks:         '<weeks',
    workloads:     '<workloads',
    fieldworkerId: '<fieldworkerId',
  }
};

function fieldworkerController($log, $state, $window, Auth, FieldworkerModel, Persistence, Project) {
  let vm = this;
  vm.auth = Auth;
  vm.showMap = true;

  vm.$onInit = activate;
  vm.$onChanges = change;

  let persistence = Persistence();
  let storage = persistence.storage;
  let isAdmin = vm.auth.current.roles.Owner || vm.auth.current.roles.Manager;

  function activate() {
    angular.extend(vm, {
      setMapData:             setMapData,
      activeTab:              'household',
      setSelectedWorkload:    setSelectedWorkload,
      setSelectedWeek:        setSelectedWeek,
      capiTabSelected:        capiTabSelected,
      back:                   back,
      home:                   home,
      relevance:              getRelevance(),
      setRelevance:           setRelevance
    });
  }

  function getRelevance() {
      return storage.getValue('relevanceSelect') || ( isAdmin ? 'all' : 'todo');
  }

  function setRelevance(option) {
    vm.relevance = option;
    storage.setValue('relevanceSelect', option);
  }

  function back() {
    $window.history.back();
  }

  function capiTabSelected() {
    return $state.current.name === 'scheduledEventWork';
  }

  function home() {
    const { user } = vm.auth.current;
    $state.go(user.homeState.state, user.homeState.params);
    if(user.is_fieldworker) {
      setSelectedWeek(getCurrentWeek());
    }
  }

  function getCurrentWeek() {
    let now = new Date();
    let currentWeek = _.find(vm.weeks, (week, index) => {
      let weekEnd = new Date(week.work_week_end_date + "T" + "18:00");
      return (now.getTime() <= weekEnd.getTime());
    });

    return currentWeek;
  }

  function change(changedObj) {
    if (changedObj.weeks) {
      vm.weeks = changedObj.weeks.currentValue;
      if (!vm.selectedWeek) {
        setSelectedWeek(getDefaultWeek());
        // vm.getHouseholds(vm.selectedWeek.survey_week_id);
      }
    }
    if (changedObj.workloads.currentValue && changedObj.workloads.isFirstChange()) {
      changedObj.workloads.currentValue.$promise.then(resp => {
        setSelectedWorkload(_.first(resp));
      });
    }
    if (changedObj.fieldworkerId && changedObj.fieldworkerId.currentValue) {
      FieldworkerModel.get(changedObj.fieldworkerId.currentValue).$promise.then(resp => {
        vm.fieldworker = resp;
      });
    }
  }

  function setSelectedWorkload(workload) {
    // Below is to give you some flexibility when setting the map data, can be array with length 1 or workload object
    vm.selectedWorkload = workload;
    if (angular.isArray(workload)) {
      setMapData(workload);
    } else {
      // setMapData(vm.householdByClusterId[workload.cluster_id]);
    }
  }

  function setSelectedWeek(week) {
    vm.selectedWeek = week;
    if (week) {
      persistence.setValue('week_number', week.week_number);
    } else {
      persistence.setValue('week_number', undefined);
    }
  }

  function getDefaultWeek() {
    // Use parseInt here because _.find seems to do a strict match.
    // Just searching for saved.week doesn't work.
    let weekId = parseInt(persistence.getValue('week_number'));
    let savedWeek = _.find(vm.weeks, { week_number: weekId });
    if (savedWeek) {
      return savedWeek;
    }

    // If a week was requested but not found then leave week unspecified/unselected
    // If *no* week was requested, fall back to whatever getCurrentWeek gives:
    return weekId ? null : getCurrentWeek();
  }

  function setMapData(households) {
    if (angular.isArray(households)) {
      vm.mapData = households;
    } else {
      let arr = [];
      arr.push(households);
      vm.mapData = arr;
    }
  }
}

angular
    .module('tmr-admin')
    .component('fieldworker', fieldworkerComponent)
    .controller('FieldworkerController', fieldworkerController)
;
}());
