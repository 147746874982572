;(function() {
"use strict";

angular.module('tmr-admin').config(function($provide, $httpProvider) {
  $provide.factory('httpInterceptor', ($q, $injector, $log, configService) => {

    let streuthApiUrl;

    function isStreuthApiCall(url) {
      let streuthApiUrl = $injector.get('configService').config.streuthApiUrl;
      return _.startsWith(url, streuthApiUrl);
    }

    // This is where we store the streuth login token that we get from
    // logged-in responses. We inject it into streuth api calls. We don't need
    // to set it as a cookie, or any persistent type of storage. If we reload
    // the app afresh while still logged in, the first thing we do it fetch the
    // user, which will give us the token again. A plain old variable is just
    // fine here.
    let streuthApiToken;

    return {
      request: (config) => {
        if (streuthApiToken && isStreuthApiCall(config.url)) {
          // For the app backend, the login token gets handled automatically
          // via cookies (note: not the one set in auth.setToken), but that
          // doesn't work for the Streuth API. For that case, we set the
          // bearer token.
          config.headers.Authorization = `Bearer ${streuthApiToken}`;
        }

        // Remove any empty-string query parameters
        if (config.params) {
          _.forIn(config.params, (value, key) => {
            if (value === "") {
              delete config.params[key];
            }
          });
        }
        return config;
      },

      response: (response) => {
        // Check to see if the backend has set the token in a header. If there
        // is one, stash it, because it may have been rotated.
        let token = response.headers('X-Local-Auth-Token');
        if (token) {
          streuthApiToken = token;
        }
        return response;
      },
    };
  });

  $httpProvider.interceptors.push('httpInterceptor');
});
}());
