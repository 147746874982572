;(function() {
"use strict";

const workSummaryRelevanceComponent = {
  templateUrl:  'components/fieldworker/work-summary/work-summary-relevance.component.html',
  controller:   'WorkSummaryRelevanceController',
  controllerAs: 'vm',
  require: {
    parent: '^fieldworker'
  },
};

// ngInject
function workSummaryRelevanceController($log) {
  // let vm = this;
}

angular
  .module('tmr-admin')
  .component('workSummaryRelevance', workSummaryRelevanceComponent)
  .controller('WorkSummaryRelevanceController', workSummaryRelevanceController);
}());
