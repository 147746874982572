;(function() {
"use strict";

const tableFilterComponent = {
  templateUrl: "components/common/templates/table-filter.html",
  controller: "TableFilterController",
  controllerAs: "vm",
  bindings: {
    applyFilter: "&",
    clearTableParamsFilter: "&",
    isTableParamsFilterEmpty: "&",
    persistedFilter: "<",
    filterWeeks: "<",
  },
};

function filterController(Auth, Project, $scope) {
  let vm = this;

  // assign keys to model so that the parent component knows how to parse the model before assigning to query parameters
  vm.model = {
    weeks: {}
  };

  // track state of tabs
  vm.activeTab = 'weeks';
  vm.results = {};
  vm.isFormCollapsed = true;

  function getEndOfDay(date){
    // returns date with time modified to 23:59:59 of the given date
    return new Date(`${date}T23:59:59`);
  }

  function getTodayDate() {
     // returns today's date in Austrlia/Queensland timezone
    const currentDate = new Date();
    currentDate.toLocaleDateString({ timezone: 'Australia/Queensland'});
    return currentDate;
  }

  vm.handleApplyFilter = function () {
    makeFilterBtnPills();
    vm.applyFilter({ model: vm.model});
  };

  vm.handleClearFilterClick = function () {
    //remove all pill filters
    _.forEach(_.keys(vm.results), (entry) => {
      vm.removeFilter(entry);
    });
  };

  vm.handleAllWeeksFilterClick = function () {
    vm.model.weeks = {
      start_week:  vm.filterWeeks[0].survey_week_id,
      end_week: vm.filterWeeks[vm.filterWeeks.length - 1].survey_week_id
    };
  };

  vm.handlePresentToFutureClick = function () {
    const currentDate = getTodayDate();
    const start = vm.filterWeeks.find(week => getEndOfDay(week.end_date) >= currentDate);

    const end = vm.filterWeeks[vm.filterWeeks.length - 1];

    vm.model.weeks = {
      end_week: end.survey_week_id,
      start_week: start.survey_week_id,
    };
  };
  

  function applyValues(persistedFilter){
    const { weeks } = angular.copy(persistedFilter);
    if(weeks && Object.keys(weeks).length){
        vm.model.weeks = weeks
        makeFilterBtnPills()
    }
  }

  function init(weeks) {
    const { start_week, end_week } = vm.model.weeks;
    if(start_week && end_week){
      makeFilterBtnPills();
    } else {
      vm.startWeeks = angular.copy(weeks);
      vm.endWeeks = angular.copy(weeks);
    }
  }

  $scope.$watch(() => vm.persistedFilter, (newValue, oldValue) => {
    if(newValue && newValue.weeks){
      applyValues(newValue);
    }
  }, true);

  $scope.$watch(() => vm.model.weeks.start_week, (newVal, oldVal) => {
    if (!_.isUndefined(newVal)) {
      // whenever start_week is changed, update available options for end_date
      vm.adjustEndWeeksOptions();
      if (vm.model.weeks.end_week) { // only apply filter when both selectors are non-empty
        vm.handleApplyFilter();
      }
    }
    else {
      // if no new val, give it all options
      vm.startWeeks = angular.copy(vm.filterWeeks);
    }
  });

  $scope.$watch(() => vm.model.weeks.end_week, (newVal, oldVal) => {
    // whenever end_week is changed, update available options for start_date
    if (!_.isUndefined(newVal)) {
      vm.adjustStartWeeksOptions();
      if (vm.model.weeks.start_week) { // only apply filter when both selectors are non-empty
        vm.handleApplyFilter();
      }
    }
    else {
      // if no new val, give it all options
      vm.endWeeks = angular.copy(vm.filterWeeks);
    }
  });

  vm.$onChanges = function(changeObj){
    if(changeObj.filterWeeks){
      _.forEach(changeObj.filterWeeks.currentValue, (entry) => {
        entry.label = `${entry.survey_week_id}: ${entry.start_date} - ${entry.end_date}`;
      });
      init(changeObj.filterWeeks.currentValue);
    }
  }

  vm.displayPresentToFutureBtn = function() {
    // find if today's date falls into any ranges in vm.filterWeeks
    if (vm.filterWeeks && vm.filterWeeks.length) {
      const currentDate = getTodayDate();
      const matches = vm.filterWeeks.find(entry => getEndOfDay(entry.end_date) >= currentDate);
      if (matches) {
        return true;
      }
    }

    return false;
  };

  vm.removeFilter = function(key) {
      // remove the chosen pill filter
      delete vm.results[key];
      vm.model[key] = {};
      vm.applyFilter({ model: vm.model });
  }

  vm.showClearAllFilterBtn = function() {
     if (vm.isTableParamsFilterEmpty() && _.isEmpty(vm.results)) {
        return false;
     }
     return true;
  }

  vm.clearAllFilters = function() {
    //remove all pill filters
    _.forEach(_.keys(vm.results), (entry) => {
      vm.removeFilter(entry);
    });
    // clear NgTableParams
    vm.clearTableParamsFilter();
  }

  function makeFilterBtnPills () {
    // make the filter pill at the top
    const { weeks } = vm.model;
    if(vm.filterWeeks && !angular.equals({}, weeks)) {
      const { start_week, end_week } = weeks;
        const selectedWeeks = vm.filterWeeks.filter(week => week.survey_week_id === start_week || week.survey_week_id === end_week);
        // if startWeek and endWeek are same, use same week for start and end week value
        const [startWeek, endWeek] = selectedWeeks.length > 1 ? selectedWeeks : [...selectedWeeks, ...selectedWeeks];
        vm.results = Object.assign(vm.results,
          { weeks : `${startWeek.survey_week_id}:${startWeek.start_date} - ${endWeek.survey_week_id}:${endWeek.end_date}` }
        );
    }
  }

  vm.toggleFilterForm = function(key = undefined) {
    // make sure to open the right element when clicking on the result pill
    if(key) vm.activeTab = key;
    vm.isFormCollapsed = !vm.isFormCollapsed
  }

  vm.adjustStartWeeksOptions = function() {
    vm.startWeeks = _.filter(vm.filterWeeks, (entry) => {
      return entry.survey_week_id <= vm.model.weeks.end_week;
    });
  };

  vm.adjustEndWeeksOptions = function() {
    vm.endWeeks = _.filter(vm.filterWeeks, (entry) => {
      return entry.survey_week_id >= vm.model.weeks.start_week;
    });
  };
}


angular
  .module("tmr-admin")
  .component("tableFilter", tableFilterComponent)
  .controller("TableFilterController", filterController);
}());
