;(function() {
"use strict";

const resolveEarlyEntryModalComponent = {
  templateUrl:  'components/household/event/resolve-event-form/resolve-early-entry-modal/resolve-early-entry-modal.component.html',
  controller:   'ResolveEarlyEntryModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&'
  },
};

function resolveEarlyEntryModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('resolveEarlyEntryModal', resolveEarlyEntryModalComponent)
    .controller('ResolveEarlyEntryModalController', resolveEarlyEntryModalController)
;
}());
