;(function() {
"use strict";

const householdDetailComponent = {
  templateUrl:  'components/household/main/household-detail/household-detail.component.html',
  controller:   'HouseholdDetailController',
  controllerAs: 'vm',
  bindings: {
    household: "=household",
    canNavigate: "<",
    previousUrl: "<",
  }
};

// ngInject
function householdDetailController($filter, $log, $sessionStorage, $state, $uibModal, Alerts, MetaModel, HouseholdModel, Auth) {
  let vm = this;
  vm.auth = Auth;
  MetaModel.dwellingTypes().then(resp => {
    vm.dwellingTypes = resp;
  });

  vm.resetToken = function() {
    $log.log(vm.household);
    // TODO endpoint for new household tokens
    HouseholdModel.resetToken(vm.household.household_id).$promise.then(resp => {
      vm.household.token = resp.token;
    });
  };

  vm.save = function (withAlert) {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then(() => {
      if (withAlert) {
        Alerts.add('success', `Saved household ${$filter('tmrToken')(vm.household.token)}`);
      }
    });
  };

  // saveThrottled works just like save, but it will delay calling save until
  // there's been a "gap" of saveDelayMilliseconds.
  //
  // eg. if you start typing, and keep typing, it won't call save at all until
  // saveDelayMilliseconds after you stop typing
  //
  // This should do what we want for the notes field. It will save the field
  // automatically, but not so frequently that the user notices.
  //
  let saveDelayMilliseconds = 3000;
  vm.saveThrottled = _.debounce(vm.save, saveDelayMilliseconds);

  vm.openResetModal = function(size) {
    let modalInst = $uibModal.open({
      component: 'resetTokenModal',
    });

    modalInst.result.then(function () {
      // If ok is hit
      vm.resetToken();
    }, function () {
      // If cancel is hit
      $log.info('modal-component dismissed at: ' + new Date());
    });
  };

  vm.back = function() {
    let lastSession = $sessionStorage.householdDetail;
    if (lastSession) {
      let url = $sessionStorage.householdDetail.url;

      if (url) {
        $log.log(`Going to ${url}`);
        $window.location = url;
      }
      else {
        $log.log("No stored url. Back?");
        $window.history.back();
      }
    }
    else {
      if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
        $window.location = "#/manager/assign-workload/list";
      }
      else {
        $window.location = `#/fieldworker/${vm.auth.current.user.admin_user_id}/validation`;
      }
    }
  };
}

angular
    .module('tmr-admin')
    .component('householdDetail', householdDetailComponent)
    .controller('HouseholdDetailController', householdDetailController)
;
}());
