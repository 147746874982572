;(function() {
"use strict";

// ngInject
function streuthApi($http, configService) {
  const conf = configService.config;

  // We update this version manually as necessary.
  const streuthApiVersion = 1;
  const basePath    = `${conf.streuthApiUrl}/v${streuthApiVersion}`;
  const sitePath    = `${basePath}/sites/${conf.siteName}`;
  const invitesPath = `${sitePath}/invites`;
  const invitePath  = inviteId => `${invitesPath}/${inviteId}`;


  // API functions
  function getInvites(projectCode) {
    return $http.get(invitesPath, { }).then((response) => _getDataFilterByProjectCode(response, projectCode));
  }

  function getInvite(inviteId) {
    return $http.get(invitePath(inviteId)).then(_getData);
  }

  function createInvite(invite) {
    return $http.post(invitesPath, invite).then(_getData);
  }

  function updateInvite(inviteId, newStatus) {
    const body = { status: newStatus };
    return $http.put(invitePath(inviteId), body).then(_getData);
  }

  // Internal functions
  function _getData(response) {
    return response.data;
  }

  // get data filter by projectCode 
  function _getDataFilterByProjectCode(response, projectCode) {
    const filteredDataWithProjectCode = response.data.filter(item => item.attributes.some(attr => attr === `project.${projectCode}`));
    return filteredDataWithProjectCode;
  }

  // This is the actual API. It's been split into sections, so you can
  // pick out the individual parts if you like. All calls return a
  // promise, which resolves to the data itself, not the response.
  return {
    Invites: {
      get:      getInvite,
      list:     getInvites,
      create:   createInvite,
      update:   updateInvite,
    },
  };
}

angular
  .module('tmr-admin')
  .factory('StreuthApi', streuthApi)
  ;
}());
