;(function() {
"use strict";

angular.module('tmr-admin').factory('ClusterModel', (ClusterResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = ClusterResource;

  function get(clusterId) {
    //console.log(clusterId);
    return resource.get({ cluster_id: clusterId });
  }

  function getSummary(clusterId) {
    return resource.getSummary({ cluster_id: clusterId});
  }

  function augment(clusterId, newClusterId, invalidateCurrentCluster) {
    return resource.augment(
      // Params (in the URL)
      {
        cluster_id: clusterId
      },
      // Data (in the body)
      {
        new_cluster_id: newClusterId,
        invalidate_current: !!(invalidateCurrentCluster)
      }
    );
  }

  return {
    get:        get,
    getSummary: getSummary,
    augment:    augment,
  };
});
}());
