;(function() {
"use strict";

const invalidationEntryModalComponent = {
  templateUrl:  'components/household/event/contact-event-form/invalidation-entry-modal/invalidation-entry-modal.component.html',
  controller:   'InvalidationEntryModalController',
  controllerAs: 'vm',
  bindings: {
    resolve:  '<',
    close:    '&',
    dismiss:  '&'
  },
};

function invalidationEntryModalController($log) {
  var vm = this;

  vm.$onInit = function () {
  };

  vm.ok = function () {
    vm.close();
  };

  vm.cancel = function () {
    vm.dismiss({$value: 'cancel'});
  };
}

angular
    .module('tmr-admin')
    .component('invalidationEntryModal', invalidationEntryModalComponent)
    .controller('InvalidationEntryModalController', invalidationEntryModalController)
;
}());
