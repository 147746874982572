;(function() {
"use strict";

const tmrMapComponent = {
  templateUrl:  'components/tmr-map/tmr-map.component.html',
  controller:   'tmrMapController',
  controllerAs: 'vm',
  bindings: {
    model: '<',
    defaultCentroid: '<',
  }
};

/* @ngInject */
function tmrMapController($log, $timeout, NgMap) {
  let vm = this;

  angular.extend(vm, {
    showInfoWindow:      showInfoWindow,
  });

  vm.$onChanges = changes;
  function changes(changedObj) {
    if (angular.isDefined(changedObj.defaultCentroid) && angular.isDefined(changedObj.defaultCentroid.currentValue)) {
      vm.defaultCentroid = householdToLatLonString(changedObj.defaultCentroid.currentValue);
    }

    vm.selectedHousehold = _.first(vm.model);
    NgMap.getMap().then(function (map) {
      vm.map = map;
      if (angular.isDefined(changedObj.model) && angular.isDefined(changedObj.model.currentValue)) {

        if (changedObj.model.currentValue.length == 0) {
          // There are no households, so this position shows "deep blue sea"
          vm.mapCentroid = "-26.072268, 173.637410";
        }
        else {
          // Set the position to an 'lat,lon' string
          changedObj.model.currentValue.forEach(house => {
            house.position = householdToLatLonString(house);
          });
          zoomToIncludeMarkers(changedObj.model.currentValue);
        }
      }
    });

  }

  // Smart zooming that has
  // - A limit of how far to zoom in
  // - Fits all markers into view
  // - Zooms to the limit on the map centroid if there are no markers
  function zoomToIncludeMarkers(markers) {
    if (markers.length > 0) {
      // console.log('Zooming to include markers');
      vm.mapCentroid = findCentroid(markers);
      let bounds = new google.maps.LatLngBounds();

      markers.forEach(marker => {
        bounds.extend(
          new google.maps.LatLng(parseFloat(marker.y), parseFloat(marker.x))
        );
      });

      vm.map.fitBounds(bounds);

      if (vm.map.getZoom() > 17) {
        // console.log(vm.map.getZoom() + " is too much zoom. Going back to 17");
        vm.map.setZoom(17);
      }
    }
    else {
      // console.log('Zooming to centroid');
      vm.mapCentroid = vm.defaultCentroid;
      vm.map.setCenter(vm.mapCentroid);
      vm.map.setZoom(17);
    }
  }

  function showInfoWindow(evt, household) {
    vm.selectedHousehold = household;

    // showInfoWindow(windowID, anchorID)
    vm.map.showInfoWindow('household-info-window', `marker-${household.address_id}`);
  }

  // Return a string representing the lat,lon of a household
  function householdToLatLonString(household) {
    let x = parseFloat(household.x);
    let y = parseFloat(household.y);

    return `${y},${x}`;
  }

  // Given an array of markers (households) this method determines and
  // returns the centroid as a lat,lon string
  function findCentroid(markers) {
    let latXTotal = 0;
    let latYTotal = 0;
    let lonDegreesTotal = 0;

    markers.forEach(house => {
      var latDegrees = parseFloat(house.y);
      var lonDegrees = parseFloat(house.x);

      var latRadians = Math.PI * latDegrees / 180;
      latXTotal += Math.cos(latRadians);
      latYTotal += Math.sin(latRadians);

      lonDegreesTotal += lonDegrees;
    });

    let finalLatRadians = Math.atan2(latYTotal, latXTotal);
    let y = parseFloat((finalLatRadians * 180 / Math.PI));
    let x = parseFloat((lonDegreesTotal / markers.length));

    return `${y},${x}`;
  }
}

angular
  .module('tmr-admin')
  .component('tmrMap', tmrMapComponent)
  .controller('tmrMapController', tmrMapController)
;
}());
