;(function() {
"use strict";

angular.module('tmr-admin').factory('ScheduledEventModel', ($log, HouseholdScheduledEventResource, ScheduledEventResource) => {
  // This type of resource service masks the Model to prevent lots of duplicate models persisting.
  let resource = HouseholdScheduledEventResource;
  let seResource = ScheduledEventResource;

  function remove(householdId, eventId) {
    // Delete is a reserved word
    return resource.delete({ household_id: householdId, scheduled_event_id: eventId });
  }

  function get(householdId, eventId) {
    return resource.get({ household_id: householdId, scheduled_event_id: eventId });
  }

  function update(householdId, eventId, contact) {
    return resource.update({ household_id: householdId, scheduled_event_id: eventId }, contact);
  }

  function getAllForHousehold(householdId, range) {
    // query is inbuilt in all resources, this can be passed optional params that get appended to the url
    return resource.query({household_id: householdId});
  }

  function save(householdId, event) {
    return resource.save({household_id: householdId}, event);
  }

  function getAll(range, sort, filters) {
    let params = {};
    params["sort"] = sort;
    _.merge(params, filters);
    return seResource(range).query(params);
  }
  return {
    remove:                 remove,
    get:                    get,
    getAll:                 getAll,
    getAllForHousehold:     getAllForHousehold,
    update:                 update,
    save:                   save
  };
});
}());
