;(function() {
"use strict";

const loginComponent = {
  templateUrl:  'components/login/login.component.html',
  controller:   'LoginController',
  controllerAs: 'vm'
};

/* @ngInject */
function loginController($sce, $log, $state, $window, $uibModal, configService, Auth) {
  let vm = this;
  const streuthUrl = configService.config.streuthUrl;
  const siteName   = configService.config.siteName;

  vm.loginUrl         = '/api/auth/login';
  vm.siteName         = siteName;
  vm.resetPasswordUrl = $sce.trustAsResourceUrl(`${streuthUrl}/x/reset_password?site=${siteName}`);

  vm.openModal = function(size) {
    let modalInst = $uibModal.open({
      component: 'instructionModal',
    });

    modalInst.result.then(function () {
      // If ok is hit
      return;
    }, function () {
      // If cancel is hit
      $log.info('modal-component dismissed at: ' + new Date());
    });
  };

  // If a user is already logged in, skip past the login page
  if (Auth.isLoggedIn()) {
    $state.go('projectSelector');
  }
}

angular
    .module('tmr-admin')
    .component('login', loginComponent)
    .controller('LoginController', loginController)
;
}());
