;(function() {
"use strict";

angular.module('tmr-admin').factory('SurveyWeekResource', ($resource, Project) => {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/survey-weeks/:survey_week_id/clusters', paramDefaults, {
    'get': {method: 'GET', isArray: true},
    'getAllClustersForWeek': { method: 'GET', url:'/api/project/:project_id/survey-weeks/:survey_week_id/clusters', isArray: true},
    'getAll': { method: 'GET', url:'/api/project/:project_id/survey-weeks', isArray: true}
  });
});
}());
