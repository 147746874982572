;(function() {
"use strict";

const householdHeaderComponent = {
  templateUrl:  'components/household/header/household-header.component.html',
  controller: 'HouseholdHeaderController',
  controllerAs: 'vm',
  bindings: {
    parent:'<',
    canNavigate: '<?',
    household: '=',
  }
};

/* @ngInject */
function HouseholdHeaderController(HouseholdModel) {
  let vm = this;

  vm.$onInit = function() {
    vm.back = vm.parent.back;
    vm.loginUrl = vm.parent.loginUrl;
    vm.canNavigate = vm.canNavigate || true; // if not passed, set is as true
  };
  vm.saveContactChoice = function () {
    HouseholdModel.update(vm.household.household_id, vm.household).$promise.then(resp => {
      vm.household.do_not_contact = resp.do_not_contact;
    });
  };
}
  
angular
    .module('tmr-admin')
    .component('householdHeader', householdHeaderComponent)
     .controller('HouseholdHeaderController', HouseholdHeaderController)

;
}());
