;(function() {
"use strict";

const readonlyFieldComponent = {
  templateUrl:  'components/inputs/readonly/readonly.field.component.html',
  bindings: {
    key: '<',
    stringKey: '@',
    value: '<'
  }
};

angular
    .module('tmr-admin')
    .component('readonlyField', readonlyFieldComponent)
;
}());
