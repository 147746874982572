;(function() {
"use strict";

angular.module('tmr-admin').factory('ClusterResource', ($resource, Project) => {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/clusters/:cluster_id/households', paramDefaults, {
    'get':        { method: 'GET', isArray: true},
    'getSummary': { method: 'GET', url: '/api/project/:project_id/clusters/:cluster_id/summary'},
    'augment':    { method: 'POST', url: '/api/project/:project_id/clusters/:cluster_id/augment', isArray: true},
  });
});
}());
