;(function() {
"use strict";

/**
   * Auth is the service responsible for authenticating and authorizing users into TMR. It uses {@link AuthModel} to make requests to the server.
   * @param {!Object} $location ngLocation service.
   * @param {!Object} $cookies ngCookieStore service.
   * @param {!Object} $state ui.router $state service.
   */
angular
  .module('tmr-admin')
  .factory('Auth', Auth);

/* @ngInject */
function Auth($http, $location, $transitions, $state, $log, configService, Project, Rollbar, Storage) {
  let service = {
      /**
       * The currently logged in user. This object is used to store all important information about the user.
       * @type {Object}
       * @property {Object} current.user the user that is logged in.
       * @property {Array} current.organistions filled out with the organisations that this user belongs to.
       * @property {Object} current.roles current users roles in path value pairing of organisations to roles.
       * @property {Array} current.roleSummary a condensed list of the roles that user has irrespective of organisation.
       */
    current: {
        user:  { },
        roles: { },
    },

    // Exposed api functions get listed here.
    canAccessState,
    isAdmin,
    isLoggedIn,
    login,
    logout,
    refresh,
  };
  let storage = Storage();

  const roleStates = {
    'manager': ['Owner', 'Manager'],
    'fieldworker': ['Fieldworker'],
    'household': [ 'Owner', 'Manager', 'Fieldworker' ],
    'household.detail': ['Owner', 'Manager', 'Fieldworker']
  };

  /**
   * Checks to see whether there is currently a user logged in.
   * @returns {Boolean} True or false depending on if there is a user logged in.
   */
  function isLoggedIn() {
    return !angular.equals(service.current.user, {});
  };

  /**
   * Find if a user is able to access a particular state in the application. Passes through [isAdmin]{@link Auth#isAdmin}
   * @param {!String} stateName The state name to check.
   * @returns {Boolean} True or false depending on if there is a user logged in.
   */
  function canAccessState(stateName) {
    if (isAdmin()) {
      return true;
    }
    const allowedRoles = roleStates[stateName];
    let isAllowed = false;
    angular.forEach(allowedRoles, (role) => {
      if (hasRole(role)) {
        isAllowed = true;
      }
    });
    return isAllowed;
  };

  /**
   * Check if a user in an admin of Streuth.
   * @returns {Boolean} True or false depending on if the current user is an admin.
   */
  function isAdmin() {
    // TODO: I think this code might be always false
    if (service.current.user && service.current.user.attributes) {
      return service.current.user.attributes.admin === 1;
    } else {
      return false;
    }
  };

  /**
   * Check if a user has a particular role.
   * @param {!string} roleName The name of the role to check against
   * @returns {Boolean} True or false if the user has the current role or not.
   * @example
   * Auth.hasRole('User Management')
   * // return true if the user has UM
   */
  function hasRole(roleName) {
    if (isAdmin()) {
      return true;
    }
    if (service.current.roles[roleName]) {
      return true;
    } else {
      return false;
    }
  };

  function getTimezone() {
    return jstz.determine().name();
  }

  /**
   * Contact the server and attempt to retrieve user details.
   * @returns The user promise.
   */
  function login(forceRefresh = false) {
    if (service.current.user.$promise) {

      // If we already have a promise, it may be already resolved, or it may
      // still be in flight, but either way, we don't want to fire off a new
      // one.
      //
      if (forceRefresh && isLoggedIn()) {
        // Unless we've specifically asked to force the refresh. If that's the
        // case, and the existing promise has completed, we fall through and
        // redo the promise.
        //console.log("Forcing user refresh");
      }
      else {
        // This is the usual case, just return the existing promise.
        return service.current.user.$promise;
      }
    }

    service.current.user.$promise = fetchUser().then(user => {

      // TODO: this is confusing. Fetching the user always returns 200, but
      // if not logged in, returns an empty object. Problem is that because
      // we're using this resource class, the empty user object isn't empty.
      // Next round I think we replace that user resource with a plain old
      // $http jobby.

      // Decorate Rollbar with logged in user info
      Rollbar.configure({
        payload: {
          person: {
            username: user.full_name,
            id:       user.admin_user_id,
          },
        }
      });

      if (user.is_owner) {
        service.current.roles.Owner = true;
        user.homeState = {
            state: 'listHouseholds',
            params: { },
        };
      }
      else if (user.is_manager) {
        service.current.roles.Manager = true;
        user.homeState = {
            state: 'listHouseholds',
            params: { },
        };
      }
      else if (user.is_telephone) {
        service.current.roles.Telephone = true;
        user.homeState = {
            state: 'assignEvents',
            params: { },
        };
      }
      else if (user.is_fieldworker) {
        service.current.roles.Fieldworker = true;
        user.homeState = {
          state: 'fieldworker',
          params: {
            fieldworker_id: user.admin_user_id,
          },
        };
      }

      Project.setProjectList(user.projects);

      // Don't select the project here, do that in the onEnter handler of the
      // project state.
      if (user.projects && user.projects.length == 1) {
        user.homeState.params.project_id = user.projects[0].project_id;
      }

      Object.assign(service.current.user, user);
      return user;
    });
    return service.current.user.$promise;
  }

  // This fetches the user data again.
  function refresh() {
    return login(true);
  }

  /**
   * Log the current user out of the system.
   */
  function logout() {
    logoutUser().then(() => {
      $state.go('login');
    });

    Project.clearProject();
    storage.clear();
    service.current = {
      user: {},
      roles: {}
    };
  };

  function fetchUser() {
    return $http.put('/api/user', { timezone: getTimezone() })
                .then(response => response.data);
  }

  function logoutUser() {
    return $http.delete('/api/user').then(response => response.data);
  }

  return service;
}
}());
