;(function() {
"use strict";

angular.module('tmr-admin').factory('FieldworkerDetailResource', ($resource, Project) => {
  return (range) => {
    const paramDefaults = {
      project_id: function() { return Project.getProjectID(); }
    };
    return $resource('/api/project/:project_id/users/:admin_user_id/active', paramDefaults, {
      'update': { method:'PUT' },
      'activateProjects': { method: 'PUT', url: '/api/user/:user_id/projects' },
      'withFilters': { method: 'GET', url:'/api/project/:project_id/users', isArray: false, headers: {"Range": range},
        transformResponse: function(data, headers, status) {
          let response = {};
          response.data = angular.fromJson(data);
          response.headers = headers();
          return response;
        },
      },
    });
  };
});
}());
