;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('FieldworkerBatchResource', FieldworkerBatchResource);

/* @ngInject */
function FieldworkerBatchResource($resource, Project) {
  const paramDefaults = {
    project_id: function() { return Project.getProjectID(); }
  };
  return $resource('/api/project/:project_id/fieldworkers/:fieldworker_id/households/:role', paramDefaults, {
    'assignToHouseholds': {method: 'PUT', url: '/api/project/:project_id/fieldworkers/:fieldworker_id/households/:role/assign', isArray: true},
    'removeFromHouseholds': {method: 'PUT', url: '/api/project/:project_id/fieldworkers/:fieldworker_id/households/:role/remove', isArray: true}
  });
}
}());
