;(function() {
"use strict";

const fieldworkerTodoComponent = {
  templateUrl:  'components/fieldworker/todo/fieldworker-todo.component.html',
  controller:   'FieldworkerTodoController',
  controllerAs: 'vm',
  require: {
    parent: '^fieldworker'
  },
  bindings: {
    mode:            '<mode',
    selectedWeek:    '<',
  }
};

/* @ngInject */
function fieldworkerTodoController($log, NgTableParams, $filter, $state, FieldworkerModel, AccordionPersistence) {
  let vm = this;

  vm.$onChanges = changes;

  let accordionPersistence = AccordionPersistence();

  let modeData = {
    validation: {
      role: 'validator',
      isValid: function(household) {
        return true;
      },
      groups: [ 'now', 'done' ],
      classify: function(household) {
        switch (household.status) {
          case 'Unverified':  return 'now';
          default:            return 'done';
        }
      },
    },

    recruitment: {
      role: 'recruiter',
      isValid: function(household) {
        if (household.status === 'Unverified') {
          return false;
        }
        return true;
      },
      groups: [ 'now', 'later', 'done' ],
      classify: function(household) {
        switch (household.status) {
          case 'Validation - Validated':      return 'now';
          case 'Recruitment - No response':   return 'later';
          default:                            return 'done';
        }
      },
    },
  };

  function splitHouseholdsIntoClusters(households) {
    // Make a cluster => [ households ] mapping.
    let byCluster = _.groupBy(households, 'cluster');

    // Flatten that mapping out into a list of objects.
    let clusters = _.map(byCluster, (householdsList, clusterName) => ({
      cluster:    householdsList[0].cluster,
      cluster_id: householdsList[0].cluster_id,
      households: householdsList,
    }));

    // Sort that list by cluster name.
    return _.orderBy(clusters, 'cluster');
  }

  let selectedGroup;
  let selectedCluster = { };

  // This gets called whenever one of the accordions changes state.
  function accordionCallback(key, value) {
    if (!value) {
      return; // just leave things as they are
    }
    if (key == 'when') {  // Selected now/later/done has changed
      selectedGroup = value.name;
    }
    else {                // Cluster within a group has changed
      selectedCluster[key] = value;
    }

    let cluster = selectedCluster[selectedGroup];
    if (cluster) {
      $log.log(`Selecting workload ${cluster.cluster}[${selectedGroup}]`);
      vm.parent.setSelectedWorkload(cluster.households);
    }
  }

  function makeAccordionData(mode_id, fieldworker_id, week_number) {
    let mode = modeData[mode_id];
    FieldworkerModel.getHouseholds(fieldworker_id, mode.role, week_number)
                    .$promise.then(households => {
  // if there is no household, simply set [] and return
      if(!households.length) {
        vm.parent.setMapData([]);
        // if there is no households, also set householdAccordianData to empty array
        vm.householdAccordionData = [];
        return 0;
      }
      // Add a streetName & streetNumber field to each household.
      // We use those to sort on
      _.each(households, splitAddress);

      // Sort the households by streetName,streetNumber, then split into
      // groups by now/later/done, then split each of those into clusters.
      let groups = _(households)
                    // Remove the invalid results for recruitment.
                    .filter(mode.isValid)
                    .orderBy(['streetName', 'streetNumber'])
                    .groupBy(mode.classify)
                    .mapValues(splitHouseholdsIntoClusters)
                    .value();

      // if the groups does not have any now|Later|Done array, reset the map data
      if(!Object.keys(groups).length) {
        vm.parent.setMapData([]);
      }
      // $log.log('Groups', groups);

      let totalHouseholds = _(households)
        // Remove the invalid results for recruitment.
        .filter(mode.isValid)
        .orderBy(['streetName', 'streetNumber'])
        .groupBy(mode.classify)
        .value();

      function getHouseholdCount(groupName) {
        if (totalHouseholds[groupName]) {
          return totalHouseholds[groupName].length;
        }
        return 0;
      }

      function hasData(groupName) {
        if(getHouseholdCount(groupName) == 0) {
          return false;
        }
        return true;
      }

      // Use the object above to create an array of objects.
      vm.householdAccordionData =
        _.map(mode.groups, groupName => ({
          heading:          _.capitalize(groupName),
          name:             groupName,
          household_count:  getHouseholdCount(groupName),
          isDisable:        !hasData(groupName),
          clusters:         groups[groupName] || [ ],
        }));

      // Attach persistence to all the accordions
      function canOpenGroup(group) {
        return group.household_count > 0;
      }

      function canOpenCluster(cluster) {
        return cluster.households.length > 0;
      }

      accordionPersistence.attach(vm.householdAccordionData, 'when', 'name', canOpenGroup, accordionCallback);
      _.each(vm.householdAccordionData, group => {
        accordionPersistence.attach(group.clusters, group.name, 'cluster_id', canOpenCluster, accordionCallback);
      });
    });
  }

  function changes(changedObj) {
    let id = $state.params.fieldworker_id;
    if (changedObj.mode && changedObj.mode.currentValue) {
      vm.mode = changedObj.mode.currentValue;
    }
    if (changedObj.selectedWeek && changedObj.selectedWeek.currentValue) {
      vm.selectedWeek = changedObj.selectedWeek.currentValue;
      selectedCluster = { };
      makeAccordionData(vm.mode, id, vm.selectedWeek.week_number);
    }
  }

  function splitAddress(household) {
    let match = /^\s*(\S+)\s+(.*)$/.exec(household.address)
              || [ household.address, household.address ];

    household.streetNumber = match[1];
    household.streetName   = match[2];
  }

  function addressForHouseholdId(householdId) {
    return vm.addressInfo[householdId].address;
  }

  // TODO: fill in later/done
  angular.extend(vm, {
    householdsToView:       'TODO',
    addressForHouseholdId:  addressForHouseholdId
  });
}

angular
  .module('tmr-admin')
  .component('fieldworkerTodo', fieldworkerTodoComponent)
  .controller('FieldworkerTodoController', fieldworkerTodoController)
;
}());
