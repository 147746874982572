;(function() {
"use strict";

const trainingBannerComponent = {
  templateUrl:  'components/training-banner/training-banner.component.html',
  controller:   'TrainingBannerController',
  controllerAs: 'vm',
};

/* @ngInject */
function TrainingBannerController(Project) {
  let vm = this;

  vm.isTraining = Project.isTrainingProject();

  function onProjectChange(oldProject, newProject) {
    vm.isTraining = Project.isTrainingProject();
  }

  const unsubscribe = Project.subscribe(onProjectChange);
}

angular
    .module('tmr-admin')
    .component('trainingBanner', trainingBannerComponent)
    .controller('TrainingBannerController', TrainingBannerController)
;
}());
