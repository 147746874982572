;(function() {
"use strict";

const workSummaryComponent = {
  templateUrl:  'components/fieldworker/work-summary/work-summary.component.html',
  controller:   'WorkSummaryController',
  controllerAs: 'vm',
  require: {
    parent: '^fieldworker'
  },
  bindings: {
    selectedWeek: '<',
  }
};

// ngInject
function workSummaryController($log, $q, $filter, $state, $uibModal, NgTableParams, FieldworkerModel, MetaModel, $scope, Project) {
  let vm = this;
  vm.projectFeatures = Project.getProjectFeatures();
  vm.work = [];

  vm.$onInit = function() {
    vm.tableParams = new NgTableParams(
      {
        //count: vm.filteredWork.length,
        count: 500, // big enought to prevent paging
        sorting: true,
      },
      {
        counts: [] // hides pager
      }
    );
    if (vm.parent.showMap === true) {
      vm.parent.showMap = false;
    }
    MetaModel.contactTypesOutcomes().then(resp => {
      vm.contactTypeOutcomes = resp;
    });

    $scope.$watch('vm.parent.relevance', (newValue) => {
      vm.filter( vm.work, newValue );
    });
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.selectedWeek) {
      if (changedObj.selectedWeek.currentValue) {
        vm.selectedWeek = changedObj.selectedWeek.currentValue;
        FieldworkerModel.getAllByWeekAndWorker($state.params.fieldworker_id, vm.selectedWeek.week_number).$promise.then(resp => {
          vm.work = resp;
          // Making the silent sort fielld (address sans number)
          _.forEach(vm.work, (house) => {
            let components = /^[\w|\d|\/]+\s+(.*)$/.exec(house.address);
            if (components) {
              house.silent_sort_address = components[1];
            } else {
              $log.warn('address may not have passed regex properly');
              $log.log(house.address);
            }
          });
          vm.filter(vm.work, vm.parent.relevance);
        });
      }
    }
  };

  vm.openModal = function(household_note, event_note) {
    let modalInst = $uibModal.open({
      component: 'noteModal',
      resolve: {
        notes: () => {
          return { household: household_note, event: event_note };
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
    }, function () {
      // If cancel is hit
    });
  };

  vm.filter = function(data, relevance) {
    if (vm.work.length > 0) {
      vm.message = "Nothing to show";
    } else {
      vm.message = "There is no work scheduled";
    }

    let filteredData = filterByRelevance(data, relevance);
    vm.tableParams.settings({
      count: filteredData.length,
      dataset: filteredData
    });
  };

  function filterByRelevance(data, relevance) {
    if (relevance == 'todo') {
      return _.reject(data, isDone);
    }

    if (relevance == 'done') {
      return _.filter(data, isDone);
    }

    return data;
  }

  function isDone(row) {
    if (row.scheduled_for) {
      return isScheduledEventDone(row);
    }
    else {
      return isContactEventDone(row);
    }
  }

  function isScheduledEventDone(row) {
    if (row.resolved_by_contact_id) {
      return true;
    }

    let todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    return new Date(row.scheduled_for) <= todayMidnight;
  }

  function isContactEventDone(row) {
    if (row.status === 'Unverified') {
      return false;
    }

    if (row.latest_contact) {
      let typeOutcomes = _.find(vm.contactTypeOutcomes, {'label': row.work_type });
      return _.some(
        typeOutcomes.contact_outcomes, (outcome) => (outcome.id === row.latest_contact.contact_outcome_id) && outcome.type_final
      );
    }

    return false;
  }

}

angular
    .module('tmr-admin')
    .component('workSummary', workSummaryComponent)
    .controller('WorkSummaryController', workSummaryController);
}());
