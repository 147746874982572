;(function() {
"use strict";

const accessHouseholdComponent = {
  templateUrl:  'components/access-household/access-household.component.html',
  controller:   'AccessHouseholdController',
  controllerAs: 'vm',
};

/* @ngInject */
function AccessHouseholdController(HouseholdModel, $state, Alerts) {
  let vm = this;

  angular.extend(vm, {
      findHouseholdByToken: findHouseholdByToken,
  });

  function findHouseholdByToken() {
    if (!vm.token) {
      Alerts.add('danger', `No access code specified. Please enter an access code.`);
    } else {
      let token = vm.token.toUpperCase();
      HouseholdModel.byToken(token).$promise.then(
        (household) => {
          $state.go('householdMain', {household_id: household.household_id});
        },
        (failure) => {
          if (failure.status == 404) {
            // The http interceptor already shows an alert.
            // See https://gh.sdintra.net/Client-TMR/admin/issues/98
            // Leave this one out until then.
            // Alerts.add('danger', `No household found for token: ${token}`);
          }
        }
      );
    }
  }
}

angular
    .module('tmr-admin')
    .component('accessHousehold', accessHouseholdComponent)
    .controller('AccessHouseholdController', AccessHouseholdController)
;
}());
