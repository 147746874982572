;(function() {
"use strict";

angular
    .module('tmr-admin')
    .factory('BooleanAttribute', BooleanAttribute);

/* @ngInject */
function BooleanAttribute() {
    // This service is just a single function.
    // It converts an attribute value to a boolean.
    // Use it in directives:
    // eg. scope: { good: '@' },
    //     link(scope, element, attr) {
    //        scope.good = booleanAttr(attr.good);
    //    }
    //    <directive good>         good = true
    //    <directive>              good = false
    return function(attributeValue) {
        return !_.isUndefined(attributeValue);
    }
}
}());
