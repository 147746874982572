;(function() {
"use strict";

const householdListAllComponent = {
  templateUrl:  'components/manager/household-list-all/household-list-all.component.html',
  controller:   'HouseholdListAllController',
  controllerAs: 'vm',
  bindings: {
    weeks: "<"
  }
};

// ngInject
function householdListAllController($log,
                                    $q,
                                    $state,
                                    $uibModal,
                                    $cookies,
                                    $window,
                                    NgTableParams,
                                    HouseholdModel,
                                    FieldworkerModel,
                                    TablePersistence,
                                    Alerts,
                                    Project,
                                    Storage,
                                   ) {
  let vm = this;
  let project_id  = Project.getProjectID();
  let persistence = TablePersistence();
  let storage = Storage();
  let filteredData = [];

  vm.headerClass = "text-left";

  function getValidFilter(filters) {
    // store valid filters
    return  _.pickBy(angular.copy(filters), filter => filter !== "");
  }

  function isValidDate(dateStr) {
    let regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateStr) && !_.isNaN(Date.parse(dateStr));
  }
  vm.$onInit = function() {
    // disable <body> scrollbar
    angular.element(document.querySelector('body')).css({'overflow-x': 'hidden'});

    let init = true;
    let rowCount = $cookies.get("rowCount") ? $cookies.get("rowCount") : 25;

    // define external fields we want to grab
    // so later we can use queryParams.survey_week_id
    const queryParams = persistence.get(['survey_week_id']);
    const hasQueryParams = queryParams.filter && Object.keys(queryParams.filter).length;
    const persistedFilter = storage.getItem('householdStatus');
    const defaultFilter = {
      page: 1, // show first page
      count: 1 * rowCount, // count per page default
      isFiltersVisible: true,
      sorting: {
        cluster: "asc",
        travel_day: "asc",
      },
    };

    let baseParams = hasQueryParams  ? queryParams
                    : persistedFilter ? persistedFilter['baseParams']
                    :                   defaultFilter;

    if (hasQueryParams && baseParams.survey_week_id) {
      // prevent all params to fall back to the default option
      const arr = _.clone(baseParams.survey_week_id).split('-');
      baseParams.extraFilters = {
        weeks: { start_week: _.toInteger(arr[0]), end_week: _.toInteger(arr[1]) },
      };
    }

    let encodeSort = persistence.encodeSort;
    let validBaseParamsFilter = getValidFilter(baseParams.filter);

    vm.tableParams = new NgTableParams(baseParams, {
      total: 0,
      paginationMaxBlocks: 8,
      paginationMinBlocks: 2,
      getData: function(params) {
        vm.headerClass = 'text-left';

        let tableParamsFilter = params.filter();

        // if extraFilters is persisted, use that
        if(!vm.extraFilters && baseParams.extraFilters){
          vm.extraFilters = angular.copy(baseParams.extraFilters)
        }

        // apply default week filter if not initially present
        if((!baseParams.extraFilters || (baseParams.extraFilters && !baseParams.extraFilters.weeks)) && vm.weeks.length){
          //leave the week filter empty
          //vm.extraFilters = {
          //  weeks: {
          //    start_week: vm.weeks[0].survey_week_id,
          //    end_week: vm.weeks[vm.weeks.length - 1].survey_week_id
          //  }
          //};
        }

        // delete week filter if user has removed the filter
        if (vm.extraFilters){
          if (_.isEmpty(vm.extraFilters.weeks)) {
            delete tableParamsFilter.survey_week_id;
          }
          else {
            let w = vm.extraFilters.weeks;
            tableParamsFilter.survey_week_id = `${w.start_week}-${w.end_week}`;
          }
        }

        // Update the url to reflect our updated params
        tableParamsFilter = getValidFilter(tableParamsFilter);

        // validate travel_day if we enter one
        if (tableParamsFilter.travel_day && !init && !isValidDate(tableParamsFilter.travel_day)) {
          vm.headerClass = 'date-error text-left';

          // remove travel day
          delete tableParamsFilter['travel_day'];
          if (validBaseParamsFilter['travel_day']) {
            delete validBaseParamsFilter['travel_day'];
          }
          else if (angular.equals(validBaseParamsFilter, tableParamsFilter)) {
            // if this change is trigger by travel_day filter
            return filteredData;
          }

        }

        storage.setItem('householdStatus',
          { 'baseParams': Object.assign(baseParams, { filter: tableParamsFilter, extraFilters: vm.extraFilters }) }
        );
        params.localFilters = tableParamsFilter;
        persistence.set(params);

        // page setup
        let page = params.page();
        let count = params.count();
        if (!$cookies.get("rowCount")) {
          $cookies.put("rowCount", params.count());
        }
        // range is 0 indexed
        let range = `${((page * count) - count)}-${(page * count) - 1}`;
        return HouseholdModel.getAllFiltered(range, encodeSort(params.sorting()), tableParamsFilter).$promise.then((resp) => {
          init = false;
          vm.tableParams.total(setPages(resp.headers["content-range"]));

          // Add in the capi/cati users here rather than computing them live.
          // Stops the table from freaking out with digest limits reached.
          _.each(resp.data, household => {
            let summary = household.assignment_summary;
            if (summary && summary.capi_assigned_to_id) {
              household.capi_user = vm.capiFieldworker(summary);
            }
            if (summary && summary.cati_assigned_to_id) {
              household.cati_user = vm.catiFieldworker(summary);
            }
          });
          filteredData = resp.data;
          return resp.data;
        });

      }, // getData: function(params)
    });

    vm.tableParams.downloadFiltered = function() {
      // needs a way to refer to itself to access filters and sort, sucks i have to dingleberry this function on like this. RA
      let params = { };
      _.each(this.filter(), (value, key) => {
        // If you type in a travel day, then clear it, you'll still get
        // travel_day="", which causes api problems. Strip out any empty
        // filters.
        value = value.trim();
        if (value.length > 0) {
          if (key == 'travel_day') {
            if (!isValidDate(value)) {
              return; // skip invalid dates
            }
          }
          params[key] = value;
        }
      });
      params['download'] = 1;
      params['sort'] = encodeSort(this.sorting());
      let filterStr = '?' + _.map(params, (value, key) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');
      let url = '/api/project/'+project_id+'/households' + filterStr;
      $window.open(url, '_blank');
    };

    vm.tableParams.downloadAll = function() {
      $window.open('/api/project/'+project_id+'/households?download=1&missing_clusters=1',
                   '_blank');
    };

    vm.tableParams.downloadRefusedInvalidated = function() {
      $window.open('/api/project/'+project_id+'/refused-invalidated');
    };
  };

  vm.$onDestroy = function() {
    // remove in-line styling
    angular.element(document.querySelector('body')).css({'overflow-x': ''});
  };

  let treatAsUTC = function(date) {
    let result = new Date(date);
    // Treat the date as morning midnight of date in question (remove all hours, mins, secs)
    result.setHours(0, 0, 0, 0);
    return result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  };

  vm.daysSince = function(fromDate, toDate) {
    // divided by milliseconds per day
    if (angular.isDefined(fromDate) && angular.isDefined(toDate)) {
      // https://rt.strategicdata.com.au/Ticket/Display.html?id=736941
      // We had entries with +1 hour here, probably to do with daylight savings.
      // Presumably we might also get -1 hours in six months time.
      return Math.round((treatAsUTC(toDate) - treatAsUTC(fromDate)) / 86400000);
    }
    return "";
  };

  let setPages = function(header) {
    if (header === 'items */0') {
      return 0;
    }
    let [, , , max] = header.match(/(\d+)-(\d+)\/(\d+)/);
    return max;
  };

  vm.openModal = function(household_note, event_note) {
    let modalInst = $uibModal.open({
      component: 'noteModal',
      resolve: {
        notes: () => {
          return { household: household_note, event: event_note };
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      return;
    }, function () {
      // If cancel is hit
      return;
    });
  };

  vm.capiFieldworker = function(assignment_summary) {
    return {
      admin_user_id   :   assignment_summary.capi_assigned_to_id,
      full_name       :   assignment_summary.capi_full_name,
      code            :   assignment_summary.capi_user_code,
      survey_week_id  :   assignment_summary.capi_week_id,
    };
  };

  vm.catiFieldworker = function(assignment_summary) {
    return {
      admin_user_id   :   assignment_summary.cati_assigned_to_id,
      full_name       :   assignment_summary.cati_full_name,
      code            :   assignment_summary.cati_user_code,
      survey_week_id  :   assignment_summary.cati_week_id,
    };
  };

  vm.handleApply = function(model){
    vm.extraFilters = model;
    vm.tableParams.reload();
  }

  vm.clearTableParamsFilter = function() {
    vm.tableParams.filter({});
  }

  vm.isTableParamsFilterEmpty = function() {
    return _.isEmpty(getValidFilter(vm.tableParams.filter()));
  }

}

angular
    .module('tmr-admin')
    .component('householdListAll', householdListAllComponent)
    .controller('HouseholdListAllController', householdListAllController);
}());
