;(function() {
"use strict";

angular
  .module('tmr-admin')
  .factory('Project', Project);

function Project() {

  let selectedProject = null;
  let projectList = null;
  let subscribers = [ ];

  return {
    clearProject,
    setProjectID,
    getProjectList,
    setProjectList,
    getProjectID,
    getProjectName,
    getProjectCode,
    getProjectFeatures,
    isTrainingProject,
    subscribe
  };

  // Note: I've left a bunch of trace messages in here, commented out in case
  // things blow up and we need to diagnose this. At the time of writing, all
  // the cases seem to work okay.
  function getProjectList() {
    return projectList;
  }

  function setProjectList(newProjectList) {
    projectList = angular.copy(newProjectList);
  }

  function getProjectID() {
    if (! selectedProject) {
      //console.trace();
      //console.error("getProjectID() selectedProject is undefined");
      return 0;
    }

    //console.log(`getProjectID() = ${ selectedProject.project_id }`);
    return selectedProject.project_id;
  }

  function getProjectName() {
    if (! selectedProject) {
      //console.error("getProjectName() selectedProject is undefined");
      return '(no project)';
    }

    //console.log(`getProjectName() = "${ selectedProject.name }"`);
    return selectedProject.name;
  }

  function getProjectCode() {
    if (! selectedProject) {
      //console.error("getProjectName() selectedProject is undefined");
      return '(no project)';
    }

    //console.log(`getProjectName() = "${ selectedProject.name }"`);
    return selectedProject.code;
  }

  function getProjectFeatures() {
    if (! selectedProject) {
      return { };
    }
    return selectedProject.features;
  }

  function isTrainingProject() {
    if (! selectedProject) {
      return false;
    }
    return selectedProject.is_training;
  }

  // While it feels like maybe we could pull this from $stateParas.project_id,
  // that didn't work.
  // 1. There's some sort of initialisation order problem. Even though the
  //    project state has $stateParams.project_id set, if we try to access that
  //    value directly, it isn't set yet. (it is available later on though)
  // 2. Pushing it in from the project state onEnter handler means we can abort
  //    the transition by returning false if the id is invalid.
  function setProjectID(newProjectID) {
    if (!projectList) {
      //console.error(`setProjectByID(${ newProjectID }) - no projects in list`);
      return false;
    }

    let newProject = _.find(projectList, p => p.project_id == newProjectID);
    if (!newProject) {
      //console.error(`setProjectByID(${ newProjectID }) not found in `, angular.copy(projectList));
      return false;
    }

    //console.log(`setProjectID(${ newProjectID }) = `, angular.copy(newProject));
    updateProject(newProject);

    return true;
  }

  function clearProject() {
    updateProject(null);
  }

  function updateProject(newProject) {
    let oldProject = selectedProject;
    selectedProject = newProject;

    // Notify any subscribers
    _.each(subscribers, callback => {
      callback(oldProject, newProject);
    });
  }

  // Subscribe to notifications when the project changes.
  // Returns an unsubscribe function.
  // callback is function(oldProject, newProject)
  function subscribe(callback) {
    function unsubscribe() {
      subscribers = _.reject(callback);
    }
    subscribers.push(callback);
    return unsubscribe;
  }
}
}());
