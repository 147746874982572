;(function() {
"use strict";

const managerUsersPendingComponent = {
  templateUrl:  'components/manager/manage-users/pending/pending-invite.html',
  controller:   'managerUsersPendingController',
  controllerAs: 'vm',
  bindings: {
    newInvite: '='
  }
};

function managerUsersPendingController($cookies,
                                      NgTableParams,
                                      Alerts,
                                      Project,
                                      Auth,
                                      StreuthApi,
                                      $scope,
                                      $stateParams,
                                      $state,
                                    ) {
  let vm = this;
  vm.project_id = Project.getProjectID();
  vm.projectCode = Project.getProjectCode();
  vm.logged_in_user_id = Auth.current.user.admin_user_id;

  const invitesApi = StreuthApi.Invites;
  const invites = [ ]; // the reference is constant, the array itself is mutable

  $scope.$watch(() => vm.newInvite, (newVal, oldVal) => {
    if (oldVal !== newVal && newVal) {
      const newInvites = [...invites, newVal];
      setInvitesList(newInvites);
    }
  })

  function setInvitesList(newInvites) {
      // map 'exceeded' status to 'failed' for display purposes:
      _.each(newInvites, invite => {
        invite.status_display = (invite.status === 'exceeded')
                            ? 'Failed' : _.upperFirst(invite.status);

        // there may be multiple role attributes set for a user, but we're only
        // interested in the highest priority one.
        // vis. Manager > Telephone > Fieldworker
        for ( const attr of invite.attributes ) {
          let role = _.upperFirst(attr);
          if ( role === 'Manager' ) {
            invite.role = role;
          }
          if ( role === 'Telephone' && invite.role !== 'Manager' ) {
            invite.role = role;
          }
          if ( role === 'Fieldworker' && invite.role !== 'Telephone' && invite.role !== 'Manager' ) {
            invite.role = role;
          }
        }
        // fallback if no user role specified:
        if ( ! invite.role ) {
          invite.role = '(unknown)';
        }

        _.each(invite.actions, i => {
            i.verb = _.upperFirst(i.verb);
        });
      });

      // Replace the contents of the invites array with the contents of
      // result.
      invites.length = 0;
      invites.push(...newInvites);
      vm.tableParams.reload();
  }

  function updateTable() {
    invitesApi.list(vm.projectCode).then(setInvitesList);
  }

  vm.$onInit = function() {

    const defaultFilterId = '';
    vm.roleOpts = [
      { id: defaultFilterId, title: 'All' }, // default selection
      { id: 'manager', title: 'Manager' },
      { id: 'fieldworker', title: 'Fieldworker' },
      { id: 'telephone', title: 'Telephone' }
    ];
    vm.statusOpts = [
      { id: defaultFilterId, title: 'All' }, // default selection
      { id: 'accepted', title: 'Accepted' },
      { id: 'pending',  title: 'Pending'  },
      { id: 'exceeded', title: 'Failed'   },
      { id: 'expired',  title: 'Expired'  },
      { id: 'revoked',  title: 'Revoked'  }
    ];

    // When using a selector with ng-table, if there is no option with a blank
    // id, ng-table will insert a blank one for you. That's the default option.
    // You'll get a selectable blank line in your selector.
    // By specifying a row with an empty id, that one becomes your default.
    angular.element(document).ready(function () {
      let options = document.querySelectorAll('option[value=""]');
      if (options.length) {
        Array.from(options).forEach(option => {
          option.parentNode.removeChild(option);
        })
      }
    });

    vm.tableParams = new NgTableParams(
      {
        page: 1,            // show first page
        isFiltersVisible: true,
        count: 10,
        sorting: {
          full_name: "asc",
        },
      },
      {
        dataset: invites,
      }
    );

    updateTable();
  }

  vm.updateInvitation = function(inviteId, actionObj) {
    invitesApi.update(inviteId, actionObj.status)
      .then(response => {
        Alerts.add('success', response.message);
      })
      .then(updateTable)
  }
}

angular
    .module('tmr-admin')
    .component('managerUsersPending', managerUsersPendingComponent)
    .controller('managerUsersPendingController', managerUsersPendingController);
}());
