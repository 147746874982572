;(function() {
"use strict";

function previousUrl($log, $sessionStorage, $state, $window) {

  function create(storageKey) {

    function normaliseUrl(url) {
      // This isn't a full url normalisation. Just tackling the cases we're
      // likely to run into. So far it's just doubling up slashes, where
      // we say "$base/something", and base already has a slash.
      url = url.replace(/\/+/g, "/");   // multiple slashes -> single slash
      url = url.replace(":/", "://");   // fix the proto:// part we just broke

      return url;
    }

    function save() {
      // If we've not come from a previous state, then currentUrl will be some
      // substring of ourUrl. If this is the case, don't save it, because then
      // we'll have a back button that takes you nowhere.
      let ourUrl = normaliseUrl($state.href($state.current.name, $state.params, {absolute: true}));
      let currentUrl = normaliseUrl($window.location.href);

      //$log.log(`current=${currentUrl} ours=${ourUrl}`);
      if (ourUrl.indexOf(currentUrl) == 0) {
        //$log.log(`Current url ${currentUrl} is from calling page - not saving`);
      }
      else {
        setUrl($window.location.href);
      }
    }

    function go() {
      let url = getUrl();
      if (url) {
        //$log.log(`Going to ${url}`);
        $window.location = url;
      }
      else {
        //$log.log("No stored url. Back?");
        $window.history.back();
      }
    }

    function setUrl(newUrl) {
      let oldUrl = $sessionStorage[storageKey];
      $sessionStorage[storageKey] = newUrl;
      //$log.log(`PreviousUrl[${storageKey}].set: ${oldUrl}->${newUrl}`);
    }

    function getUrl() {
      let oldUrl = $sessionStorage[storageKey];
      //$log.log(`PreviousUrl[${storageKey}].get: ${oldUrl}`);
      return oldUrl;
    }

    // Save the current url when the user creates an object.
    save();

    return {
      save:   save,
      go:     go,
    };
  }

  // This factory just returns a constructor function.
  return create;
}

angular.module('tmr-admin')
       .factory('PreviousUrl', previousUrl);
}());
