;(function() {
"use strict";

const eventListComponent = {
  templateUrl:  'components/household/main/scheduled-event/event-list/event-list.component.html',
  controller:   'EventListController',
  controllerAs: 'vm',
  require: {
    parent: '^scheduledEvent'
  },
  bindings: {
    scheduledEvents: '<',
    household: '<',
  }
};

function eventListController($log, Auth, $uibModal,NgTableParams) {
  let vm = this;
  vm.auth = Auth;
  // Data for ng-table must be an array
  vm.$onChanges = function(changedObj) {
    if (changedObj.scheduledEvents && changedObj.scheduledEvents.currentValue) {
      vm.scheduledEvents = angular.copy(changedObj.scheduledEvents.currentValue);
      vm.tableParams = new NgTableParams({
        count: vm.scheduledEvents.length, // hides pager
        sorting: {
          scheduled_for: "desc",
        },
      }, {
        dataset: vm.scheduledEvents,
        counts: [], // hides page sizes
      });
    }
  };

  vm.editEvent = function(model) {
    // TODO this is not a good way of doing this, altering parent values to show/hide bits
    vm.parent.eventToEdit = model;
    vm.parent.onEditForm();
  };

  vm.disableEdit = function(id) {
    // In this scenario false means "do not disable this button" also TODO this function seems to run a lot for each entry, suspicious
    if (vm.auth.current.user.is_owner || vm.auth.current.user.is_manager) {
      return false;
    } else if (vm.auth.current.user.admin_user_id === id) {
      return false;
    } else if (id == null) {
      return false;
    }
    return true;
  };

  vm.openModal = function(household_note, event_note) {
    let modalInst = $uibModal.open({
      component: 'noteModal',
      resolve: {
        notes: () => {
          return { household: household_note, event: event_note };
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      return;
    }, function () {
      // If cancel is hit
      return;
    });
  };
}

angular
    .module('tmr-admin')
    .component('eventList', eventListComponent)
    .controller('EventListController', eventListController)
;
}());
