;(function() {
"use strict";

const eventsListComponent = {
  templateUrl:  'components/manager/assign-events/events-list/events-list.component.html',
  controller:   'EventsListController',
  controllerAs: 'vm',
  bindings: {
  }
};

// ngInject
function eventsListController($log, $q, $filter, $state, $cookies, $scope, TablePersistence, Auth, $uibModal, NgTableParams, ScheduledEventModel, FieldworkerModel, Storage) {
  let vm = this;
  vm.auth = Auth;
  let storage = Storage();

  let buildSort = function(sort) {
    let sortArr = [];
    _.forOwn(sort, (value, key) => {
      if (value === "asc") {
        sortArr.push(`${key}`);
      } else if (value === "desc") {
        sortArr.push(`-${key}`);
      }
      // if (index === (sort.length() - 1)) {
      //   return sortArr.join();
      // }
    });
    return sortArr.join();
  };

  vm.$onInit = function() {
    vm.statusOpts = [
      { id: '', title: 'All' }, // default selection
      { id: 'false', title: 'Unresolved' },
      { id: 'true', title: 'Resolved' }
    ];
    //TODO use local storage instead
    let rowCount = $cookies.get("rowCount")
            ?  $cookies.get("rowCount")
            : 25;
    vm.fieldworkers = vm.getFieldworkers();
    let persistence = TablePersistence();

    const queryParams = persistence.get();
    const hasQueryParams = queryParams.filter && Object.keys(queryParams.filter).length;
    const persistedFilter = storage.getItem('assignSchedule');
    const defaultFilter = {
      page: 1, // show first page
      count: 1 * rowCount, // count per page default
      isFiltersVisible: true,
      sorting: {
        "assigned_to.code": "desc",
        scheduled_for: "asc",
        cluster: "desc",
      },
      filter: {
        resolved: 'false', // hide resolved events by default
      },
    };

    let baseParams = hasQueryParams  ? queryParams
                   : persistedFilter ? persistedFilter['baseParams']
                   :                   defaultFilter;

    // initialize date if any
    vm.dateFrom = baseParams.filter.start_date  || null;
    vm.dateTo = baseParams.filter.end_date  || null;

    vm.tableParams = new NgTableParams(baseParams, {
        total: 0,
        paginationMaxBlocks: 8,
        paginationMinBlocks: 2,
        getData: function(params) {

          let page = params.page();
          let count = params.count();
          if (!$cookies.get("rowCount")) {
            $cookies.put("rowCount",  count);
          }
          // range is 0 indexed
          let range = `${((page * count) - count)}-${(page * count) - 1}`;

          let filter = angular.copy(params.filter());
          if (vm.dateFrom) {
            filter.start_date = $filter('date')(vm.dateFrom, 'yyyy-MM-dd');
          }
          else {
            delete filter.start_date;
          }

          if (vm.dateTo) {
            filter.end_date = $filter('date')(vm.dateTo, 'yyyy-MM-dd');
          }
          else {
            delete filter.end_date;
          }

          // storing the filter
          baseParams.filter = filter;
          storage.setItem('assignSchedule', { baseParams });

          // update the url
          params.localFilters = filter;
          persistence.set(params)

          return ScheduledEventModel.getAll(range, buildSort(params.sorting()), filter).$promise.then(resp => {
            vm.tableParams.total(setPages(resp.headers["content-range"]));
            // Set selected status on events
            vm.parseSelected(resp.data);
            vm.events = resp.data;
            _.forEach(vm.events, (entry) => {
              entry.status = entry.resolved_by_contact ? 'Resolved' : 'Unresolved';
            });
            return resp.data;
          });
        }
    });
  };

  let setPages = function(header) {
    if (header === 'items */0') {
      return 0;
    }
    let [, , , max] = header.match(/(\d+)-(\d+)\/(\d+)/);
    return max;
  };

  // -------------------Getters---------------------------

  vm.getFieldworkers = function() {
    FieldworkerModel.getAll().$promise.then((fieldworkers) => {
      vm.fieldworkers = fieldworkers;
    });
  };

  // -------------------Setters---------------------------

  vm.setSelectedFieldworker = function(value) {
    vm.selectedFieldworker = value;
    vm.parseSelected();
  };

// -------------------- Assignment ----------------------

  vm.parseSelected = function(events = vm.tableParams.data) {
    if (!vm.selectedFieldworker) {
      // $log.warn('no selected work type or worker');
      return;
    }
    events.forEach(sEvent => {
      if (sEvent.assigned_to_id === vm.selectedFieldworker.admin_user_id) {
        sEvent.selected = true;
      } else {
        sEvent.selected = false;
      }
    });
    vm.assigning = !_.some(events, {"selected": true});
  };

  vm.setSelected = function(sEvent) {
    if (sEvent.selected === true) {
      sEvent.assigned_to_id = vm.selectedFieldworker.admin_user_id;
    } else {
      sEvent.assigned_to_id = null;
    }
    vm.updateScheduledEvent(sEvent);
  };

  vm.updateScheduledEvent = function(sEvent) {
    ScheduledEventModel.update(sEvent.household.household_id, sEvent.scheduled_event_id, sEvent).$promise.then(resp => {
      var index = _.indexOf(vm.tableParams.data, _.find(vm.tableParams.data, {scheduled_event_id: sEvent.scheduled_event_id}));
      if (resp.assigned_to_id === vm.selectedFieldworker.admin_user_id) {
        resp.selected = true;
      } else {
        resp.selected = false;
      }
      vm.tableParams.data[index] = resp;
    });
  };

  vm.setDateFrom = function(date) {
    if (date) {
      vm.dateFrom = date;
      vm.tableParams.reload();
    }
  };

  vm.setDateTo = function(date) {
    if (date) {
      vm.dateTo = date;
      vm.tableParams.reload();
    }
  };

  vm.clearDates = function() {
    vm.dateFrom = null;
    vm.dateTo = null;
    vm.tableParams.reload();
  };

  vm.openModal = function(household_note, event_note) {
    let modalInst = $uibModal.open({
      component: 'noteModal',
      resolve: {
        notes: () => {
          return { household: household_note, event: event_note };
        },
      }
    });

    modalInst.result.then(function () {
      // If ok is hit
      return;
    }, function () {
      // If cancel is hit
      return;
    });
  };

}

angular
    .module('tmr-admin')
    .component('eventsList', eventsListComponent)
    .controller('EventsListController', eventsListController);
}());
