;(function() {
"use strict";

const householdNotesComponent = {
  templateUrl:  'components/household/notes/household-notes.component.html',
  controller:   'HouseholdNotesController',
  controllerAs: 'vm',
  bindings: {
    household: '<',
    notes:     '<',
    currentNote: '<',
  },
  require: {
    parent: '^household'
  },
};

// ngInject
function householdNotesController($log, Auth, NgTableParams) {
  let vm = this;
  vm.auth = Auth;
  vm.$onInit = function() {
    vm.back = vm.parent.back;
    vm.loginUrl = vm.parent.loginUrl;
  };

  vm.$onChanges = function(changedObj) {
    if (changedObj.notes && changedObj.notes.currentValue) {
      vm.notes = angular.copy(changedObj.notes.currentValue);
      vm.tableParams = new NgTableParams({
        count: vm.notes.length,
        sorting: { note_time: "desc" }
      }, {
        dataset: vm.notes,
        counts: [], // hides page sizes
      });
    }
  };
}

angular
    .module('tmr-admin')
    .component('householdNotes', householdNotesComponent)
    .controller('HouseholdNotesController', householdNotesController)
;
}());
