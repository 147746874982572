;(function() {
"use strict";

const scheduledEventDateTimeComponent = {
  templateUrl:  'components/inputs/scheduled-event-date-time/scheduled-event-date-time.component.html',
  controller:   'ScheduledEventDateTimeController',
  controllerAs: 'vm',
  bindings: {
    scheduledFor: '<',
    timeWindowId: '<',
    lowerBound: '<',  // date-time
    readonly: '<',
    onChange: '&',
  }
};

function scheduledEventDateTimeController($log, Auth, MetaModel, TimeWindows) {
  let vm = this;
  let timeWindows = TimeWindows();

  const canSetPastDates = Auth.current.user.is_owner || Auth.current.user.is_manager;

  vm.dateOptions = { showWeeks: false };

  timeWindows.windowsPromise.then(resp => {
    const options = [ { id: null, label: 'Specify an exact time' } ];
    for (const w of angular.copy(resp)) {
      w.label = _.startCase(w.label);
      options.push(w);
    }
    vm.timeWindowOptions = options;
  });

  vm.isWindowAvailable = function(tw) {
    if (!tw.id || !vm.minDate) {
      return true;
    }
    return timeWindows.getDateTime(tw, vm.scheduledFor) > vm.minDate;
  }

  vm.$onInit = function() {
  }

  function notifyChanges() {
    vm.onChange({ $scheduledFor: vm.scheduledFor, $timeWindowId: vm.timeWindowId });
  }

  vm.$onChanges = function (changedObj) {
    if (changedObj.lowerBound && changedObj.lowerBound.currentValue) {
      // if we have the lower boundary, set minDate with it
      vm.minDate = new Date(changedObj.lowerBound.currentValue);
      if (vm.scheduledFor < vm.minDate) {
        $log.info("Move the date up to the lower bound");
        if (vm.timeWindowId) {
          const nextWindow = timeWindows.nextWindow(vm.timeWindowOptions, vm.minDate);
          vm.scheduledFor = nextWindow.dateTime;
          vm.timeWindowId = nextWindow.id;
        }
        else {
          vm.scheduledFor = angular.copy(vm.minDate);
        }
      }
    }

    if (!vm.minDate && !canSetPastDates) {
      // only managers are able to set schedule event for past dates
      vm.minDate = new Date();
    }
  };

  vm.timeChanged = function() {
    notifyChanges();
  }

  vm.hideTimePicker = function() {
    return vm.timeWindowId !== null;
  }

  vm.timeWindowChanged = function() {
    if (vm.timeWindowId) {
      const tw = _.find(vm.timeWindowOptions, { id: vm.timeWindowId });
      if (tw) {
        // No need to notifyChanges here - changing the time will trigger that.
        vm.scheduledFor = timeWindows.getDateTime(tw, vm.scheduledFor);
      }
      else {
        console.error(`Time window id ${ vm.timeWindowId } not found`);
      }
    }
    else {
      // We've switched to specific times, but the time hasn't changed.
      // We need to notify about the selected time window changing.
      notifyChanges();
    }
  }
}

angular
    .module('tmr-admin')
    .component('scheduledEventDateTime', scheduledEventDateTimeComponent)
    .controller('ScheduledEventDateTimeController', scheduledEventDateTimeController)
;
}());
