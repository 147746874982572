;(function() {
"use strict";

// TODO this component is waiting on spec revision.

const fieldworkerDetailComponent = {
  templateUrl:  'components/manager/assign-workload/rhs-fieldworker-panel/fieldworker-detail/fieldworker-detail.component.html',
  controller:   'FieldworkerDetailController',
  controllerAs: 'vm',
  bindings: {
    fieldworker: '<fieldworker',
    weeks: '<weeks'
  }
};

// ngInject
function fieldworkerDetailController($log, Alerts, FieldworkerModel) {
  let vm = this;

  vm.$onChanges = function(changedObj) {
    if (changedObj.weeks) {
      vm.weeks = changedObj.weeks.currentValue;
      if (!vm.selectedWeek) {
        vm.setSelectedWeek(vm.weeks[0]);
      }
    }
  };

  vm.setSelectedWeek = function(week) {
    vm.selectedWeek = week;
    vm.scheduledEvents = FieldworkerModel.scheduledEvents(vm.fieldworker.admin_user_id);
  };
}

angular
    .module('tmr-admin')
    .component('fieldworkerDetail', fieldworkerDetailComponent)
    .controller('FieldworkerDetailController', fieldworkerDetailController)
;
}());
