;(function() {
"use strict";

const projectSelectorComponent = {
  templateUrl:  'components/project-selector/project-selector.component.html',
  controller:   'ProjectSelectorController',
  controllerAs: 'vm'
};

function projectSelectorController($http, $log, $interval, Auth, $state, Alerts, NgTableParams, $uibModal, $window) {
  let vm = this;
  let user = Auth.current.user;
  vm.logout = Auth.logout;

  vm.$onInit = function() {
    vm.showRetiredProjects = false;

    setupProjects();

    if (vm.projects.length == 1 && !vm.isOwner) {
      // No point hanging about here - if there's only one project and user is
      // not an owner, go straight there.
      vm.goToProject(vm.projects[0]);
      // Note we still fall through here
      return;
    }

    setupPeriodicRefresh(60); // seconds

    vm.tableParams = new NgTableParams({ }, {
      counts: [], // hide pagination
      getData: function(params) {
        if (vm.showRetiredProjects) {
          return vm.projects;
        }
        return _.reject(vm.projects, { status: 'retired' });
      },
    });
  }

  // This gets called in vm.$onInit and in refreshProjectList
  function setupProjects() {
    user = Auth.current.user;

    vm.isOwner = user.is_owner;
    vm.projects = user.projects;
    _.each(vm.projects, annotateProject);
    vm.projects = _.orderBy(vm.projects, [ 'sortKey0', 'sortKey1' ], [ 'asc', 'desc' ]);
  }

  vm.$onDestroy = function() {
    // Need to do this, otherwise the interval timer keeps going off
    cancelPeriodicRefresh();
  }

  vm.updateShowRetiredProjects = function() {
    vm.tableParams.reload();
  };

  vm.createSupportEmailLink = function(project) {
    const supportEmail = 'support@logicly.com.au';
    const emailTitle   = encodeURIComponent(`TMR Admin ${ project.name } - ${ project.description }`);
    const emailBody    = encodeURIComponent('Please leave the suggested subject line intact and add any additional information you think may be relevant below:');
    const emailHref = `mailto:${ supportEmail }?subject=${ emailTitle }&body=${ emailBody }`;
    return emailHref;
  };

  let refreshPromise;
  function setupPeriodicRefresh(delaySeconds) {
    refreshPromise = $interval(refreshProjectList, delaySeconds * 1000);
  }

  function cancelPeriodicRefresh() {
    $interval.cancel(refreshPromise);
  }

  function retireProject(project) {
    return $http.post(`/api/project/${project.project_id}/retirement`).then(
      resp => {
        refreshProjectList();
      });
      /*
      error => {
        console.log("ERROR!", angular.copy(error));
        throw error;
      });
      */
  }

  function formatDatetime(str) {
    // convert to local time
    return moment(str).format("DD-MMM-YYYY hh:mm A");
  }

  vm.projectStatus = function(project) {
    if (project.retirement_request) {
      return project.retirement_request.status;
    }
    return 'Active';
  }

  function showConfirmationDialog(templateUrl, vars) {
    return $uibModal.open({
      controller: function($scope) {
        _.assignIn($scope, vars);
      },
      templateUrl: templateUrl,
    }).result;
  }

  vm.requestRetirement = function(project) {
    let template = 'components/project-selector/retirement-warning-modal.html';
    let vars = { projectName: project.name };

    return showConfirmationDialog(template, vars).then(
      ok     => retireProject(project),
      cancel => { },
    );
  };

  vm.canRequestRetirement = function(project) {
    return project.can_request_retirement;
  };

  vm.requestCancelRetirement = function(project) {
    let template = 'components/project-selector/cancel-warning-modal.html';
    let vars = { projectName: project.name };
    return showConfirmationDialog(template, vars).then(
      ok     => cancelRetirement(project),
      cancel => { },
    );
  };

  function cancelRetirement(project) {
    return $http.delete(`/api/project/${project.project_id}/retirement`).then(
      resp => {
        refreshProjectList();
    });
  };

  vm.canCancelRetirement = function(project) {
    return project.can_cancel_retirement;
  };

  vm.canDeleteData = function(project) {
    return project.can_delete_data;
  };

  function deleteData(project) {
    return $http.delete(`/api/project/${project.project_id}/archive`).then(
      resp => {
        refreshProjectList();
      }
    );
  }

  vm.requestDelete = function(project) {
    let template = 'components/project-selector/delete-warning-modal.html';
    let vars = { projectName: project.name };

    return showConfirmationDialog(template, vars).then(
      ok     => deleteData(project),
      cancel => { },
    );
  };

  vm.downloadLink = function(project) {
    return `/api/project/${project.project_id}/archive`;
  }

  vm.canDownloadArchivedData = function(project) {
    return project.can_download_archive;
  };

  vm.goToProject = function(project) {
    user.homeState.params.project_id = project.project_id
    $state.go(user.homeState.state, user.homeState.params);
  };

  function refreshProjectList() {
    Auth.refresh().then(ok => {
      setupProjects();
      vm.tableParams.reload(); // force the table to refresh
    });
  }

  function plural(word, count) {
    return count == 1 ? word : word + 's';
  }

  function failures(job) {
    return job && (job.status != 'complete') ? job.failures : 0;
  }

  function annotateProject(project) {
    // for sorting, split "[project name] [years]" by the last occurence white space
    const lastIdx = project.name.lastIndexOf(' ');
    // grab the splitted part otherwise fallback to project.name
    project.sortKey0 = project.name.substr(0, lastIdx) || project.name;
    project.sortKey1 = _.trimStart(project.name.substr(lastIdx)) || project.name;

    let warnings = [ ];

    if (!project.retirement_request) {
      project.description = "Active";
      project.warnings = warnings;
      project.short_status = 'active'; // for styling icon
      return;
    }

    let rr = project.retirement_request;

    function check_job(job) {
      let count = failures(job);
      if ( count > 0) {
        const jobTime = job.scheduled ? job.scheduled : job.started;
        const msg = `Failed ${ formatDatetime(jobTime) } (attempt ${ job.failures })`;
        warnings.push(msg);
      }
    }

    check_job(rr.archive_job);
    check_job(rr.delete_job);

    project.warnings = warnings;

    project.info = [ `Initiated ${ formatDatetime(rr.requested_utc) } by ${ rr.requested_by_user.full_name }` ];
    project.short_status = 'archiving'; // for styling icon

    if (rr.status == 'creating_archive') {
      project.description = warnings.length > 0 ? "Archive failed" : "Creating archive";
    }
    else if (rr.status == 'archive_ready') {
      project.description = "Archive ready to download";
    }
    else if (rr.status == 'removing_data') {
      project.description = warnings.length > 0 ? "Delete failed" : "Removing data";
    }
    else if (rr.status == 'retired') {
      project.description = "Retired";
      project.info.unshift(`Finalised ${ formatDatetime(rr.delete_completed_utc) } by ${ rr.deleted_by_user.full_name }`);
      project.short_status = 'retired'; // for styling icon
    }
    else {
      project.description = `Unexpected status ${ rr.status }`;
    }
  }
}

angular
    .module('tmr-admin')
    .component('projectSelector', projectSelectorComponent)
    .controller('ProjectSelectorController', projectSelectorController)
;
}());
